var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"white"}},[_c('img',{staticClass:"responsive",attrs:{"src":require("../../../../public/gsd/home_student_images/form/formalurios_solicitudes.png")}}),_vm._v(" "),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-9"},[_c('div',{staticClass:"container is-fullheight is-family-secondary"},[_vm._m(0),_vm._v(" "),_c('div',[_c('section',[_c('Youtube',{attrs:{"videoId":"J3qxO4OvPjk","maxWidth":"1080px"}})],1)]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{ref:"createRequestType",staticClass:"container",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"subtitle is-3 has-text-secondary"},[_vm._v("Calendario de solicitudes")]),_vm._v(" "),_c('DateIntevalCalendar',{attrs:{"origin":"MSD"}})],1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('hr',{staticClass:"solid"}),_vm._v(" "),_vm._m(3),_vm._v(" "),(_vm.requestTypes.length > 0)?_c('div',[_c('ListRequestTypeCard',{attrs:{"request-types":_vm.requestTypes,"background-color":"has-background-white","title-text-color":"has-text-src","border-color":"is-border-src","button-type":"is-secondary","border-top-content-color":"is-border-top-src","size":"is-12"}})],1):_vm._e(),_vm._v(" "),(!_vm.moduleEnabled)?_c('div',{staticClass:"columns is-centered"},[_c('b-message',{staticClass:"column is-vcentered",attrs:{"type":"is-danger","icon-size":"is-medium","has-icon":""}},[_vm._v("\n              Actualmente este módulo no se encuentra recibiendo solicitudes.\n            ")])],1):_vm._e(),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)])])]),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section has-text-centered",staticStyle:{"height":"100%"}},[_c('div',[_c('h1',{staticClass:"subtitle is-3 has-text-secondary has-text-weight-bold"},[_vm._v("\n              Te aconsejamos ver el siguiente video antes de cursar cualquier\n              solicitud\n            ")])]),_vm._v(" "),_c('div',{staticClass:"has-text-secondary"},[_c('h6',{staticClass:"is-size-6 has-text-weight-bold"},[_vm._v("\n              Recuerda que las solicitudes que se realizan en este portal, son\n              dirigidas para estudiantes de la FING USACH de la modalidad\n              DIURNO.\n            ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"subtitle is-5 has-text-secondary has-text-weight-bold"},[_vm._v("\n              Puedes encontrar la explicación sobre las condiciones y\n              documentos requeridos para realizar tu Solicitud Docente\n              dirigiéndote a los siguientes minutos del video:\n            ")]),_vm._v(" "),_c('h1',{staticClass:"subtitle is-6 has-text-secondary"},[_vm._v("\n              •Postergación de Semestre: 00:01:09\n              "),_c('br'),_vm._v("\n              •Renuncia: 00:02:10\n              "),_c('br'),_vm._v("\n              •Retiro Temporal: 00:02:54\n              "),_c('br'),_vm._v("\n              •Justificación de Inasistencia: 00:04:05\n              "),_c('br'),_vm._v("\n              •Convalidación Internos FING: 00:04:58\n              "),_c('br'),_vm._v("\n              •Convalidación Externos FING: 00:05:46\n              "),_c('br'),_vm._v("\n              •Reincorporación: 00:06:44\n              "),_c('br'),_vm._v("\n              •Reincorporación Estudiantes Egresados: 00:07:21\n              "),_c('br'),_vm._v("\n              •Postulación con Título Universitario: 00:08:07\n              "),_c('br'),_vm._v("\n              •Cambios de Carrera Estudiantes FING: 00:09:09\n              "),_c('br'),_vm._v("\n              •Cambios de Carrera Estudiantes de otras Facultades: 00:09:57\n              "),_c('br'),_vm._v("\n              •Matrícula Fuera de Plazo: 00:10:40\n              "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex is-justify-content-flex-start has-text-weight-bold"},[_c('h1',{staticClass:"subtitle is-3 has-text-secondary"},[_vm._v("Solicitudes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 has-text-secondary"},[_c('h6',{staticClass:"is-size-6"},[_vm._v("\n              A continuación se exponen las solicitudes para realizar tu\n              trámite estudiantil. Si tienes dudas de cómo hacer una Solicitud\n              de Docencia, te invitamos a revisar el video explicativo\n              expuesto anteriormente.\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#003b71","margin-top":"10em"},attrs:{"align":"center"}},[_c('img',{attrs:{"src":require("../../../../public/logo_sin_texto.png")}}),_vm._v(" "),_c('div',[_c('a',{attrs:{"href":"https://www.fing.usach.cl/","target":"_blank"}},[_c('h1',{staticClass:"is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold"},[_vm._v("\n          WEB OFICIAL FING USACH | Entra aquí\n        ")])]),_vm._v(" "),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"has-background-msd",attrs:{"align":"center"}},[_c('br'),_vm._v(" "),_c('h1',{staticClass:"is-size-6 has-text-black has-text-centered is-family-secondary"},[_vm._v("\n      Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins\n      nº 3363. Estación Central. Santiago. Chile.\n    ")]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.usach.cl/contacto","target":"_blank"}},[_c('h1',{staticClass:"is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold"},[_vm._v("\n        Oficina de Informaciones, Reclamos y Sugerencias (OIRS)\n      ")])]),_vm._v(" "),_c('br')])
}]

export { render, staticRenderFns }