<template>
  <div class="container box mt-5">
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <h1 class="subtitle is-1 has-text-secondary">
      Gestión de Documentos Parametrizados
    </h1>
    <hr class="solid" />
    <div class="columns mb-0">
      <div class="column">
        <b-field label="Nombre Tipo de Documento">
          <b-input v-model="name"
              placeholder="Nombre Tipo de Documento">
          </b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Siglas Tipo de Documento">
           <b-input v-model="initials"
              placeholder="Siglas Tipo de Documento">
          </b-input>
        </b-field>
      </div>
    </div>
    <div class="level-right mb-5">
      <div class="buttons">
        <b-button
          type="is-secondary"
          icon-left="search"
          @click="fetchDocumentParameterized()"
        >
          Buscar
        </b-button>
      </div>
    </div>


    <h2 class="has-text-centered new-h2">Tabla de Documentos Parametrizados</h2>

    <div class="level-right mb-5">
      <div class="buttons">
        <b-button
          type="is-success"
          icon-left="plus"
          @click="newDocumentParameterized()"
        >
          Añadir Documento Parametrizado
        </b-button>
      </div>
    </div>
    <b-table
      :data="documentTypes"
      paginated
      per-page="10"
      hoverable
      sort-icon="arrow-up"
    >
      <b-table-column field="name" label="Nombre Tipo de Documento" sortable searchable>
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>
      <b-table-column
        field="initials"
        label="Siglas"
        sortable
        searchable        
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.initials }}
        </template>
      </b-table-column>
      <b-table-column label="Acciones" centered width="15em">
        <template v-slot="props">
          <b-button
            type="is-primary"
            icon-left="eye"
            @click="openModalShowDocument(props.row)"
          >
          Ver
          </b-button>
          <b-button
            type="is-secondary"
            @click="editDocumentParameterized(props.row)"
            icon-left="pen"
          >
          Editar
          </b-button>
        </template>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">
          Sin Tipos de Documentos Parametrizados
        </div>
      </template>
    </b-table>

    <b-modal
      v-model="isModalAddDocumentParameterized"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="90%"
    >
      <template #default="props">
        <modal-add-document-parameterized  
            :element-data="dataModal"
            :modal-header="modalHeader"
            :action = "action"
            @close="props.close"
            @reload="getDocumentParameterized"
        >
        </modal-add-document-parameterized>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalShowDocumentParameterized"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="80%"
    >
      <template #default="props">
        <modal-show-document-parameterized
            :element-data="dataModal"
            @close="props.close"
        >
        </modal-show-document-parameterized>
      </template>
    </b-modal>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "ParameterizedManagment",
  components:{ 
    ModalAddDocumentParameterized: () => import( "./ModalAddDocumentParameterized.vue"),
    ModalShowDocumentParameterized: () => import( "./ModalShowDocumentParameterized.vue"),
   },
  data() {
    return {
      isLoading: false,
      name: "",
      initials: "",
      documentTypes: [],
      action:"",
      modalHeader:"",
      dataModal: {},
      isModalAddDocumentParameterized: false,
      isModalShowDocumentParameterized: false,


    };
  },
  async created() {
    document.title = "Gestión de Parametrización";
    await this.getDocumentParameterized();

  },
  methods: {
    getDocumentParameterized() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/document-parameterizeds.json")
        .then((response) => {
          vm.documentTypes = response.data;
        })
        .catch((error) => {
          vm.$buefy.alert.open({
            title: "Error",
            hasIcon: true,
            message: "Error al cargar los tipos de documentos parametrizados",
            type: "is-danger",
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },


    fetchDocumentParameterized() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/document-parameterizeds.json", {
          params: {
            name: vm.name,
            initials: vm.initials,
          },
        })
        .then((response) => {
          vm.documentTypes = response.data;
  
        })
        .catch((error) => {
          vm.$buefy.dialog.alert({
            title: "Error",
            message: "Ocurrió un error al obtener la información",
            type: "is-danger",
            hasIcon: true,
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },    

    newDocumentParameterized(){
      this.action = "create";
      this.modalHeader = "Nuevo Tipo de Documento Parametrizado";
      this.dataModal = {};
      this.isModalAddDocumentParameterized = true;
    },
    editDocumentParameterized(row){
      this.action = "update";
      this.modalHeader = "Editar Tipo de Documento Parametrizado";
      this.dataModal = row;
      this.isModalAddDocumentParameterized = true;
    },
    openModalShowDocument(row){
      this.dataModal = row;
      this.isModalShowDocumentParameterized = true;
    },

    
  },
};
</script>
<style scoped>
  .new-h2 {
    font-size: 24px;
    font-weight: bold;
  }


</style>