<template>
  <div class="container">
    <section style="width: auto">
      <!-- Box de Formulario Appointment -->
      <div class="modal-card" style="width: auto; height: auto">
        <header class="modal-card-head has-background-secondary">
          <p class="modal-card-title has-text-white">Documentos internos</p>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>
        <section class="modal-card-body" ref="ModalAddInternalPositions">
          <div class="control">
            <div
              class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap mb-4"
            >
              <div class="field is-flex mb-0 mt-2">
                <h1 class="title is-5">
                  Documentos internos asociados a la observación
                  {{ observationCode }}
                </h1>
              </div>
            </div>
            <div class="subtitle is-6">
              Observación realizada por <b>{{ observationBy }}</b> el
              {{ dateToStringWithHour(date) }}: "{{ observation }}"
            </div>

            <div class="level-right">
              <b-button
                v-if="documents.length !== openedDocuments.length"
                class="is-secondary is-light is-border-cornflowerblue"
                icon-left="eye"
                @click="openAllDocuments"
              >
                Ver todos los documentos
              </b-button>
              <b-button
                v-else
                class="is-primary is-light is-border-darkgoldenrod"
                icon-left="eye-slash"
                @click="closeAllDocuments"
              >
                Ocultar todos los documentos
              </b-button>
            </div>
            <b-table
              :data="documents"
              class="is-border-customgrey p-3"
              hoverable
              :mobile-cards="true"
              detail-key="id"
              detailed
              detail-icon=""
              :opened-detailed="openedDocuments"
            >
              <template #detail="props">
                <div class="title is-4">Previsualización del documento</div>
                <DocumentPrevisulizer :src="props.row.src" :zoom="40" />
              </template>
              <b-table-column
                field="name"
                label="Nombre del Documento"
                v-slot="props"
              >
                {{ props.row.name }}
              </b-table-column>
              <b-table-column
                field="actions"
                label="Acciones"
                centered
                v-slot="props"
              >
                <b-button
                  v-if="!isOpened(props.row.id)"
                  type="is-primary "
                  icon-left="eye"
                  @click="previewDocument(props.row)"
                ></b-button>
                <b-button
                  v-else
                  class="is-primary is-light is-border-darkgoldenrod"
                  icon-left="eye-slash"
                  @click="closeDocument(props.row)"
                ></b-button>
                <b-button
                  type="is-secondary "
                  icon-left="download"
                  @click="downloadFile(props.row.id)"
                ></b-button>
              </b-table-column>

              <template #empty>
                <div class="has-text-centered">
                  Aún no hay documentos subidos
                </div>
              </template>
            </b-table>
          </div>
        </section>
        <div class="modal-card-foot has-background-secondary">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">
              Cerrar
            </button>
          </div>
        </div>
      </div>
      <b-loading
        :is-full-page="true"
        v-model="isLoadingAny"
        :can-cancel="false"
      ></b-loading>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { dateToStringWithHour } from "../../../packs/utilities";

export default {
  name: "ModalViewAllDocs",
  props: [
    "request_id",
    "internal_observation_id",
    "observationBy",
    "date",
    "observationCode",
    "observation",
  ],
  components: {
    DocumentPrevisulizer: () =>
      import("../../Documents/DocumentPrevisulizer.vue"),
  },
  data() {
    return {
      isLoading: false,
      isLoadingAllDocuments: false,
      documents: [],
      openedDocuments: [],
    };
  },

  created() {
    this.isLoading = true;
    this.getInternalDocuments();
  },

  computed: {
    isLoadingAny() {
      return this.isLoading || this.isLoadingAllDocuments;
    }
  },

  methods: {
    dateToStringWithHour,
    async getInternalDocuments() {
      this.isLoading = true;
      await axios
        .get(
          "/internal_observations/internal-documents/" +
            this.internal_observation_id +
            ".json"
        )
        .then((response) => {
          this.documents = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    openAllDocuments() {
      this.isLoadingAllDocuments = true;
      const promises = this.documents.map(async (document) => {
        await this.previewDocument(document);
      });

      Promise.all(promises).then(() => {
        this.isLoadingAllDocuments = false;
      });

    },

    closeAllDocuments() {
      this.openedDocuments = [];
    },

    closeDocument(row) {
      this.openedDocuments = this.openedDocuments.filter(
        (doc) => doc !== row.id
      );
    },

    downloadPdf(row) {
      this.isLoading = true;
      axios
        .request({
          url: "/documents/download_pdf_file/" + row.id,
          responseType: "blob",
        })
        .then(async (response) => {
          const file = new File([new Blob([response.data])], document.name);
          const buffer = await file.arrayBuffer();

          row.src = new Uint8Array(buffer);
          this.openedDocuments.push(row.id);
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Fallo la previsualización.",
            type: "is-danger",
            hasIcon: true,
            title: "Error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    isOpened(id) {
      return this.openedDocuments.includes(id);
    },

    async previewDocument(row) {
      if (this.openedDocuments.includes(row.id)) {
        return;
      }
      if (row.src) {
        this.openedDocuments.push(row.id);
      } else {
        this.downloadPdf(row);
      }
    },

    downloadFile(document_id) {
      this.isLoading = true;

      axios
        .request({
          url: "/documents/download_file/" + document_id,
          responseType: "blob",
        })
        .then((response) => {
          //var blobUrl = URL.createObjectURL(response.data);
          //window.open(blobUrl)

          let info = response.headers["content-disposition"];
          let name = decodeURIComponent(info.split('"')[1]);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Se descargo con exito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Fallo la descarga.",
            type: "is-danger",
          });
        });
    },
  },
};
</script>
