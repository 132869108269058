<template>
  <div style="background-color: white">
    <img
      src="../../../../public/gsd/home_student_images/form/formalurios_solicitudes.png"
      class="responsive"
    />
    <div class="columns is-centered">
      <div class="column is-9">
        <div class="container is-fullheight is-family-secondary">
          <div class="section has-text-centered" style="height: 100%">
            <!-- Primer Titulo -->
            <div>
              <h1 class="subtitle is-3 has-text-secondary has-text-weight-bold">
                Te aconsejamos ver el siguiente video antes de cursar cualquier
                solicitud
              </h1>
            </div>

            <!-- subTitulo -->
            <div class="has-text-secondary">
              <h6 class="is-size-6 has-text-weight-bold">
                Recuerda que las solicitudes que se realizan en este portal, son
                dirigidas para estudiantes de la FING USACH de la modalidad
                DIURNO.
              </h6>
            </div>
          </div>
          <!-- Video -->
          <div>
            <section>
              <Youtube videoId="J3qxO4OvPjk" maxWidth="1080px"></Youtube>
            </section>
          </div>

          <div>
            <!-- informacion del video -->
            <div class="container">
              <h1 class="subtitle is-5 has-text-secondary has-text-weight-bold">
                Puedes encontrar la explicación sobre las condiciones y
                documentos requeridos para realizar tu Solicitud Docente
                dirigiéndote a los siguientes minutos del video:
              </h1>
              <h1 class="subtitle is-6 has-text-secondary">
                •Postergación de Semestre: 00:01:09
                <br />
                •Renuncia: 00:02:10
                <br />
                •Retiro Temporal: 00:02:54
                <br />
                •Justificación de Inasistencia: 00:04:05
                <br />
                •Convalidación Internos FING: 00:04:58
                <br />
                •Convalidación Externos FING: 00:05:46
                <br />
                •Reincorporación: 00:06:44
                <br />
                •Reincorporación Estudiantes Egresados: 00:07:21
                <br />
                •Postulación con Título Universitario: 00:08:07
                <br />
                •Cambios de Carrera Estudiantes FING: 00:09:09
                <br />
                •Cambios de Carrera Estudiantes de otras Facultades: 00:09:57
                <br />
                •Matrícula Fuera de Plazo: 00:10:40
                <br />
                <br />
                <br />
              </h1>
            </div>
          </div>

          <div class="container" style="height: 100%" ref="createRequestType">
            <!-- Titulo de los formularios -->

            <div class="box">
              <div class="subtitle is-3 has-text-secondary">Calendario de solicitudes</div>
              <DateIntevalCalendar origin="MSD"></DateIntevalCalendar>
            </div>
            <div
              class="is-flex is-justify-content-flex-start has-text-weight-bold"
            >
              <h1 class="subtitle is-3 has-text-secondary">Solicitudes</h1>
            </div>
            <!-- Separador -->
            <hr class="solid" />

            <!-- subtitulo Titulo -->
            <div class="mb-3 has-text-secondary">
              <h6 class="is-size-6">
                A continuación se exponen las solicitudes para realizar tu
                trámite estudiantil. Si tienes dudas de cómo hacer una Solicitud
                de Docencia, te invitamos a revisar el video explicativo
                expuesto anteriormente.
              </h6>
            </div>

            <!-- Request Types Section -->

            <div v-if="requestTypes.length > 0">
              <ListRequestTypeCard
                :request-types=requestTypes
                background-color="has-background-white"
                title-text-color="has-text-src"
                border-color="is-border-src"
                button-type="is-secondary"
                border-top-content-color="is-border-top-src"
                size="is-12"
              />
            </div>

            <div v-if="!moduleEnabled" class="columns is-centered">
              <b-message
                class="column is-vcentered"
                type="is-danger"
                icon-size="is-medium"
                has-icon
              >
                Actualmente este módulo no se encuentra recibiendo solicitudes.
              </b-message>
            </div>

            <b-loading
              :is-full-page="true"
              v-model="isLoading"
              :can-cancel="false"
            ></b-loading>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #003b71; margin-top: 10em" align="center">
      <img src="../../../../public/logo_sin_texto.png" />
      <div>
        <a href="https://www.fing.usach.cl/" target="_blank">
          <h1
            class="is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold"
          >
            WEB OFICIAL FING USACH | Entra aquí
          </h1>
        </a>
        <br />
      </div>
    </div>
    <div class="has-background-msd" align="center">
      <br />
      <h1
        class="is-size-6 has-text-black has-text-centered is-family-secondary"
      >
        Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins
        nº 3363. Estación Central. Santiago. Chile.
      </h1>
      <a href="https://www.usach.cl/contacto" target="_blank">
        <h1
          class="is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold"
        >
          Oficina de Informaciones, Reclamos y Sugerencias (OIRS)
        </h1>
      </a>
      <br />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Youtube from "../Tools/Youtube";
import DateIntevalCalendar from "./DateIntevalCalendar.vue";
import ListRequestTypeCard from "./ListRequestTypeCard.vue";

export default {
  name: "ChooseRequestTypeStudent",
  components: {ListRequestTypeCard, DateIntevalCalendar, Youtube },
  data: () => {
    return {
      requestTypes: [],
      moduleEnabled: false,
      isLoading: false,
    };
  },
  created() {
    document.title = "MSD | Iniciar una solicitud";
    this.isLoading = true;

    axios
      .get("/msd/request_types.json")
      .then((response) => {
        this.requestTypes = response.data.request_types;
        this.moduleEnabled = response.data.module_enabled;
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  },
  methods: {},
};
</script>
