<template>
  <div class="container box mt-5">
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <h1 class="subtitle is-1 has-text-secondary">
      Listado de Pagos Realizados
    </h1>
    <hr class="solid" />
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(fetchPaymentSummary)">
        <div class="columns mb-0">
          <div class="column">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-field label="Fecha de inicio*">
                <b-datepicker
                  v-model="startDate"
                  icon="calendar-alt"
                  placeholder="Formato de Fecha: dd/mm/aaaa"
                  :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                  locale="es"
                >
                </b-datepicker>
              </b-field>
              <span class="validation-alert">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="column">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-field label="Fecha de fin*">
                <b-datepicker
                  v-model="endDate"
                  icon="calendar-alt"
                  placeholder="Formato de Fecha: dd/mm/aaaa"
                  :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                  locale="es"
                >
                </b-datepicker>
              </b-field>
              <span class="validation-alert">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="level-right mb-5">
          <div class="buttons">
            <b-button
              type="is-secondary"
              icon-left="search"
              native-type="submit"
            >
              Buscar
            </b-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-table
      v-if="summary"
      :data="summary"
      paginated
      per-page="10"
      hoverable
      sort-icon="arrow-up"
      detailed
      detail-icon="plus-circle"
      header-checkable
      checkable
      :checked-rows.sync="checkedRows"
      @check-all="onCheckAll"
      checkbox-position="right"
    >
      <b-table-column field="rut" label="RUT" sortable centered searchable>
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.rut }}
        </template>
      </b-table-column>
      <b-table-column
        field="full_name"
        label="Nombre Completo"
        sortable
        searchable
        centered
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.full_name }}
        </template>
      </b-table-column>
      <b-table-column
        field="request_type"
        label="Tipo de Solicitud"
        sortable
        searchable
        centered
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.request_type }}
        </template>
      </b-table-column>
      <b-table-column
        field="payment_amount"
        label="Monto Total"
        sortable
        searchable
        centered
        :custom-sort="customSortPrice"
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <div class="has-text-right">$ {{ props.row.payment_amount }}</div>
        </template>
      </b-table-column>
      <b-table-column
        field="payment_date"
        label="Fecha de Pago"
        sortable
        searchable
        centered
        :custom-sort="customSortDate"
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.payment_date }}
        </template>
      </b-table-column>
      <b-table-column label="Comprobante de Pago" centered width="10em">
        <template v-slot="props">
          <b-button
            type="is-primary"
            icon-left="eye"
            @click="openModalShowDocument(props.row.id_drive)"
          >
          </b-button>
          <b-button
            type="is-secondary"
            @click="downloadFile(props.row)"
            icon-left="download"
          >
          </b-button>
        </template>
      </b-table-column>
      <template #detail="props">
        <b>Comentario del pago:</b>
        {{
          props.row.payment_comment == null
            ? "Sin comentario"
            : props.row.payment_comment
        }}
      </template>
      <template #empty>
        <div class="has-text-centered">
          No se encontraron pagos para las fechas seleccionadas
        </div>
      </template>
    </b-table>
    <div class="columns mt-3">
      <div class="column has-text-right">
        <b-button
          v-if="summary"
          type="is-excel"
          icon-left="file-excel"
          @click="downloadExcel"
          :disabled="checkedRows.length === 0"
        >
          Exportar seleccionados a Excel
        </b-button>
      </div>
    </div>

    <b-modal
      v-model="isModalShowDocumentActive"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="90%"
    >
      <template #default="props">
        <PreviewProgramModal :docId="src" @close="props.close">
        </PreviewProgramModal>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import PreviewProgramModal from "../Documents/PreviewProgramModal.vue";
export default {
  components: {
    PreviewProgramModal: () => import("../Documents/PreviewProgramModal.vue"),
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      isLoading: false,
      summary: null,
      isModalShowDocumentActive: false,
      src: null,
      checkedRows: [],
    };
  },
  created() {
    document.title = "Listado de Pagos Realizados";
    let now = new Date();
    let startOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 1)); // Ajusta al lunes
    let endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 4); // Ajusta al viernes

    this.startDate = startOfWeek;
    this.endDate = endOfWeek;
  },
  methods: {
    fetchPaymentSummary() {
      this.isLoading = true;
      this.checkedRows = [];
      axios
        .get("/payment-details/reviewer/summary.json", {
          params: {
            start_date: this.startDate,
            end_date: this.endDate,
          },
        })
        .then((response) => {
          this.summary = response.data.summary;
        })
        .catch((error) => {
          console.log(error);
          this.$buefy.dialog.alert({
            title: "Error",
            message: "Ocurrió un error al obtener la información",
            type: "is-danger",
            hasIcon: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    downloadFile(row) {
      let vm = this;
      vm.isLoading = true;

      axios
        .request({
          url: "/documents/download_file/" + row.id_drive,
          responseType: "blob",
        })
        .then((response) => {
          let info = response.headers["content-disposition"];
          let name = info.split('"')[1];
          const rut = name.match(/RUT_(\d{7,8}-\d)/);
          name = `Comprobante de pago RUT_${rut[1]}_Fecha_${row.payment_date}.pdf`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
          vm.isLoading = false;
          vm.$buefy.dialog.alert({
            message: "Se descargo con exito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        })
        .catch((e) => {
          vm.$buefy.dialog.alert({
            title: "Error",
            message: "Fallo la descarga.",
            type: "is-danger",
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    openModalShowDocument(driveId) {
      this.src = driveId;
      this.isModalShowDocumentActive = true;
    },
    compareDate(a, b) {
      if (a == null) {
        return -1;
      }
      if (b == null) {
        return 1;
      }
      const dateA = new Date(a.split("-").reverse().join("-"));
      const dateB = new Date(b.split("-").reverse().join("-"));
      return dateA - dateB;
    },
    customSortDate(a, b, isAsc) {
      return isAsc
        ? this.compareDate(a.start_date, b.start_date)
        : this.compareDate(b.start_date, a.start_date);
    },
    downloadExcel() {
      this.isLoading = true;
      axios
        .get("/payment-details/reviewer/summary.xlsx", {
          params: {
            start_date: this.startDate,
            end_date: this.endDate,
            payment_ids: this.checkedRows.map((row) => row.id)
          },
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const filename = "Resumen de pagos.xlsx";
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          this.$buefy.dialog.alert({
            message: "Archivo descargado correctamente.",
            type: "is-success",
            hasIcon: true,
          });
        })
        .catch((e) => {
          console.log(e);
          this.$buefy.dialog.alert({
            title: "Error",
            message: "Error al descargar el archivo.",
            type: "is-danger",
          });
        })
        .then(() => (this.isLoading = false));
    },
    customSortPrice(a, b, isAsc) {
      const numA = parseFloat(a.replace(/\./g, ""));
      const numB = parseFloat(b.replace(/\./g, ""));
      return isAsc ? numA - numB : numB - numA;
    },
    onCheckAll() {
      this.$nextTick(() => {
        if (this.checkedRows.length !== this.summary.length) {
          this.checkedRows = [];
        } else {
          this.checkedRows = this.summary;
        }
      });
    },
  },
};
</script>
