<template>
  <div>
    <div class="columns is-centered pb-0 mb-0">
      <div class="column is-full">
        <b-carousel
          icon-size="is-large"
          :interval="6000"
          indicator-style="is-lines"
          :pause-info="false"
        >
          <template v-for="(carousel, i) in carousels">
            <b-carousel-item v-if="!carousel.isVideo" :key="i">
              <a :href="carousel.link">
                <section
                  :class="`hero is-medium is-bold`"
                  :style="`background-color: ${carousel.color};`"
                >
                  <b-image class="image" :src="carousel.image"></b-image>
                </section>
              </a>
            </b-carousel-item>
            <b-carousel-item v-if="carousel.isVideo" :key="i">
              <div class="has-background-black">
                <div class="columns">
                  <div class="column pt-0">
                    <Youtube
                      class="mt-5"
                      :videoId="carousel.videoId"
                      max-width="40em"
                    ></Youtube>
                  </div>
                </div>
              </div>
            </b-carousel-item>
          </template>
        </b-carousel>
      </div>
    </div>
    <div class="columns is-centered">
      <div
        class="column is-10-desktop is-11"
        style="padding: 2rem; background-color: #f2f2f2; height: 100%"
      >
        <div class="section py-4" style="background-color: white; height: 100%">
          <div
            class="section pb-3 pt-0"
            style="background-color: white; height: 100%"
          >
            <!-- Titulo de la sección -->

            <div class="columns my-2">
              <div class="column is-12">
                <RequestTypeSearch />
              </div>
            </div>
          </div>

          <div class="is-flex is-justify-content-center">
            <h1 class="subtitle is-3 has-text-secondary">
              Seleccione Módulo de Solicitudes
            </h1>
          </div>
          <!-- Separador -->
          <div class="columns my-0 pt-2 pb-0 is-desktop">
            <div v-if="can_view_gcc" class="column py-0">
              <b-collapse class="card" animation="slide" :open="false">
                <template #trigger="props">
                  <div
                    class="card-header"
                    role="button"
                    :aria-expanded="props.open"
                  >
                    <a
                      class="button is-customgrey is-border-grey is-large is-fullwidth card-header-title has-text-dark has-text-weight-normal"
                      href="/src/home"
                      style="
                        border-bottom-right-radius: 0;
                        border-top-right-radius: 0;
                      "
                    >
                      <span>
                        <i class="fas fa-list mx-3"></i>
                      </span>
                      <span> Módulo Solicitudes Registro Curricular (SRC) </span>
                    </a>
                    <a class="card-header-icon has-background-customgrey">
                      <b-icon
                        type="is-secondary"
                        size="is-medium"
                        :icon="props.open ? 'minus-circle' : 'plus-circle'"
                      >
                      </b-icon>
                    </a>
                  </div>
                </template>

                <div class="card-content">
                  <p class="is-size-5">
                    <b-icon icon="info-circle" type="is-info" />
                    Módulo de solicitudes de documentación de Registro
                    Curricular, en el cual estudiantes y ex-estudiantes de la
                    Facultad de Ingeniería de la Universidad de Santiago de
                    Chile pueden realizar sus solicitudes de certificados,
                    planes y programas.
                  </p>
                </div>
              </b-collapse>
            </div>
          </div>
          <div class="columns my-1 py-0 is-desktop">
            <div v-if="can_view_gsd" class="column">
              <b-collapse class="card" animation="slide" :open="false">
                <template #trigger="props">
                  <div
                    class="card-header"
                    role="button"
                    :aria-expanded="props.open"
                  >
                    <a
                      class="button is-customgrey is-border-grey is-large is-fullwidth card-header-title has-text-dark has-text-weight-normal"
                      href="/msd/home"
                      style="
                        border-bottom-right-radius: 0;
                        border-top-right-radius: 0;
                      "
                    >
                      <span>
                        <i class="fas fa-list mx-3"></i>
                      </span>
                      <span> Módulo Solicitudes Docentes (MSD) </span>
                    </a>
                    <a class="card-header-icon has-background-customgrey">
                      <b-icon
                        type="is-secondary"
                        size="is-medium"
                        :icon="props.open ? 'minus-circle' : 'plus-circle'"
                      >
                      </b-icon>
                    </a>
                  </div>
                </template>

                <div class="card-content">
                  <div class="content">
                    <p class="is-size-5">
                      <b-icon icon="info-circle" type="is-info" />
                      Módulo de solicitudes de Docencia, en el cual estudiantes
                      regulares de la Facultad de Ingeniería podrán realizar sus
                      solicitudes docentes. Por ejemplo, para realizar tu
                      solicitud de 'Justificación de Inasistencia' o 'Renuncia'.
                    </p>
                  </div>
                </div>
              </b-collapse>
            </div>


            <div v-if="can_view_soa" class="column">
              <a
                class="button is-customgrey is-large is-fullwidth"
                href="/gsd/home"
              >
                <span>
                  <i class="fas fa-list mx-2"></i>
                </span>
                <span> Solicitudes Oficina Administrativa </span>
              </a>
              <p style="padding: 1rem">
                Portal web de solicitudes de Oficina Administrativa de la Facultad de
                Ingeniería, en el cual se podrán realizar de manera más rápida
                estos trámites.
              </p>
            </div>
          </div>
          <div class="mt-5">
            <b-message type="is-info" has-icon style="font-size: 1.09em">
              Considere que algunas solicitudes se pueden realizar solo hasta
              <b>3</b> veces por sistema en un semestre cronológico del calendario
              (esto quiere decir, que si el año actual es 2024, los semestres
              cronológicos serán entre 01/01/2024 al 30/06/2024 y entre
              01/07/2024 al 31/12/2024). De superar esta cantidad, deberá
              realizar su solicitud por medio del canal presencial o el correo
              centralizado de la unidad correspondiente.
            </b-message>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestTypeSearch from "./RequestType/RequestTypeSearch.vue";
import Youtube from "./Tools/Youtube.vue";

export default {
  components: {
    Youtube,
    RequestTypeSearch,
  },
  name: "platform",
  props: ["can_view_gcc", "can_view_gsd", "can_view_soa"],
  data: function () {
    return {
      list_requests_path: "/requests",
      new_requests_path: "/requests/request_types",
      search_requests_path: "/requests/search",
      isModalSelectRequestTypeActive: false,
      requestTypes: ["", "Nombramiento"],
      selectedRequestType: null,
      carousels: [
        {
          isVideo: false,
          image: "shorcuts_images/default.png",
          link: "/#",
          condition: true,
          color: "#EE8600",
        },
        {
          isVideo: false,
          image: "shorcuts_images/applicant/mis_solicitudes.png",
          link: "/requests/my_requests",
          color: "#FF3860",
        },
        {
          isVideo: false,
          image: "shorcuts_images/applicant/registro_curricular.png",
          link: "/src/home",
          color: "#7957D5",
        },
        {
          isVideo: false,
          image: "shorcuts_images/applicant/docencia.png",
          link: "/msd/home",
          color: "#EE8600",
        },
        {
          isVideo: true,
          videoId: "wKnLDKv0qOE",
        }, // Video SAS YouTube
      ],
    };
  },

  created() {},

  methods: {},
};
</script>

<style scoped>
.image-container {
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
