<template>
  <div
    class="container is-fullheight"
    style="padding: 2rem; background-color: #f2f2f2"
    :class="isMobile ? 'p-2' : ''"
  >
    <div
      class="section"
      :class="isMobile ? 'p-2 pt-3' : ''"
      style="background-color: white; height: 100%"
    >
      <!-- Titulo de la sección -->
      <div class="columns is-vcentered mb-0 is-flex-wrap-wrap" :class="isMobile ? 'ml-3' : ''">
        <div class="column is-narrow">
          <h1 class="title is-1 has-text-secondary mb-0 mr-5">
            Solicitud Código {{ request.code }}
            <b-button
              class="mx-1 mt-1"
              :type="progressColor"
              :icon-left="progressIcon"
              size="is-medium"
              >{{ progress_of_request }}
            </b-button>
          </h1>
        </div>
        <div class="column"></div>
        <div v-if="canPrioritize" class="column is-narrow has-text-right">
          <b-button
            v-if="!request.priority"
            type="is-info is-light"
            icon-left="arrow-up"
            style="border: 1px solid darkblue"
            @click="togglePriority()"
          >
            Marcar solicitud como prioritaria
          </b-button>
          <b-button
            v-else
            type="is-warning is-light"
            icon-left="arrow-down"
            style="border: 1px solid darkgoldenrod"
            @click="togglePriority()"
          >
            Quitar prioridad
          </b-button>
        </div>
      </div>
      <b-message
        v-if="request.is_scheduled"
        has-icon
        type="is-info"
        icon="clock"
      >
        <b>Aceptación de solicitud programada:</b> <br />
        Esta solicitud está programada para ser enviada automáticamente al
        siguiente día hábil a las 08:00 hrs.
        <br />
        <b
          >Si deseas cancelar la programación de esta solicitud, ten en cuenta
          que los archivos de respuesta final serán eliminados y deberás volver
          a adjuntarlos al aceptar la solicitud nuevamente. Recuerde editar las
          observaciones en caso de ser necesario.</b
        >
        <div class="has-text-right mt-4">
          <b-button type="is-danger" icon-left="ban" @click="cancelSchedule">
            Cancelar Programación
          </b-button>
        </div>
      </b-message>

      <!-- Separador -->
      <hr class="solid" />

      <div class="column is-full ml-5" v-if="isCorrected">
        <b-message type="is-info" has-icon>
          Esta solicitud ha sido retrocedida. Por favor, revise con cuidado las
          respuestas.
        </b-message>
      </div>
      <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
        <b-message type="is-danger" has-icon>
          <div v-for="(item, index) in errores_validacion" :key="index">
            {{ item[0] }}
          </div>
        </b-message>
      </div>
      <!--      sección de datos-->
      <div class="columns box is-multiline my-2 px-5">
        <div class="column is-full has-text-centered mt-0 mb-3 py-0">
          <div class="column is-full has-text-centered mt-0 mb-3 py-0">
            <div class="subtitle is-3 mb-0 mt-0 has-text-centered">
              Tipo de Solicitud: <b>{{ request_type.real_name }}</b>
              <b-button
                v-if="request_type"
                class="is-info is-light is-border-cornflowerblue ml-2 mt-1"
                @click="expandedDescription = !expandedDescription"
              >
                <b-icon icon="info-circle" size="is-medium">
                </b-icon>
              </b-button>
            </div>
            <div
              class="subtitle is-4 mb-5"
              v-if="request_type.role_type && request_type.role_state"
            >
              [{{ request_type.role_type }}] [{{ request_type.role_state }}]
            </div>
            <div class="subtitle is-4 mb-2" v-else-if="request_type.role_type">
              [{{ request_type.role_type }}]
            </div>
            <b-message v-model="expandedDescription" type="is-info" has-icon>
              <div class="has-text-left">
                <b>Descripción de la solicitud:</b><br />
                <p v-html="replaceNewlines(request_type.long_description)"/>

              </div>
              <div class="has-text-right">
                <b-button @click="expandedDescription = !expandedDescription">
                  Cerrar
                </b-button>
              </div>
            </b-message>
          </div>
        </div>
        <div class="column is-full p-0 mt-3">
          <b-tabs
            v-model="currentTabSelected"
            position="is-centered m-0"
            type="is-toggle-rounded"
          >
            <b-tab-item icon="user" class="p-0" label="Datos Generales">
              <div class="mx-6">
                <div
                  class="subtitle is-5 has-text-secondary has-text-centered mt-4 mb-4"
                >
                  {{
                    "Datos del Solicitante " +
                    (siacData.nombres ? "(SIAC)" : "")
                  }}
                </div>

                <div class="columns is-multiline">
                  <div class="column is-half py-1">
                    <p v-if="!siacData.nombres">
                      <b>Nombre Gmail:</b> {{ applicantData.full_name }}
                    </p>
                    <p v-else>
                      <b>Nombre Completo:</b>
                      {{
                        siacData.nombres +
                        " " +
                        siacData.paterno +
                        " " +
                        siacData.materno
                      }}
                    </p>
                  </div>
                  <div class="column is-half py-1">
                    <b>Correo Institucional SIAC:</b>
                    {{ applicantData.corporate_mail }}
                  </div>
                  <div class="column is-half py-1">
                    <b>RUT:</b> {{ applicantData.run }}
                  </div>
                  <div v-if="siacData.nombres" class="column is-full py-1">
                    <div class="mb-2">
                      <b>Carreras solicitante: </b>
                    </div>
                    <ul>
                      <li
                        class="ml-5 mb-1"
                        v-for="(carrera, index) in siacData.carreras"
                        :key="index"
                        style="list-style-type: disc"
                      >
                        [{{ carrera.codigo_carrera }}]
                        {{ carrera.alt_nombre_carrera }}
                        {{ carrera.version_plan }}-{{ carrera.plan }} -
                        {{ carrera.tipo }}, {{ carrera.jornada }}
                        <b-icon
                          v-if="carrera.jornada === 'Diurno'"
                          class="mr-1"
                          icon="sun"
                        />
                        <b-icon
                          v-else-if="carrera.jornada === 'Vespertino'"
                          class="mr-1"
                          icon="moon"
                        />

                        Ingreso: {{ carrera.semestre_ingreso }}-{{
                          carrera.agno_ingreso
                        }}
                      </li>
                    </ul>
                  </div>
                  <div v-else class="column is-full">
                    <em>
                      Error con SIAC, hubo un error de conexión o el
                      participante no posee datos, los datos presentados
                      anteriormente pueden estar desactualizados.
                    </em>
                  </div>
                  <div class="columns">
                    <div class="column has-text-centered mt-1">
                      <b-button
                        class="is-secondary is-light is-border-cornflowerblue"
                        icon-left="list"
                        @click="() => (openModalAllRequests = true)"
                      >
                        Ver todas las solicitudes del solicitante
                      </b-button>
                    </div>
                  </div>
                </div>
                <div
                  class="subtitle is-5 has-text-secondary has-text-centered mt-0 mb-4"
                >
                  Datos de la Solicitud
                </div>

                <div class="columns is-multiline">
                  <div class="column is-12 py-1">
                    <b>Fecha de inicio solicitud:</b>
                    {{ request.start_date }}
                  </div>
                  <div class="column is-12 py-1">
                    <div class="mb-2">
                      <b>Carrera sobre la cual se realizó la solicitud: </b>
                    </div>
                    <div v-if="filterSelectedCareer">
                      <div class="circle-icon mr-2">
                        <b-icon
                          icon="clipboard-check"
                          class="has-text-white"
                          size="is-medium"
                        ></b-icon>
                      </div>
                      [{{ filterSelectedCareer.codigo_carrera }}]
                      {{ filterSelectedCareer.alt_nombre_carrera }}
                      {{ filterSelectedCareer.version_plan }}-{{
                        filterSelectedCareer.plan
                      }}
                      - {{ filterSelectedCareer.tipo }},
                      {{ filterSelectedCareer.jornada }}
                      <b-icon
                        v-if="filterSelectedCareer.jornada === 'Diurno'"
                        class="mr-1"
                        icon="sun"
                      />
                      <b-icon
                        v-else-if="
                          filterSelectedCareer.jornada === 'Vespertino'
                        "
                        class="mr-1"
                        icon="moon"
                      />

                      Ingreso: {{ filterSelectedCareer.semestre_ingreso }}-{{
                        filterSelectedCareer.agno_ingreso
                      }}
                    </div>
                    <div v-else>
                      <div class="circle-icon mr-2">
                        <b-icon
                          icon="clipboard-check"
                          class="has-text-white"
                          size="is-medium"
                        ></b-icon>
                      </div>
                      {{ formCareerName }}
                    </div>
                  </div>
                </div>

                <b-notification
                  v-if="
                    applicantData &&
                    applicantData.personal_email &&
                    !applicantData.corporate_email_active
                  "
                  :closable="false"
                  type="is-danger is-light"
                  has-icon
                  icon="exclamation-triangle"
                >
                  Este estudiante posee un correo alternativo habilitado para
                  acceder a su cuenta en el sistema SAS y recibir las
                  notificaciones, no utiliza su correo institucional registrado
                  en SIAC. <br />
                  El correo alternativo es el siguiente:
                  <b>{{ applicantData.personal_email }}</b
                  >.
                </b-notification>
                <b-notification
                  v-else-if="
                    applicantData &&
                    applicantData.personal_email &&
                    applicantData.corporate_email_active
                  "
                  :closable="false"
                  type="is-warning is-light"
                  has-icon
                  icon="exclamation-triangle"
                >
                  Este estudiante posee un correo alternativo asociado a su
                  cuenta en el sistema SAS. Sin embargo, el
                  <b>correo institucional USACH</b> es el habilitado para
                  recibir notificaciones e ingresar al sistema.
                  <br />
                </b-notification>
              </div>
              <div class="columns is-centered mt-6">
                <div class="column is-11">
                  <div style="border: 1px solid black; border-radius: 10px">
                    <div class="mb-2 mx-2">
                      <div class="subtitle is-5 my-2">
                        Cantidad de veces solicitada en esta carrera en el año:
                        <b>{{ applicantRequestTypeCount }}</b>
                      </div>
                      <div v-if="isGccRequest">
                        <b-icon
                          icon="exclamation-triangle"
                          type="is-warning"
                        ></b-icon>
                        Dependiendo de la cantidad de solicitudes realizadas
                        durante el año por la parte solicitante, este trámite
                        podría requerir un pago para continuar. Para realizar
                        este requerimiento, usted debe detener esta solicitud,
                        enviando un mensaje a través de la sección
                        <b>'Comentarios del paso'</b> detallando el
                        <b>monto a depositar</b>, los
                        <b>datos del depósito</b> y requerir un
                        <b>comprobante de pago</b> para continuar.

                        <div class="columns is-centered">
                          <div class="column is-5 mt-2">
                            <b-button
                              class="is-src is-fullwidth is-border-black"
                              icon-left="shopping-cart"
                              @click="
                                isModalPaymentInfoOpen = !isModalPaymentInfoOpen
                              "
                            >
                              Información de Pagos
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="p-0">
              <template #header>
                <span class="tab-header">
                  <b-icon icon="file-invoice"></b-icon>
                  <span>Observaciones y documentación de revisión interna </span>
                  <span
                    v-if="internalNotes.length"
                    :class="{
                      'notification-circle': true,
                      'has-background-white': currentTabSelected === 1,
                    }"
                    style="height: 24px; width: 24px"
                  >
                    <b
                      :class="{
                        'has-text-secondary': currentTabSelected === 1,
                      }"
                      >{{ internalNotes.length }}</b
                    >
                  </span>
                </span>
              </template>
              <div class="card-content">
                <div class="content">
                  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(confirmSendNote)">
                      <div class="columns is-multiline is-vcentered">
                        <div class="column is-full">
                          <b-notification type="is-info" has-icon>
                            En esta sección se pueden añadir observaciones de
                            uso interno entre las personas que revisan para
                            entregar información relevante para esta solicitud.
                            Estas observaciones son solo para quienes revisan y
                            no son vistas por la parte solicitante.<br />
                            Los formatos de archivo permitidos son: .pdf, .doc,
                            .docx, .png, .jpg, .jpeg y no pueden superar los
                            10MB de tamaño cada uno.<br />
                            <b>
                              ¡IMPORTANTE! En caso de haber un error en alguna
                              observación, se deberá realizar una nueva
                              observación a manera de rectificación.</b
                            >
                          </b-notification>
                        </div>

                        <div
                          v-if="canCreateInternalObservation"
                          class="column is-full mb-0 pb-0"
                        >
                          <ValidationProvider
                            rules="required|max:500"
                            v-slot="{ errors }"
                          >
                            <b-field label="Dejar una observación interna*">
                              <span v-if="answerTo" class="">
                                Estás respondiendo la observación interna con
                                código <b>{{ answerTo.code }}</b> de
                                <b>{{ answerTo.reviewer.full_name }}</b>
                              </span>
                              <b-input
                                class=""
                                type="textarea"
                                maxlength="500"
                                expanded
                                v-model="inputNote"
                                placeholder="Escriba sus observaciones aquí"
                              >
                              </b-input>
                            </b-field>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>

                        <div
                          v-if="canCreateInternalObservation"
                          class="column is-full mt-0 pt-0"
                        >
                          <div class="columns">
                            <div class="column">
                              <b-field class="file is-secondary">
                                <b-upload
                                  v-model="internalFiles"
                                  class="file-label is-primary"
                                  multiple
                                >
                                  <span class="file-cta">
                                    <b-icon
                                      class="file-icon"
                                      icon="upload"
                                    ></b-icon>
                                    <span class="file-label is-primary"
                                      >Subir uno o más archivos internos</span
                                    >
                                  </span>
                                </b-upload>
                              </b-field>
                              <div class="tags pt-3">
                                <span
                                  v-for="(file, index) in internalFiles"
                                  :key="index"
                                  class="tag is-primary"
                                >
                                  {{ file.name }} |
                                  {{
                                    Math.round((file.size * 100) / 1000000) /
                                    100
                                  }}MB
                                  <button
                                    class="delete is-small"
                                    type="button"
                                    @click="deleteDropInternalFile(index)"
                                  ></button>
                                </span>
                              </div>
                            </div>
                            <div class="column is-narrow has-text-right">
                              <b-button
                                v-if="answerTo"
                                type="is-danger"
                                icon-left="times"
                                @click="answerTo = null"
                              >
                                Cancelar respuesta
                              </b-button>
                              <b-button
                                v-if="answerTo"
                                type="is-primary"
                                icon-left="reply"
                                @click="checkValidation()"
                                native-type="submit"
                              >
                                Responder a la observación {{ answerTo.code }}
                              </b-button>
                              <b-button
                                v-else
                                type="is-primary"
                                icon-left="save"
                                @click="checkValidation()"
                                native-type="submit"
                              >
                                Guardar
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                  <!-- Ver notas de otros revisores -->
                  <hr class="solid" />
                  <div class="columns">
                    <div class="column pb-0 has-text-centered">
                      <h1 class="subtitle is-3 has-text-secondary mb-0">
                        Tabla de Observaciones y Documentación
                      </h1>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-full is-vcentered">
                      <internal-observation-table
                        :internal-notes="internalNotes"
                        :is-paginated="isPaginated"
                        :can-create="canCreateInternalObservation"
                        @view="openModalViewAllDocs"
                        @answer="setNoteResponse"
                      ></internal-observation-table>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
      <!--Sección de revision-->
      <div class="columns is-multiline mt-3 mb-2 px-5">
        <div class="column is-full has-text-centered">
          <h1 class="subtitle is-3 has-text-secondary mb-0">
            Revisión de la Solicitud
          </h1>
        </div>
        <div class="div column is-full has-text-centered">
          <a class="button is-primary" @click="isModalHistoryReview = true"
            >Ver Historial de Revisión <i class="fas fa-undo-alt ml-2"></i
          ></a>
        </div>
      </div>
      <!--Detalles de los estados-->
      <div class="columns is-centered">
        <div class="column">
          <b-steps v-if="states.length > 0" v-model="activeStep">
            <b-step-item
              v-for="(state, index_state) in states"
              :type="getStepColor(state.progress.name)"
              :step="index_state + 1"
              :label="`${index_state + 1}. ${state.real_name}`"
              :key="index_state"
              :clickable="true"
              :icon="getStepIcon(state.progress.name)"
            >
              <div class="columns p-1 is-centered">
                <!--               Estado del paso-->
                <div class="column" :class="isMobile ? 'px-0' : ''">
                  <div class="column has-text-centered">
                    <h1
                      v-if="state.progress.name === 'En Curso'"
                      class="title is-4 ml-3 mb-1 has-text-secondary"
                    >
                      La solicitud se encuentra en el paso
                      {{ state.real_name }} ({{ getSteps(state.id) }})
                      <i class="fas fa-map-marker-alt"></i>
                    </h1>

                    <h1
                      v-if="state.progress.name === 'Aprobado'"
                      class="title is-4 ml-3 mb-1 has-text-success"
                    >
                      Paso Aprobado ({{ getSteps(state.id) }})
                      <i class="fas fa-check-circle"></i>
                    </h1>

                    <h1
                      v-if="state.progress.name === 'Aceptada'"
                      class="title is-4 ml-3 mb-1 has-text-success"
                    >
                      Solicitud Aceptada ({{ getSteps(state.id) }})
                      <i class="fas fa-double"></i>
                    </h1>

                    <h1
                      v-if="state.progress.name === 'Detenida'"
                      class="title is-4 ml-3 mb-1 has-text-danger"
                    >
                      La solicitud se encuentra detenida en el paso
                      {{ state.real_name }} ({{ getSteps(state.id) }})
                      <i class="fas fa-stop"></i>
                    </h1>

                    <h1
                      v-if="state.progress.name === 'Sin Iniciar'"
                      class="title is-4 ml-3 mb-1 has-text-dark"
                    >
                      Este paso aún no ha sido iniciado ({{
                        getSteps(state.id)
                      }})
                      <i class="fas fa-bookmark"></i>
                    </h1>
                    <div></div>
                  </div>

                  <!--Descripcion del paso-->
                  <div class="column has-text-centered mx-5 pt-0">
                    <strong>Infomación del paso: </strong>
                    {{ state.information }}
                  </div>

                  <!--Duracion si tiene del paso-->
                  <div v-if="state.duration != 0" class="column mx-5">
                    <strong>Duración aproximada: </strong>
                    {{ state.duration + " días" }}
                  </div>
                  <div
                    v-if="state.can_assign_state"
                    class="column is-full has-text-centered"
                  >
                    <b-button
                      v-if="canAssignRequest"
                      type="is-primary is-light is-border-darkgoldenrod"
                      icon-left="file-signature"
                      @click="autoAssignReviewer(state.id, request.id)"
                    >
                      Asignarme el paso {{ state.real_name }} ({{
                        getSteps(state.id)
                      }})
                    </b-button>
                  </div>
                  <template v-else>
                    <div
                      v-if="
                        canChangeReviewer &&
                        pendingRequest &&
                        (state.progress.name === 'En Curso' ||
                          state.progress.name === 'Detenida')
                      "
                      class="column is-full has-text-centered"
                    >
                      <b-button
                        type="is-warning is-light is-border-darkgoldenrod"
                        icon-left="file-signature"
                        @click="reassignReviewer(state.id)"
                      >
                        Reasignar revisión
                      </b-button>
                    </div>
                  </template>
                  <!--                  SECCION DOCUMENTO Y FORMULARIO-->
                  <div class="card mt-4">
                    <div class="card-content">
                      <div class="column is-full">
                        <h1
                          class="title has-text-left is-4 has-text-secondary mb-0 ml-3"
                        >
                          Justificación de la solicitud
                        </h1>
                      </div>
                      <div class="m-0 p-0 pr-5">
                        <div class="column is-full is-border-light-grey ml-5">
                          <span>
                            <b
                              >Motivos de la solicitud aportados por
                              solicitante:
                            </b>
                            {{
                              request.description
                                ? request.description
                                : "Esta solicitud no tiene descripción"
                            }}
                          </span>
                        </div>
                      </div>
                      <!-- Formularios del paso -->
                      <div class="column is-full">
                        <h1
                          class="title has-text-left is-4 has-text-secondary mb-0 ml-3"
                        >
                          Formularios respondidos
                        </h1>
                      </div>

                      <div
                        class="columns is-multiline"
                        v-if="state.google_forms.length === 0"
                      >
                        <div class="column is-centered is-full">
                          <b-message type="is-info" class="ml-5" has-icon>
                            No se requiere completar ningún formulario en este
                            paso
                          </b-message>
                        </div>
                      </div>

                      <div
                        v-if="state.google_forms.length > 0"
                        class="m-0 p-0 pr-5"
                      >
                        <div
                          v-for="(form, form_index) in state.google_forms"
                          :key="form_index"
                          class="column is-full ml-5 p-0"
                        >
                          <dt v-if="form.form_responses.length == 0">
                            <div
                              class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap my-1"
                            >
                              <div class="is-flex content mb-0 p-3">
                                <strong class="mb-0">{{ form.name }}</strong>
                              </div>
                              <div class="is-flex m-2">
                                <b-button
                                  v-if="
                                    state.progress.name === 'En Curso' &&
                                    state.can_review_state &&
                                    form.form_responses.length === 0
                                  "
                                  type="is-success mr-2"
                                  size=" is-small"
                                  @click="
                                    openModalAddFormResponse(state.id, form)
                                  "
                                >
                                  Añadir Respuesta
                                  <i class="fas fa-poll-h ml-2"></i>
                                </b-button>
                              </div>
                            </div>
                            <b-message
                              v-if="form.form_responses.length === 0"
                              type="is-info"
                              has-icon
                              class="m-3 ml-5"
                            >
                              Este Formulario aún no ha sido respondido.
                            </b-message>
                          </dt>

                          <div
                            v-for="(
                              response, response_index
                            ) in form.form_responses"
                            :key="response_index"
                            class="column is-full is-border-light-grey"
                          >
                            <div class="columns is-vcentered my-1">
                              <div class="column">
                                <span
                                  ><b>Nombre del formulario: </b>
                                  {{ form.name }}</span
                                >
                              </div>
                              <div class="column is-narrrow has-text-right">
                                <b-button
                                  style="background-color: #008080"
                                  type="is-primary mx-1"
                                  @click="openModalShowFormResponse(response)"
                                  icon-right="eye"
                                >
                                  Ver Respuesta Formulario
                                </b-button>
                                <!-- NO ES POSIBLE EDITAR POR AHORA ASI QUE SE DESACTIVA
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <b-button type="is-secondary mx-1" outlined size="is-small" @click="openModalEditFormResponse(response)">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            Editar Respuesta <i class="fas fa-edit ml-2"></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </b-button>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Documents Section -->
                      <div class="column is-full">
                        <h1
                          class="title is-4 has-text-left has-text-secondary mb-0 ml-3"
                        >
                          Documentos adjuntados
                        </h1>
                      </div>

                      <div
                        v-if="
                          state.documents.length === 0 ||
                          isFinalResponseDocument(state.documents)
                        "
                        class="columns is-multiline"
                      >
                        <div class="column is-centered is-full">
                          <b-message type="is-info" has-icon class="ml-5">
                            No se requieren documentos en este paso.
                          </b-message>
                        </div>
                      </div>

                      <div
                        v-if="
                          state.documents.length > 0 &&
                          !isFinalResponseDocument(state.documents)
                        "
                        class="m-0 p-0 pr-5"
                      >
                        <div
                          v-for="(document, document_index) in state.documents"
                          :key="document_index"
                          class="column is-full ml-5 mb-2 is-border-light-grey"
                        >
                          <div class="columns is-vcentered my-1">
                            <div class="column has-text-left">
                              <span
                                ><b>Nombre del documento: </b>
                                {{ setName(document.name) }}
                              </span>
                            </div>
                            <div
                              class="column has-text-centered"
                              v-if="document.document_token"
                            >
                              <p>
                                <b>Código de verificación:</b>
                                {{ document.document_token }}
                              </p>
                            </div>
                            <div
                              class="column is-2"
                              v-if="document.has_file"
                            >
                              <div class="level-right">
                                <b-button
                                  icon-right="download"
                                  type="is-secondary"
                                  @click="
                                    downloadFile(
                                      document.id,
                                      false,
                                      document_index
                                    )
                                  "
                                >
                                </b-button>
                                <b-button
                                  type="is-primary mx-1"
                                  icon-right="eye"
                                  @click="openModalShowDocument(document)"
                                >
                                  Previsualizar
                                </b-button>
                              </div>
                            </div>
                            <div v-else class="column">
                              <b-tag
                                v-if="state.progress.name !== 'En Curso'"
                                type="is-primary is-light"
                              >
                                Este documento no es guardado en este tipo de
                                solicitud
                              </b-tag>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Observations -->
                  <div class="card mt-4">
                    <div class="card-content">
                      <div class="column mb-0 ml-3">
                        <h1 class="title has-text-left is-4 has-text-secondary">
                          Comentarios del paso
                        </h1>
                        <div>
                          <b>¡PRECAUCIÓN!</b><br />
                          Los comentarios ingresados aquí se vinculan
                          directamente con el paso actual de la solicitud y
                          podrían ser enviados a la parte solicitante. A
                          continuación se describe cuando son enviados o no los
                          comentarios a la parte solicitante
                          <ul style="list-style-type: disc !important">
                            <li>
                              <b class="has-text-danger"
                                >El comentario no será enviado a la parte
                                solicitante</b
                              >
                              <b-icon
                                class="fas fa-lg"
                                type="is-danger"
                                icon="ban"
                              ></b-icon>
                              <b-icon
                                class="fas fa-lg"
                                type="is-danger"
                                icon="envelope"
                              >
                              </b-icon>
                              si el <b>paso es aprobado</b>, es decir, cuando la
                              solicitud avanza al siguiente paso.
                            </li>
                            <li>
                              <b class="has-text-success"
                                >El comentario será enviado a la parte
                                solicitante</b
                              >
                              <b-icon
                                class="fas fa-lg"
                                type="is-success"
                                icon="check"
                              ></b-icon>
                              <b-icon
                                class="fas fa-lg"
                                type="is-success"
                                icon="envelope"
                              >
                              </b-icon>
                              si la solicitud <b>cambia de estado</b>, es decir,
                              es <b>detenida</b>,
                              <b>aceptada</b>
                              <b>{{ canRejectState ? ", rechazada" : "" }}</b>
                              o marcada como <b class="">no corresponde</b>. Por
                              lo tanto debe asegurarse de que su comentario sea
                              claro y justifique este cambio de estado.
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="column is-full m-0 p-0 pr-5 ml-5">
                        <b-field label="Comentarios*">
                          <b-input
                            class=""
                            v-if="editObservation || !state.observation"
                            type="textarea"
                            maxlength="30000"
                            expanded
                            v-model="inputObservation"
                            :disabled="
                              (state.progress.name !== 'En Curso' &&
                                state.progress.name !== 'Enviada') ||
                              !state.can_review_state
                            "
                            placeholder="Escriba sus observaciones aquí"
                          >
                          </b-input>

                          <div v-else class="is-border-light-grey p-4">
                            <span
                              v-if="state.observation"
                              style="white-space: pre-line"
                              >{{ state.observation }}</span
                            >
                            <span v-else>
                              Aún no se han realizado observaciones
                            </span>
                          </div>
                        </b-field>
                      </div>
                      <div
                        v-if="
                          canAddObservation &&
                          (state.progress.name === 'En Curso' ||
                            state.progress.name === 'Enviada') &&
                          state.can_review_state &&
                          (editObservation || !state.observation)
                        "
                        class="column has-text-left is-full m-0 p-0 pr-5 ml-5"
                        style="margin-top: -30px"
                      >
                        <b-button
                          type="is-ghost is-border-light-grey"
                          @click="inputObservation += 'Se aprueba el paso.'"
                          >Se aprueba el paso.
                        </b-button>
                        <b-button
                          type="is-ghost is-border-light-grey"
                          @click="inputObservation += 'Se detiene porque '"
                          >Se detiene porque...
                        </b-button>
                        <b-button
                          type="is-ghost is-border-light-grey"
                          @click="inputObservation += 'Se acepta la solicitud.'"
                          >Se acepta la solicitud.
                        </b-button>
                        <b-button
                          v-if="canRejectState"
                          type="is-ghost is-border-light-grey"
                          @click="inputObservation += 'Se rechaza porque '"
                          >Se rechaza porque...
                        </b-button>
                        <b-button
                          type="is-ghost is-border-light-grey"
                          @click="inputObservation += 'No corresponde porque '"
                        >
                          No corresponde porque...
                        </b-button>
                        <b-button
                          v-if="isGccRequest"
                          type="is-src is-border-light-grey"
                          @click="inputObservation += paymentInfoMessage()"
                          icon-left="shopping-cart"
                        >
                          Agregar datos de depósito
                        </b-button>
                      </div>
                      <div
                        v-if="
                          (editObservation || !state.observation) &&
                          (state.progress.name === 'En Curso' ||
                            state.progress.name === 'Enviada') &&
                          state.can_review_state
                        "
                        class="column is-full mr-5 has-text-right"
                      >
                        <b-button
                          v-if="state.observation"
                          type="is-secondary"
                          @click="
                            editObservation = false;
                            inputObservation = '';
                          "
                          icon-left="times"
                        >
                          Cancelar
                        </b-button>
                        <b-button
                          type="is-primary"
                          @click="
                            addObservation(
                              state.id,
                              inputObservation,
                              index_state
                            )
                          "
                          icon-left="save"
                        >
                          Guardar comentario
                        </b-button>
                      </div>
                      <div class="column is-full">
                        <div class="columns has-text-right">
                          <div class="column">
                            <b-button
                              v-if="state.pending_appeal"
                              icon-left="comments"
                              type="is-info"
                              @click="openModalObservation(state.id)"
                            >
                              Hay respuestas a la observación pendientes por
                              responder
                            </b-button>
                            <b-button
                              v-else-if="state.has_appeal"
                              type="is-warning is-light is-border-darkgoldenrod "
                              @click="openModalObservation(state.id)"
                              icon-left="comments"
                            >
                              Ver respuestas a comentarios de la detención
                            </b-button>
                            <b-button
                              v-if="
                                canAddObservation &&
                                (state.progress.name === 'En Curso' ||
                                  state.progress.name === 'Enviada') &&
                                state.can_review_state &&
                                !editObservation &&
                                state.observation
                              "
                              type="is-secondary mx-1"
                              @click="
                                editObservation = true;
                                inputObservation = state.observation;
                              "
                              :disabled="request.is_scheduled"
                            >
                              Editar Comentarios
                              <i class="fas fa-edit ml-2"></i>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Optional tools -->
                  <div class="card mt-4">
                    <div class="card-content">
                      <div class="column mb-0 ml-3">
                        <h1 class="title has-text-left is-4 has-text-secondary">
                          Herramientas Opcionales
                        </h1>
                        <br />
                        <div class="columns">
                          <div class="column">
                            <b-button
                              type="is-primary"
                              icon-left="file-signature"
                              @click="openModalSignatureFile()"
                              expanded
                            >
                              Firmar Documento de Solicitud
                            </b-button>
                          </div>
                          <div class="column">
                            <b-button
                              type="is-src"
                              icon-left="file"
                              expanded
                              :disabled="!hasRequestTypeTemplate"
                              @click="openModalCreateDocument()"
                            >
                              Generar Documento
                            </b-button>
                          </div>
                          <div class="column">
                            <b-button
                              type="is-secondary"
                              icon-left="file-invoice"
                              @click="openModalIntern()"
                              expanded
                            >
                              Agregar Obs. y Docs. de Revisión Interna

                              <span
                                v-if="internalNotes && internalNotes.length > 0"
                                class="notification-circle has-background-white has-text-secondary"
                              >
                                <b>{{ internalNotes.length }}</b>
                              </span>
                            </b-button>
                            <div class="column px-0">
                              <b-button
                                type="is-secondary is-light is-border-cornflowerblue"
                                class="is-fullwidth"
                                @click="() => (openModalDownloadDocuments = true)"
                                icon-left="download"
                                style="width: 23.9em"
                              >
                                Descargar documentación de la solicitud
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!---B-UPLOAD--->
                  <div
                    class="card mt-4"
                    v-if="
                      index_state === states.length - 1 ||
                      isRejectingRequest ||
                      isFinalResponseDocument(state.documents)
                    "
                  >
                    <div class="card-content">
                      <div class="column">
                        <h1
                          class="title has-text-left is-4 has-text-secondary mb-0 ml-3"
                        >
                          Archivos de respuesta final a la solicitud
                          {{
                            isFinalResponseDocument
                              ? request_type.real_name
                              : ""
                          }}
                        </h1>
                      </div>

                      <div
                        class="column has-text-centered is-full m-0 p-0 pr-5 ml-5"
                        v-if="!isFinalResponseDocument(state.documents)"
                      >
                        <RequestDocumentsTable
                          class="my-3"
                          :files="requestFiles"
                          v-if="
                            !((state.progress.name !== 'En Curso' &&
                              state.progress.name !== 'Enviada') ||
                            !state.can_review_state ||
                            request.is_scheduled)
                          "
                          @sendAsFinalResponse="addFileToFinalResponse"
                        />
                        <b-upload
                          v-model="dropFiles"
                          multiple
                          drag-drop
                          :disabled="
                            (state.progress.name !== 'En Curso' &&
                              state.progress.name !== 'Enviada') ||
                            !state.can_review_state ||
                            request.is_scheduled
                          "
                          expanded
                          accept=".pdf,.PDF"
                          @input="updateDropFiles"
                        >
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"></b-icon>
                              </p>
                              Arrastra aquí tus archivos o clickea para subir<br />
                              <i
                                >Extensiones permitidas: '.pdf', '.PDF'<br />
                                Tamaño máximo: 25MB<br />
                              </i>
                            </div>
                          </section>
                        </b-upload>

                        <DocumentFinalResponseTable
                          class="my-3"
                          v-if="
                            !((state.progress.name !== 'En Curso' &&
                              state.progress.name !== 'Enviada') ||
                            !state.can_review_state ||
                            request.is_scheduled)
                          "
                          :files="filesToSend"
                          @delete="handleDeleteFinalResponse"
                        />
                      </div>
                      <div v-else>
                        <div
                          v-for="(document, document_index) in state.documents"
                          :key="document_index"
                          class="column is-border-light-grey"
                          v-if="document.name.includes('[RESPUESTA FINAL]')"
                        >
                          <div class="columns is-vcentered my-1">
                            <div class="column has-text-left">
                              <span>
                                Documento de respuesta
                                {{
                                  getDocumentIndex(
                                    state.documents,
                                    document_index
                                  ) + 1
                                }}
                                <b-tooltip
                                  multilined
                                  v-if="document.original_filename"
                                  type="is-primary is-info"
                                  :label="
                                    'Nombre original: ' +
                                    document.original_filename
                                  "
                                >
                                  <b-icon
                                    icon="info-circle"
                                    type="primary is-info"
                                  ></b-icon>
                                </b-tooltip>
                              </span>
                            </div>
                            <div
                              class="column has-text-centered"
                              v-if="document.document_token"
                            >
                              <p>
                                <b>Código de verificación:</b>
                                {{ document.document_token }}
                              </p>
                            </div>
                            <div
                              class="column is-2"
                              v-if="document.has_file"
                            >
                              <div class="level-right">
                                <b-button
                                  icon-right="download"
                                  type="is-secondary"
                                  @click="
                                    downloadFile(
                                      document.id,
                                      true,
                                      document_index
                                    )
                                  "
                                >
                                </b-button>
                                <b-button
                                  type="is-primary mx-1"
                                  icon-right="eye"
                                  @click="openModalShowDocument(document)"
                                >
                                </b-button>
                              </div>
                            </div>
                            <div v-else class="column">
                              <b-tag
                                v-if="state.progress.name !== 'En Curso'"
                                type="is-primary is-light"
                              >
                                Este documento no es guardado en este tipo de
                                solicitud
                              </b-tag>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!---BUTTONS--->

                  <div
                    v-if="
                      (state.progress.name === 'En Curso' ||
                        state.progress.name === 'Enviada') &&
                      !isRejectingRequest
                    "
                    class="column is-flex is-justify-content-center pb-1"
                  >
                    <b-button
                      v-if="state.can_review_state && canStopState === true"
                      icon-left="hand-paper"
                      type="is-danger"
                      class="mx-1"
                      :disabled="!state.observation || request.is_scheduled"
                      @click="openDialogStopState(state.id, state.real_name)"
                    >
                      Detener Solicitud
                    </b-button>
                    <b-button
                      v-if="state.can_review_state && canApproveState === true"
                      icon-left="check-double"
                      type="is-success"
                      class="mx-1"
                      :disabled="!state.observation || request.is_scheduled"
                      @click="
                        openDialogApproveState(
                          state.id,
                          state.real_name,
                          index_state
                        )
                      "
                    >
                      {{
                        index_state === states.length - 1
                          ? "Aceptar Solicitud"
                          : "Aprobar Paso"
                      }}
                    </b-button>
                  </div>
                  <div
                    v-else-if="isRejectingRequest"
                    class="column has-text-centered buttons"
                  >
                    <b-button
                      class="is-primary is-light is-border-darkgoldenrod"
                      @click="() => (isRejectingRequest = false)"
                      icon-left="arrow-left"
                    >
                      Cancelar rechazo
                    </b-button>
                    <b-button
                      type="is-danger"
                      @click="openDialogDenyState(state.id)"
                      icon-left="times"
                    >
                      Rechazar solicitud
                    </b-button>
                  </div>
                  <div
                    class="column has-text-centered pt-0"
                    v-if="
                      (state.progress.name === 'En Curso' ||
                        state.progress.name === 'Enviada') &&
                      !isRejectingRequest
                    "
                  >
                    <b-button
                      v-if="
                        index_state === states.length - 1 &&
                        state.can_review_state &&
                        canApproveState === true
                      "
                      type="is-info"
                      :disabled="!state.observation || request.is_scheduled"
                      @click="openDialogScheduleState(state.id)"
                      icon-left="clock"
                    >
                      Programar <b>aceptación</b> de solicitud para próximo día
                      hábil a las 8:00 am
                    </b-button>
                    <b-button
                      v-if="
                        index_state === states.length - 1 &&
                        state.can_review_state &&
                        canApproveState === true &&
                        request.is_scheduled
                      "
                      type="is-danger"
                      icon-left="ban"
                      @click="cancelSchedule"
                    >
                      Cancelar Programación
                    </b-button>
                  </div>

                  <div
                    v-if="state.progress.name === 'Detenida'"
                    class="column is-flex is-justify-content-center"
                  >
                    <a
                      @click="openDialogResumeState(state.id, state.real_name)"
                      class="button is-success"
                    >
                      <i class="fas fa-file-export mr-2"></i>
                      Reanudar Paso
                    </a>
                  </div>
                </div>
              </div>
              <hr class="solid" />
              <div class="level-left">
                <div class="buttons" v-if="!isRejectingRequest">
                  <b-button
                    v-if="canDeny(state) && canRejectState"
                    type="is-danger"
                    @click="() => (isRejectingRequest = true)"
                    icon-left="times"
                    :disabled="!state.observation || request.is_scheduled"
                  >
                    Rechazar solicitud
                  </b-button>
                  <b-button
                    v-if="canDeny(state)"
                    class="is-dont-correspond-request"
                    @click="openDialogNotCorrespondState(state.id)"
                    icon-left="file-excel"
                    :disabled="!state.observation || request.is_scheduled"
                  >
                    Marcar como No Corresponde</b-button
                  >
                  <b-button
                    v-if="canDeny(state) && canSetNotProcessed"
                    class="is-unprocessed-request"
                    @click="openDialogNotProcessedState(state.id)"
                    icon-left="comment-slash"
                    :disabled="!state.observation || request.is_scheduled"
                  >
                    Marcar como No Procesada</b-button
                  >
                </div>
              </div>
            </b-step-item>

            <template #navigation="{ previous, next }">
              <div class="columns has-text-centered">
                <div class="column">
                  <b-button
                    outlined
                    icon-left="chevron-left"
                    :disabled="previous.disabled"
                    @click.prevent="previous.action"
                  >
                  </b-button>
                  <b-button
                    outlined
                    icon-right="chevron-right"
                    :disabled="next.disabled"
                    @click.prevent="next.action"
                  >
                  </b-button>
                </div>
              </div>
            </template>
          </b-steps>
        </div>
      </div>
      <!--------------------------------------------------------------- Modals --------------------------------------------------------------------->
      <b-modal
        v-model="openModalAllRequests"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="80%"
      >
        <template #default="props">
          <ModalAllRequestFromUser
            :applicant-data="applicantData"
            @close="props.close"
          >
          </ModalAllRequestFromUser>
        </template>
      </b-modal>
      <b-modal
        v-model="openModalDownloadDocuments"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="80%"
      >
        <template #default="props">
          <ModalDownloadDocuments
            :id="request.id"
            @close="props.close"
            :code="request.code"
            :files="requestFiles"
          >
          </ModalDownloadDocuments>
        </template>
      </b-modal>
      <b-modal
        v-model="isModalAnswerObservationReviewer"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="90%"
      >
        <template #default="props">
          <modal-answer-observation-reviewer
            modal-header="Historial de observaciones"
            :request_state_id="currentStateObservation"
            :in_progress="isStopped"
            :is-gcc-request="isGccRequest"
            :can-assign-request="canAssignRequest"
            @close="closeModalAnswerObservation()"
            :request_code="request.code"
          >
          </modal-answer-observation-reviewer>
        </template>
      </b-modal>
      <b-modal
        v-model="isModalHistoryReview"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="90%"
      >
        <template #default="props">
          <modal-history-review :requestId="requestId" @close="props.close">
          </modal-history-review>
        </template>
      </b-modal>
      <b-modal
        v-model="isModalReassignReviewer"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="80%"
      >
        <template #default="props">
          <modal-reassign-reviewer
            :requestStateId="currentRequestStateId"
            @close="props.close"
            @updated="reload"
          >
          </modal-reassign-reviewer>
        </template>
      </b-modal>

      <b-modal
        v-model="isModalShowFormResponseActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="90%"
      >
        <template #default="props">
          <modal-show-form-response
            modal-header="Ver Respuesta de Formulario"
            :google_form_id="currentFormId"
            :google_form_response_id="currentFormResponseId"
            :is_reviewer="true"
            :carreers="siacData.carreras"
            :student="siacData"
            :student-rut="applicantData.run"
            @close="props.close"
            :request-description="request.description"
          >
          </modal-show-form-response>
        </template>
      </b-modal>
      <b-modal
        v-model="isModalEditFormResponseActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="90%"
      >
        <template #default="props">
          <modal-edit-form-response
            modal-header="Editar Respuesta de Formulario"
            :google_form_id="currentFormId"
            :google_form_response_id="currentFormResponseId"
            @close="props.close"
          >
          </modal-edit-form-response>
        </template>
      </b-modal>
      <b-modal
        v-model="isModalShowDocumentActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="70%"
      >
        <template #default="props">
          <preview-program-modal :docId="src" @close="props.close">
          </preview-program-modal>
        </template>
      </b-modal>
      <b-modal
        v-model="isModalAddFormResponseActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="90%"
      >
        <template #default="props">
          <modal-add-form-response
            modal-header="Responder Formulario"
            :request_type_id="request_type.id"
            :request_state_id="currentRequestStateId"
            :google_form_id="currentFormId"
            :request_id="request.id"
            @close="props.close"
          >
          </modal-add-form-response>
        </template>
      </b-modal>
      <b-modal
        v-model="isModalViewAllDocsActive"
        trap-focus
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="100%"
        height="80%"
      >
        <template #default="props">
          <modal-view-all-docs
            :request_id="requestId"
            :internal_observation_id="internalObservationId"
            @close="props.close"
            :observation-by="selectedInternalNote.reviewer.full_name"
            :date="selectedInternalNote.created_at"
            :observation-code="selectedInternalNote.code"
            :observation="selectedInternalNote.observation"
            style="width: 80vw"
          >
          </modal-view-all-docs>
        </template>
      </b-modal>
      <b-modal
        v-model="isModalUploadDocumentsActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="90%"
      >
        <template #default="props">
          <modal-upload-document
            modal-header="Subir Documento"
            :request_state_id="currentState"
            :document_id="currentDocumentToUpload"
            :request_id="request.id"
            :multiple="false"
            @close="props.close"
          >
          </modal-upload-document>
        </template>
      </b-modal>

      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
    <b-modal
      v-model="isModalPaymentInfoOpen"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="90%"
    >
      <template #default="props">
        <PaymentInfoModal @close="props.close"></PaymentInfoModal>
      </template>
    </b-modal>

    <b-modal
      v-model="isModalObservationDocumentationIntern"
      trap-focus
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="90%"
    >
      <template #default="props">
        <modal-observation-documentation-intern
          :isModal="true"
          :request-id="request.id"
          :internal-notes="internalNotes"
          :all-internal-notes="allInternalNotes"
          :can-create-internal-observation="canCreateInternalObservation"
          @close="props.close"
          @reload="updateRequestFiles"
          style="width: 80vw"
        >
        </modal-observation-documentation-intern>
      </template>
    </b-modal>

    <b-modal
      v-model="isModalSignatureFile"
      trap-focus
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="100%"
    >
      <template #default="props">
        <modal-signature-file
          :isModal="true"
          :request-id="request.id"
          :request-code="request.code"
          :files="requestFiles"
          :user="user"
          :applicant-data="applicantData"
          :can-create-internal-observation="canCreateInternalObservation"
          @close="props.close"
          @reload="updateRequestFiles"
          @reloadObservation="getInternalNotes"
          style="width: 80vw !important"
        >
        </modal-signature-file>
      </template>
    </b-modal>

    <b-modal
      v-model="modalGenerateDocument"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="80%"
    >
      <template #default="props">
        <ModalGenerateDocument
          :request-type-name="request_type.real_name"
          :request-type-id="request_type.id"
          :career-name="filterSelectedCareer.alt_nombre_carrera"
          :user-id="applicantData.user_id"
          :entry-date="`${filterSelectedCareer.semestre_ingreso}-${filterSelectedCareer.agno_ingreso}`"
          :user="user"
          :applicant-data="applicantData"
          :request-code="request.code"
          :can-create-internal-observation="canCreateInternalObservation"
          :request-id="request.id"
          :request-start-date="request.start_date"
          @close="props.close"
          @append="appendDocument"
          @reload="updateRequestFiles"
          @reloadObservation="getInternalNotes"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import ModalReassignReviewer from "./ModalReassignReviewer.vue";
import ModalEditForm from "../ModalEditForm.vue";
import ModalShowDocument from "../../Documents/ModalShowDocument.vue";
import ModalUploadDocument from "../../Documents/ModalUploadDocument.vue";
import ModalAddFormResponse from "../../GoogleForms/ModalAddFormResponse.vue";
import ModalShowFormResponse from "../../GoogleForms/ModalShowFormResponse.vue";
import ModalEditFormResponse from "../../GoogleForms/ModalEditFormResponse.vue";
import ModalAnswerObservationReviewer from "./ModalAnswerObservationReviewer.vue";
import ModalHistoryReview from "./ModalHistoryReview.vue";
import PreviewProgramModal from "../../Documents/PreviewProgramModal.vue";
import {
  dateToStringWithHour,
  getStateInfo,
  openBuefyAlertDialog,
  openBuefyConfirmDialog,
  setName,
  verifyFile,
} from "../../../packs/utilities";
import InternalObservationTable from "./InternalObservationTable.vue";
import ModalViewAllDocs from "./ModalViewAllDocs.vue";
import moment from "moment";

export default {
  name: "SpecificRequestReviewer",
  props: [
    "requestId",
    "canApproveState",
    "canStopState",
    "canAddObservation",
    "canPrioritize",
    "pendingRequest",
    "canRejectState",
    "canSetNotProcessed",
    "canChangeReviewer",
    "isGccRequest",
    "canDownloadAllRequest",
    "user",
    "hasRequestTypeTemplate",
    "canAssignRequest",
  ],
  components: {
    ModalViewAllDocs,
    InternalObservationTable,
    PreviewProgramModal,
    ModalEditForm,
    ModalShowDocument,
    ModalUploadDocument,
    ModalAddFormResponse,
    ModalShowFormResponse,
    ModalEditFormResponse,
    ModalAnswerObservationReviewer,
    ModalHistoryReview,
    ModalReassignReviewer,
    PaymentInfoModal: () => import("../../RequestType/PaymentInfoModal.vue"),
    ModalObservationDocumentationIntern: () =>
      import("./ModalObservationDocumentationIntern.vue"),
    ModalSignatureFile: () =>
      import("../../Signatures/SimpleDigitalSignatureView.vue"),
    ModalGenerateDocument: () => import("./ModalGenerateDocument.vue"),
    TableListRequests: () => import("../TableListRequests.vue"),
    ModalAllRequestFromUser: () => import("./ModalAllRequestFromUser.vue"),
    ModalDownloadDocuments: () => import("./ModalDownloadDocuments.vue"),
    DocumentFinalResponseTable: () =>
      import("../../Documents/DocumentFinalResponseTable.vue"),
    RequestDocumentsTable: () => import("./RequestDocumentsTable.vue"),
  },
  data: function () {
    return {
      request: {
        code: "",
        start_date: "",
      },
      requestFiles: [],
      request_type: {
        role_type: "",
      },
      states: {},
      // applicantData se debe obtener desde SIGA
      applicantData: {
        commune: {},
      },
      documents: [],
      dropFiles: [],
      currentState: 0,
      currentStateObservation: 0,
      isLoading: false,
      isModalEditFormActive: false,
      isModalShowDocumentActive: false,
      isModalUploadDocumentsActive: false,
      isModalAddFormResponseActive: false,
      isModalShowFormResponseActive: false,
      isModalEditFormResponseActive: false,
      isModalAnswerObservationReviewer: false,
      isModalViewAllDocsActive: false,
      isModalHistoryReview: false,
      isModalReassignReviewer: false,
      editObservation: false,
      isStopped: false,
      currentFormId: "",
      currentFormResponseId: "",
      currentDocumentIdDrive: "",
      currentRequestStateId: "",
      currentDocumentToUpload: "",
      inputObservation: "",
      activeStep: 0,
      progress_of_request: "Enviada",
      internalFiles: [],
      formCareerName: null,
      progresses: [
        {
          name: "En Curso",
          icon: "angle-double-right",
        },
        {
          name: "Sin Iniciar",
          icon: "bookmark",
        },
        {
          name: "Aprobado",
          icon: "check-circle",
        },
        {
          name: "Detenida",
          icon: "pause-circle",
        },
      ],
      siacData: {
        nombres: "",
        paterno: "",
        materno: "",
        carreras: [],
      },
      selectedInternalNote: null,
      allInternalNotes: [],
      internalNotes: [],
      errores_validacion: {},
      document_templates: [],
      optionsForms: [],
      isMobile: false,
      isTablet: false,
      isCorrected: false,
      inputNote: "",
      src: [],
      answerTo: null,
      isPaginated: true,
      internalObservationId: null,
      canCreateInternalObservation: false,
      applicantRequestTypeCount: null,
      isModalPaymentInfoOpen: false,
      progressIcon: "paper-plane",
      progressColor: "is-primary",
      modalGenerateDocument: false,

      isModalObservationDocumentationIntern: false,
      isModalSignatureFile: false,

      currentTabSelected: 0,

      requestsFromUser: [],

      openModalAllRequests: false,
      openModalDownloadDocuments: false,

      isRejectingRequest: false,

      filesToSend: [],
      expandedDescription: false,
    };
  },
  async created() {
    await this.getRequest();
    document.title = `${this.request.code} | Revisión de solicitud`;
    await this.getRevisionHistory();
    await this.getInternalNotes();
    this.updateRequestFiles();
    this.getProgress();
  },
  methods: {
    dateToStringWithHour,
    setName,
    async filterNotes() {
      this.isLoading = true;
      if (this.selectedInternalNote) {
        return await axios
          .get(
            `/internal_observations/related-observations/${this.selectedInternalNote.id}.json`
          )
          .then((response) => {
            this.internalNotes = response.data;
          })
          .catch((e) => {})
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        return this.getInternalNotes();
      }
    },
    setNoteResponse(id) {
      this.answerTo = this.internalNotes.find((note) => note.id === id);
    },
    reload() {
      window.location.reload();
    },
    async checkValidation() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          message: "Faltan algunos campos o presentan errores de formato. ",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    confirmSendNote() {
      this.$buefy.dialog.confirm({
        title: "Confirmación",
        message: `¿Desea enviar esta observación? Luego de confirmar esta acción no podrá ser modificada y será visible para todas las personas que revisen esta solicitud`,
        cancelText: "Cancelar",
        confirmText: "Guardar",
        type: "is-success",
        onConfirm: () => this.sendNote(),
      });
    },
    async sendNote() {
      if (this.internalFiles) {
        for (let doc of this.internalFiles) {
          let errors = verifyFile(doc);
          if (errors) {
            this.$buefy.dialog.alert({
              title: "Error en archivos",
              message: `Error en: ${doc.name}: ${errors}`,
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
            return;
          }
        }
      }
      let formData = new FormData();
      formData.append("internal_observation[observation]", this.inputNote);
      formData.append("internal_observation[request_id]", this.request.id);

      if (this.answerTo) {
        formData.append("internal_observation[parent_id]", this.answerTo.id);
      }
      if (this.internalFiles) {
        this.internalFiles.forEach((file) => {
          formData.append("internal_observation[internal_files][]", file);
        });
      }

      this.isLoading = true;
      return axios
        .post("/internal_observations.json", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.internalNotes.unshift(response.data);
          this.inputNote = "";
          this.internalFiles = null;
          this.answerTo = null;
          openBuefyConfirmDialog(
            this,
            "Éxito",
            "Se ha agregado la observación correctamente",
            "Aceptar"
          );
          this.updateRequestFiles();
        })
        .catch((e) => {
          this.errores_validacion = e.response.data;
          openBuefyAlertDialog(
            this,
            "Error",
            this.errores_validacion,
            "Aceptar"
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateRequestFiles() {
      this.requestFiles = [];
      if (this.states[0].documents.length > 0) {
        this.requestFiles = this.states[0].documents.flatMap((document) => {
          if (!document.name.includes("[RESPUESTA FINAL]")) {
            return {
              ...document,
              created_at: moment(
                document["created_at"],
                "YYYY-MM-DD HH:mm:ss"
              ).format("DD-MM-YYYY HH:mm"),
              role: "Solicitante",
              observation: "Documento subido por Solicitante.",
            };
          }
          return [];
        });
      }
      let allStatesDocuments = this.states.slice(1).flatMap((state) =>
        state.documents.map((document) => {
          if (document.name.includes("[RESPUESTA FINAL]")) {
            return {
              ...document,
              author: state.reviewer.full_name,
              role: state.reviewer.role,
              observation:
                "Documento de Respuesta Final subido por " +
                state.reviewer.full_name,
              created_at: moment(
                document["created_at"],
                "YYYY-MM-DD HH:mm:ss"
              ).format("DD-MM-YYYY HH:mm"),
            };
          }
          return [];
        })
      );

      this.requestFiles = this.requestFiles.concat(allStatesDocuments);
      let aux = this.allInternalNotes.flatMap((element) =>
        element.documents.map((document) => ({
          ...document,
          author: element.reviewer.full_name,
          created_at: moment(
            document["created_at"],
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD-MM-YYYY HH:mm"),
          id_observation: element.id,
          role: element.role,
          observation: element.observation,
        }))
      );
      this.requestFiles = this.requestFiles.concat(aux);
      this.requestFiles.sort((a, b) => {
        const dateA = moment(a.created_at, "DD-MM-YYYY HH:mm");
        const dateB = moment(b.created_at, "DD-MM-YYYY HH:mm");
        return dateB - dateA;
      });
    },

    async getInternalNotes() {
      this.isLoading = true;
      const params = {
        "internal_observation[request_id]": this.request.id,
      };
      return await axios
        .get("/internal_observations.json", { params })
        .then((response) => {
          this.allInternalNotes = response.data.internal_observations;
          this.internalNotes = response.data.internal_observations;
          this.canCreateInternalObservation =
            response.data.can_create_internal_observation;
        })
        .catch((e) => {})
        .finally(() => {
          this.updateRequestFiles();
          this.isLoading = false;
        });
    },
    getSteps(request_state_id) {
      let count = 1;

      for (let i = 0; i < this.states.length; i++) {
        if (this.states[i].id != request_state_id) {
          count = count + 1;
        } else {
          i = this.states.length;
        }
      }

      return count + "/" + this.states.length;
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    deleteDropInternalFile(index) {
      this.internalFiles.splice(index, 1);
    },
    canDeny(state) {
      let state_progress = state.progress;
      if (
        this.progress_of_request == "Desistida" ||
        this.progress_of_request == "Rechazada" ||
        this.progress_of_request == "Aceptada" ||
        this.progress_of_request == "No Corresponde" ||
        this.progress_of_request == "No Procesada" ||
        state_progress.name == "Sin Iniciar" ||
        state_progress.name == "Aprobado" ||
        !state.can_review_state
      ) {
        return false;
      }
      return true;
    },

    closeModalAnswerObservation() {
      this.isModalAnswerObservationReviewer = false;
      this.getRequest();
    },
    async getRequest() {
      this.isLoading = true;
      await axios
        .get("/requests/" + this.requestId + ".json")
        .then((response) => {
          this.request = response.data.request_data;
          if (this.request["start_date"] !== null) {
            this.request.start_date = this.formatDate(this.request.start_date);
          }
          //this.progresses.forEach(progress => {
          //    this.states[progress.name] = response.data.states.filter(s => s.progress.name === state.progress.name)
          this.states = response.data.states;
          this.progress_of_request = response.data.progress_of_request;
          this.request_type = response.data.request_type;
          this.applicantData = response.data.applicant;
          this.siacData = response.data.siac_information;
          this.document_templates = response.data.document_templates;
          this.formCareerName = response.data.forms_career_name;
          this.applicantRequestTypeCount =
            response.data.applicant_request_type_count;
          this.setActiveStep();
          this.getProgress();
        })
        .catch((e) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getRevisionHistory() {
      this.isLoading = true;
      return await axios
        .get("/requests/" + this.requestId + "/revision_history.json")
        .then((response) => {
          let history_reviews = response.data.history_reviews;
          history_reviews.forEach((history_review) => {
            if (history_review.is_correction) {
              this.isCorrected = true;
            }
          });
        })
        .catch((e) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getStepColor(progress) {
      const progressColors = {
        "En Curso": "is-secondary",
        Detenido: "is-danger",
        Desistida: "is-danger",
        Rechazada: "is-danger",
        Aprobado: "is-success",
        Aceptada: "is-success",
        "Sin Iniciar": "is-gray",
        "No Corresponde": "is-danger",
        "No Procesada": "is-danger",
      };
      return progressColors[progress];
    },
    getStepIcon(progress) {
      const progressIcons = {
        "En Curso": "map-marker-alt",
        Detenido: "stop",
        Aprobado: "check",
        "Sin Iniciar": "bookmark",
        Desistida: "user-times",
        Rechazada: "times",
        Aceptada: "check-double",
        "No Corresponde": "hand-paper",
        "No Procesada": "ban",
      };
      return progressIcons[progress];
    },

    //--------------------------------------------------
    formatDate(date) {
      date = date.substring(0, 10);
      if (date != null && date !== "" && date !== undefined) {
        var arreglo = date.split("-");
        return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0];
      } else {
        return "";
      }
    },

    //----------------------------------------------------------
    openModalObservation(id) {
      this.currentStateObservation = id;
      this.isStopped = this.progress_of_request == "Detenida";
      this.isModalAnswerObservationReviewer = true;
    },
    openModalViewAllDocs(internalObservationId) {
      this.isModalViewAllDocsActive = true;
      this.internalObservationId = internalObservationId;
      this.selectedInternalNote = this.allInternalNotes.find(
        (note) => note.id === internalObservationId
      );
    },

    openModalShowFormResponse(form_response) {
      this.currentFormId = form_response.google_form_id;
      this.currentFormResponseId = form_response.form_response_id;
      this.isModalShowFormResponseActive = true;
    },

    openModalEditFormResponse(form_response) {
      this.currentFormId = form_response.google_form_id;
      this.currentFormResponseId = form_response.form_response_id;
      this.isModalEditFormResponseActive = true;
    },

    async openModalShowDocument(document) {
      this.src = document.id;
      this.isModalShowDocumentActive = true;
    },

    openModalAddFormResponse(requestStateId, form) {
      this.currentRequestStateId = requestStateId;
      this.currentFormId = form.google_form_id;
      this.isModalAddFormResponseActive = true;
    },

    openModalUploadDocuments(requestStateId, documentId) {
      this.currentRequestStateId = requestStateId;
      this.currentDocumentToUpload = documentId;
      this.isModalUploadDocumentsActive = true;
    },

    openDialogStopState(requestStateId, realName) {
      this.$buefy.dialog.confirm({
        title: "¿Detener paso?",
        type: "is-danger",
        message:
          "¿Desea detener el paso <b>" +
          realName +
          " (" +
          this.getSteps(requestStateId) +
          ")" +
          "</b>? Esto provocará que la solicitud se encuentre detenida a la espera de correcciones o datos adicionales de la parte solicitante.</br>" +
          '<br/><b class=\'has-text-success\'>IMPORTANTE: El comentario realizado será enviado por correo a la parte solicitante <i class="fas fa-check fa-lg"></i><i class="fas fa-envelope fa-lg"></i></b>',
        cancelText: "Cancelar",
        hasIcon: true,
        onConfirm: () => {
          this.changeState(requestStateId, 4);
        },
      });
    },
    openDialogDenyState(requestStateId) {
      let has_file =
        this.filesToSend.length < 1
          ? "<br><br><b class='has-text-danger'>IMPORTANTE: No se ha adjuntado un archivo de respuesta a la solicitud. El comentario realizado será enviado por correo a la parte solicitante <i class='fas fa-envelope'></i>. </b>"
          : "<br><br><b class='has-text-danger'>IMPORTANTE:</b> Se han adjuntado <b>" +
            this.filesToSend.length +
            "</b> archivo(s) de respuesta a la solicitud. El comentario y los documentos adjuntos serán enviados por correo al solicitante.";

      this.$buefy.dialog.confirm({
        title: "¿Rechazar solicitud?",
        type: "is-danger",
        message:
          "¿Desea rechazar la solicitud? Esta acción marcará toda la solicitud como rechazada y no habrá manera de revertirlo." +
          has_file,

        cancelText: "Cancelar",
        hasIcon: true,
        onConfirm: () => {
          this.rejectRequest(requestStateId);
        },
      });
    },

    openDialogNotCorrespondState(requestStateId) {
      this.$buefy.dialog.confirm({
        title: "¿Marcar como No Corresponde?",
        type: "is-dont-correspond-request",
        message:
          "¡Precaución! Al aceptar se marcará la solicitud como no corresponde debido a que no aplica, " +
          "esta acción es permanente y no se podrán realizar más cambios en la solicitud." +
          '<br/><br/><b class=\'has-text-success\'>IMPORTANTE: El comentario realizado será enviado por correo a la parte solicitante <i class="fas fa-check fa-lg"></i><i class="fas fa-envelope fa-lg"></i></b>',
        hasIcon: true,
        icon: "info-circle",
        cancelText: "Cancelar",
        onConfirm: () => {
          this.changeState(requestStateId, 9);
        },
      });
    },
    openDialogNotProcessedState(requestStateId) {
      this.$buefy.dialog.confirm({
        title: "¿Marcar como No Procesada?",
        type: "is-unprocessed-request",
        message:
          "¡Precaución! Al aceptar se marcará la solicitud como no procesada, " +
          "esta acción es permanente y no se podrán realizar más cambios en la solicitud" +
          "<br/><br/><b class='has-text-unprocessed-request'>IMPORTANTE: El comentario realizado será enviado por correo a quien solicitó <i class='fas fa-envelope'></i>.</b>",
        hasIcon: true,
        icon: "info-circle",
        cancelText: "Cancelar",
        onConfirm: () => {
          this.changeState(requestStateId, 10);
        },
      });
    },

    openDialogResumeState(requestStateId, realName) {
      this.$buefy.dialog.confirm({
        title: "Reanudar paso",
        type: "is-info",
        message:
          "¿Desea reanudar el paso <b>" +
          realName +
          " (" +
          this.getSteps(requestStateId) +
          ")" +
          "</b>? Esto provocará que la solicitud se encuentre en curso nuevamente y pueda ser asignada.",
        hasIcon: true,
        icon: "info-circle",
        cancelText: "Cancelar",
        onConfirm: () => {
          this.changeState(requestStateId, 2);
        },
      });
    },

    openDialogRewindState(requestStateId, realName) {
      this.$buefy.dialog.confirm({
        title: "Retroceder paso",
        type: "is-danger",
        message:
          "¿Desea retroceder la solicitud hasta el paso <b>" +
          realName +
          "</b>? Esta acción no es reversible y requiere volver a revisar todos los pasos nuevamente.",
        hasIcon: true,
        cancelText: "Cancelar",
        onConfirm: () => {
          this.isLoading = true;
          let formData = new FormData();
          formData.append("request_state_id", requestStateId);
          axios
            .post("/requests/rewind_to_state.json", formData)
            .then((response) => {
              this.isLoading = false;
              window.location.reload();
            })
            .catch((error) => {
              this.errores_validacion = error.response.data;
              let error_string = "Ocurrieron los siguientes errores:<br>";
              for (let prop in this.errores_validacion) {
                error_string =
                  error_string + "• " + this.errores_validacion[prop] + "<br>";
              }

              let elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$buefy.dialog.alert({
                title: "Error",
                message: error_string,
                type: "is-danger",
                hasIcon: true,
                icon: "times-circle",
                iconPack: "fa",
                ariaRole: "alertdialog",
                ariaModal: true,
              });
              this.isLoading = false;
            });
        },
      });
    },
    togglePriority() {
      let title = "Aumentar prioridad";
      let message =
        "Esta acción marcará la solicitud como prioritaria y será puesta al inicio de la lista de solicitudes.";
      if (this.request.priority) {
        title = "Quitar alta prioridad";
        message =
          "Esta acción quitará el estado de alta prioridad y la solicitud volverá a su estado normal.";
      }
      this.$buefy.dialog.confirm({
        title: title,
        message: message,
        cancelText: "Cancelar",
        confirmText: "Aceptar",
        type: "is-success",
        onConfirm: () => {
          this.isLoading = true;
          axios
            .post("/requests/toggle-priority/" + this.request.id)
            .then(() => {
              this.$buefy.dialog.confirm({
                title: "Listo",
                message: "Se ha cambiado la prioridad correctamente.",
                type: "is-success",
                hasIcon: true,
                icon: "check-circle",
                iconPack: "fa",
                ariaRole: "alertdialog",
                ariaModal: true,
                canCancel: false,
              });
              this.request.priority = !this.request.priority;
            })
            .catch(() => {
              this.$buefy.dialog.alert({
                title: "Error",
                message: "No se pudo cambiar la prioridad de la solicitud",
                type: "is-danger",
                hasIcon: true,
                icon: "times-circle",
                iconPack: "fa",
                ariaRole: "alertdialog",
                ariaModal: true,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        },
      });
    },
    rejectRequest(requestStateId) {
      let vm = this;
      let formData = new FormData();
      this.filesToSend.forEach((file) => {
        formData.append("files[]", file.file);
      });
      vm.isLoading = true;
      axios
        .patch("/request_states/" + requestStateId + "/reject.json", formData)
        .then((response) => {
          vm.isLoading = false;
          vm.$buefy.dialog.confirm({
            title: "Listo",
            message: "La solicitud ha sido rechazada correctamente.",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              window.location.reload();
            },
            onCancel: () => {
              window.location.reload();
            },
          });
        })
        .catch((e) => {
          vm.$buefy.dialog.alert({
            title: "Error",
            message: "No se pudo rechazar la solicitud",
            type: "is-danger",
            hasIcon: true,
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },

    changeState(requestStateId, progressId) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("id", requestStateId);
      formData.append("progress_id", progressId);
      axios
        .post("/request_states/change_state.json", formData)
        .then((response) => {
          this.isLoading = false;
          window.location.reload();
        })
        .catch((error) => {
          this.errores_validacion = error.response.data;
          let error_string = "Ocurrieron los siguientes errores:<br>";
          for (let prop in this.errores_validacion) {
            error_string =
              error_string + "• " + this.errores_validacion[prop] + "<br>";
          }

          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: "Error",
            message: error_string,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.isLoading = false;
        });
    },
    openDialogScheduleState(requestStateId) {
      let has_file =
        this.filesToSend.length === 0
          ? "<br><br><b class='has-text-danger'>IMPORTANTE: No se ha adjuntado un archivo de respuesta a la solicitud. El comentario realizado será enviado por correo a la parte solicitante <i class='fas fa-envelope'></i>. </b>"
          : "<br><br><b class='has-text-danger'>IMPORTANTE:</b> Se han adjuntado <b>" +
          this.filesToSend.length +
          "</b> archivo(s) de respuesta a la solicitud. El comentario y los documentos adjuntos serán enviados por correo al solicitante.";
      this.$buefy.dialog.confirm({
        title: "¿Programar aceptación de solicitud?",
        message:
          "Esta acción programará la aceptación de esta solicitud al siguiente día hábil a las 08:00 hrs. Esto provocará que el comentario y los documentos adjuntos serán enviados por correo a la parte solicitante. ¿Desea aceptar la solicitud? " +
          has_file,
        cancelText: "Cancelar",
        confirmText: "Aceptar",
        type: "is-info",
        hasIcon: true,
        icon: "clock",
        onConfirm: () => {
          this.approveState(requestStateId, true);
        },
      });
    },

    openDialogApproveState(requestStateId, realName, index_state) {
      let correctedMessage = this.isCorrected
        ? " Tenga en consideración que esta solicitud fue retrocedida."
        : "";
      let has_file =
        this.filesToSend.length === 0
          ? "<br><br><b class='has-text-danger'>IMPORTANTE: No se ha adjuntado un archivo de respuesta a la solicitud. El comentario realizado será enviado por correo a la parte solicitante <i class='fas fa-envelope'></i>. </b>"
          : "<br><br><b>IMPORTANTE:</b> Se han adjuntado <b>" +
            this.filesToSend.length +
            "</b> archivo(s) de respuesta a la solicitud. El comentario y los documentos adjuntos serán enviados por correo al solicitante.";
      if (index_state === this.states.length - 1) {
        this.$buefy.dialog.confirm({
          title: "¿Aceptar solicitud?",
          message:
            "¿Desea aceptar la solicitud? Esta acción marcará toda la solicitud como aprobada y no habrá manera de revertirlo." +
            correctedMessage +
            has_file,
          cancelText: "Cancelar",
          confirmText: "Aceptar",
          type: "is-success",
          hasIcon: true,
          onConfirm: () => {
            this.approveState(requestStateId);
          },
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Aprobar paso",
          message:
            "¿Desea aprobar el paso <b>" +
            realName +
            " (" +
            this.getSteps(requestStateId) +
            ")" +
            "</b>? Esto provocará que la solicitud avance al siguiente paso." +
            correctedMessage +
            "<br><br><b class='has-text-danger'>IMPORTANTE: No se enviará una notificación a la parte solicitante " +
            '<i class="fas fa-ban fa-lg"></i><i class="fas fa-envelope fa-lg"></i>',
          cancelText: "Cancelar",
          confirmText: "Aprobar",
          type: "is-success",
          onConfirm: () => {
            this.approveState(requestStateId);
          },
        });
      }
    },

    approveState(requestStateId, isScheduled = false) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("id", requestStateId);
      this.filesToSend.forEach((file) => {
        formData.append("files[]", file.file);
      });
      formData.append("is_scheduled", isScheduled);
      axios
        .post("/request_states/approve_state.json", formData)
        .then((response) => {
          this.isLoading = false;
          window.location.reload();
        })
        .catch((error) => {
          this.errores_validacion = error.response.data;
          let error_string = "Ocurrieron los siguientes errores:<br>";
          for (let prop in this.errores_validacion) {
            error_string =
              error_string + "• " + this.errores_validacion[prop] + "<br>";
          }
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: "Error",
            message: error_string,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.isLoading = false;
        });
    },

    //--------------------------------------------------
    addObservation(request_state_id, observation, index) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("id", request_state_id);
      formData.append("observation", observation);
      axios
        .post("/request_states/update_observation.json", formData)
        .then((response) => {
          this.$buefy.dialog.confirm({
            title: "Listo",
            message: "El comentario se ha guardado correctamente.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            canCancel: false,
          });
          //save observation in view
          this.states[index].observation = observation;
          this.isLoading = false;
          this.editObservation = false;
          this.inputObservation = "";
        })
        .catch((error) => {
          this.errores_validacion = error.response.data;
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: "Error",
            message:
              "Hubo errores al guardar la observación, por favor intente de nuevo.",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.isLoading = false;
          this.editObservation = false;
          this.inputObservation = "";
        });
    },

    deleteFileOfDocument(document, state_index) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("id", document.id);

      axios
        .post("/documents/delete_file_document.json", formData)
        .then((response) => {
          this.$buefy.dialog.confirm({
            title: "Listo",
            message: "El documento se ha eliminado exitosamente.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            canCancel: false,
            onConfirm: () => {
              console.log("Documento eliminado exitosamente.");
            },
          });
          let index_to_update =
            this.states[state_index].documents.indexOf(document);
          this.states[state_index].documents[index_to_update] = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: "Error",
            message: "No se pudo eliminar el documento.",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.isLoading = false;
        });
    },
    autoAssignReviewer(requestStateId, requestId) {
      this.$buefy.dialog.confirm({
        title: "Asignar solicitud",
        message:
          "Si continua, esta solicitud le será asignada para su revisión en el paso actual y solo usted podrá revisarla. ¿Desea continuar?",
        cancelText: "Cancelar",
        onConfirm: () => {
          this.reviewState(requestStateId, requestId);
        },
      });
    },
    reassignReviewer(state_id) {
      this.currentRequestStateId = state_id;
      this.isModalReassignReviewer = true;
    },
    paymentInfoMessage() {
      return (
        `Estimado(a) ${
          this.siacData.nombres +
          " " +
          this.siacData.paterno +
          " " +
          this.siacData.materno
        }:\n` +
        "Se le informa su solicitud ha sido detenida debido a que se necesita realizar un depósito con los siguientes datos para continuar\n\n" +
        "Monto a depositar: $[MONTO] \n" +
        "Nombre de la Institución: Universidad de Santiago de Chile\n" +
        "RUT: 60.911.000-7\n" +
        "Banco: Banco Santander\n" +
        "N° de Cuenta Corriente: 0-000-1903580-8\n" +
        "Correo electrónico: veronica.flores@usach.cl\n" +
        "Motivo: Su nombre y RUT.\n\n" +
        "¡IMPORTANTE! El monto transferido debe ser exacto al precio indicado, no puede ser más, ni menos.\n" +
        "Recuerda que debes enviar el comprobante de pago a través del sistema SAS como respuesta a la detención de tu " +
        "solicitud, dónde debes asegurarte de que se vean todos los datos respectivos emitidos por el banco; además, " +
        "si el depósito lo realiza una tercera persona, debes informar su nombre y RUT en la respuesta de la detención. \n" +
        "En caso depositar un monto equivocado, usted deberá volver a " +
        "realizar la transferencia por el monto completo."
      );
    },
    reviewState(request_state_id, request_id) {
      this.isLoading = true;
      axios
        .post("/requests/review_state.json", {
          request_state_id: request_state_id,
        })
        .then((response) => {
          window.location.href = "/requests/" + request_id;
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: "Error",
            message:
              "Otra persona ya fue asignada para la revisión de este paso. Por favor recargue la página.",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            onConfirm: () => {
              window.location.href = "/requests";
            },
          });
        });
    },
    downloadFile(document_id, isFinalResponse, documentNumber) {
      this.isLoading = true;

      axios
        .request({
          url: "/documents/download_file/" + document_id,
          responseType: "blob",
        })
        .then((response) => {
          //var blobUrl = URL.createObjectURL(response.data);
          //window.open(blobUrl)

          let info = response.headers["content-disposition"];
          let name;
          if (isFinalResponse) {
            name = `${this.request.code}_${
              this.applicantData.run
            }_Respuesta-Final-${documentNumber + 1}.pdf`;
          } else {
            name = `${this.request.code}_${
              this.applicantData.run
            }_Adjuntado-Solicitud-${documentNumber + 1}.${decodeURIComponent(
              info.split('"')[1]
            )
              .split(".")
              .at(-1)}`;
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Se descargo con exito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Fallo la descarga.",
            type: "is-danger",
          });
        });
    },
    getTotalFileSize() {
      let totalSize = 0;
      for (let file of this.dropFiles) {
        totalSize += file.size;
      }

      return Math.round((totalSize * 100) / 1000000) / 100;
    },
    setActiveStep() {
      const startedStates = this.states.filter(
        (state) => state.progress.name !== "Sin Iniciar"
      );
      if (startedStates.length > 0) {
        this.activeStep = this.states.findIndex(
          (state) =>
            state.id === startedStates.sort((a, b) => a.id - b.id).at(-1).id
        );
      } else {
        this.activeStep = this.states.length - 1;
      }
    },
    isFinalResponseDocument(documents) {
      if (documents.length === 0) return false;
      return documents.some((document) =>
        document.name.includes("[RESPUESTA FINAL]")
      );
    },
    getProgress() {
      if (this.states.length === 0) {
        this.progressColor = "is-grey";
        this.progressIcon = "exclamation";
        this.progressDescription = "La solicitud aún no ha sido iniciada";
        return "Sin iniciar";
      }
      let info = getStateInfo(this.progress_of_request);
      this.progressColor = info.color;
      this.progressIcon = info.icon;
    },
    openModalIntern() {
      this.isModalObservationDocumentationIntern = true;
    },
    openModalSignatureFile() {
      this.isModalSignatureFile = true;
    },

    selectFromModal(files) {
      this.dropFiles = files;
    },
    appendDocument(file) {
      this.dropFiles.push(file);
    },
    cancelSchedule() {
      this.$buefy.dialog.confirm({
        title: "Cancelar programación",
        message:
          "¿Desea cancelar la programación de esta solicitud?<br> <b>Ten en cuenta que los archivos de respuesta final " +
          "serán eliminados y deberás volver a adjuntarlos al aceptar la solicitud nuevamente. Recuerde editar las " +
          "observaciones en caso de ser necesario.</b>",
        cancelText: "Cancelar",
        confirmText: "Aceptar",
        hasIcon: true,
        type: "is-danger",
        onConfirm: () => {
          this.isLoading = true;
          axios
            .patch(
              `/request_states/${
                this.states[this.activeStep].id
              }/cancel-schedule.json`
            )
            .then(() => {
              this.$buefy.dialog.alert({
                title: "Listo",
                message: "Se ha cancelado la programación de la solicitud",
                type: "is-success",
                hasIcon: true,
                onConfirm: () => {
                  window.location.reload();
                },
                onCancel: () => {
                  window.location.reload();
                },
              });
            })
            .catch(() => {
              this.$buefy.dialog.alert({
                title: "Error",
                message: "No se pudo cancelar la programación de la solicitud",
                type: "is-danger",
                hasIcon: true,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        },
      });
    },
    openModalCreateDocument() {
      this.modalGenerateDocument = true;
    },
    getDocumentIndex(documents, index) {
      let filteredIndex = 0;
      let isFinished = false;
      documents.forEach((doc, i) => {
        if (i === index) {
          isFinished = true;
        }
        if (doc.name.includes("[RESPUESTA FINAL]") && !isFinished) {
          filteredIndex += 1;
        }
      });
      return filteredIndex;
    },
    updateDropFiles(files) {
      files.forEach((file) => {
        this.filesToSend.push({
          file: file,
          author: "-",
          role: "",
          origin: "Subido manualmente",
        });
      });
      this.dropFiles = [];
    },
    addFileToFinalResponse(file) {
      this.requestFiles = this.requestFiles.filter(
        (requestFile) => requestFile.id !== file.id
      );
      this.filesToSend.push({
        file: file.file,
        author: file.author,
        role: file.role,
        origin: file.origin,
        baseData: file.baseData,
      });
    },
    handleDeleteFinalResponse(file) {
      if (file.baseData) {
        this.requestFiles.push(file.baseData);
      }
      this.filesToSend = this.filesToSend.filter(
        (requestFile) =>
          requestFile.file.size !== file.file.size &&
          requestFile.file.name !== file.file.name
      );
    },
    replaceNewlines(text) {
      if (!text) return "";
      return text.replace(/\n/g, "<div class='my-2'></div>");
    },
  },

  computed: {
    filterSelectedCareer() {
      if (this.siacData.carreras.length === 0) return "";
      let selectedCareers = null;
      selectedCareers = this.siacData.carreras.filter(
        (carrera) => carrera.selected
      );
      return selectedCareers ? selectedCareers[0] : null;
    },
  },

  mounted() {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    const mediaQueryTablet = window.matchMedia("(min-width: 1409px)");

    // Set initial value on first load.
    this.isMobile = !mediaQuery.matches;
    this.isTablet = !mediaQueryTablet.matches;

    mediaQueryTablet.addEventListener("change", (event) => {
      if (event.matches) {
        this.isTablet = false;
      } else {
        this.isTablet = true;
      }
    });

    // Listen for changes
    mediaQuery.addEventListener("change", (event) => {
      if (event.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
  },
};
</script>
<style scoped>
.tab-header {
  display: flex; /* Alinea los elementos horizontalmente */
  align-items: center; /* Asegura que los elementos estén centrados verticalmente */
}

.notification-circle {
  background-color: goldenrod;
  color: white;
  border-radius: 50%;
  width: 25px; /* Ajusta según tus necesidades */
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  margin-left: 5px; /* Espacio entre el texto y el círculo */
}
.circle-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem; /* Ajusta el tamaño del círculo según necesites */
  height: 2.5rem; /* Ajusta el tamaño del círculo según necesites */
  border-radius: 50%; /* Hace que el contenedor sea circular */
  background-color: #0e9014; /* Color de fondo del círculo */
}
.custom-size-icon-behind {
  width: 0.9em;
  height: 1em;
  margin-top: 0.1em;
}
.custom-size-icon-front {
}
.fa-sm {
  font-size: 0.775em;
}
</style>
