<template>
  <div>
    <b-loading
      v-model="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></b-loading>
    <div class="container box my-5">
      <div class="title">Programación de Tipos de Solicitud</div>

      <b-message type="is-info" has-icon>
        Este módulo permite programar las fechas de inicio y término en las que
        un tipo de solicitud estará disponible dentro del sistema SAS. Aquí
        puedes definir las fechas de inicio y término, asegurando que no se
        acepten nuevas solicitudes fuera de estos intervalos.
      </b-message>

      <b-table
        :data="requestTypes"
        paginated
      >
        <b-table-column
          field="origin"
          label="Módulo de Solicitudes"
          sortable
          searchable
          v-if="origins.length > 1"
        >
          <template v-slot:searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row.origin }}
          </template>
        </b-table-column>
        <b-table-column
          field="name"
          label="Tipo de Solicitud"
          sortable
          searchable
        >
          <template v-slot:searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>
        <b-table-column
          field="enabled_start_date"
          label="Fecha de Inicio"
          :custom-search="customSortStartDate"
          sortable
          searchable
          width="16em"
        >
          <template v-slot:searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            <b-datepicker
              v-model="props.row.enabled_start_date"
              icon="calendar-alt"
              placeholder="dd/mm/aaaa"
              position="is-bottom-right"
              :date-formatter="(date) => date.toLocaleDateString('es-ES')"
              @input="() => (props.row.isDateChanged = true)"
              locale="es"
            />
          </template>
        </b-table-column>
        <b-table-column
          field="enabled_end_date"
          label="Fecha de Término"
          sortable
          searchable
          width="16em"
          :custom-search="customSortEndDate"
        >
          <template v-slot:searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            <b-datepicker
              v-model="props.row.enabled_end_date"
              icon="calendar-alt"
              placeholder="dd/mm/aaaa"
              position="is-bottom-right"
              :date-formatter="(date) => date.toLocaleDateString('es-ES')"
              @input="() => (props.row.isDateChanged = true)"
              locale="es"
            />
          </template>
        </b-table-column>
        <b-table-column>
          <template v-slot="props">
            <b-button
              icon-left="calendar"
              type="is-secondary"
              :disabled="!props.row.isDateChanged"
              @click="updateEnabledDate(props.row)"
            >
              Actualizar Fecha
            </b-button>
          </template>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">
            No se encontraron tipos de solicitudes
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  props: [],
  data() {
    return {
      isLoading: false,
      user: null,
      users: [],
      requestTypes: [],
      requestType: null,
      origins: [],
      origin: null,
    };
  },
  methods: {
    fetchInitialData() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/request_types/date-interval.json")
        .then((response) => {
          vm.requestTypes = response.data.request_types;
          vm.origins = response.data.origins;
          vm.requestTypes = vm.requestTypes.map((requestType) => ({
            ...requestType,
            enabled_start_date:
              requestType.enabled_start_date === null
                ? null
                : moment(requestType.enabled_start_date).toDate(),
            enabled_end_date:
              requestType.enabled_end_date === null
                ? null
                : moment(requestType.enabled_end_date).toDate(),
            isDateChanged: false,
          }));
        })
        .catch((error) => {
          console.log(error);
          vm.$buefy.dialog.alert({
            title: "Error",
            message:
              "Ocurrió un error al cargar los datos. Por favor, intente nuevamente.",
            type: "is-danger",
            hasIcon: true,
          });
        })
        .then(() => {
          vm.isLoading = false;
        });
    },
    updateEnabledDate(requestType) {
      let vm = this;
      if (
        requestType.enabled_start_date &&
        requestType.enabled_end_date &&
        requestType.enabled_start_date > requestType.enabled_end_date
      ) {
        vm.$buefy.dialog.alert({
          title: "Error",
          message: "La fecha de inicio debe ser antes que la fecha de término",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }

      vm.isLoading = true;
      axios
        .patch(`/request_types/date-interval/${requestType.id}.json`, {
          start_date: requestType.enabled_start_date,
          end_date: requestType.enabled_end_date,
        })
        .then(() => {
          vm.$buefy.dialog.alert({
            title: "¡Éxito!",
            message: `La solicitud <b>"${requestType.name}"</b> ha sido actualizada correctamente`,
            type: "is-success",
            hasIcon: true,
          });
          requestType.isDateChanged = false;
        })
        .catch((e) => {
          console.log(e);
          vm.$buefy.dialog.alert({
            title: "Error",
            message:
              "Ocurrió un error al actualizar las fechas de este tipo de solicitud. Por favor reintente nuevamente.",
            type: "is-danger",
            hasIcon: true,
          });
        })
        .finally(() => (vm.isLoading = false));
    },
    compareDate(a, b) {
      if (a == null && b == null) {
        return 0;
      }
      if (a == null) {
        return -1;
      }
      if (b == null) {
        return 1;
      }
      const dateA = new Date(a.split("-").reverse().join("-"));
      const dateB = new Date(b.split("-").reverse().join("-"));
      return dateA - dateB;
    },

    customSortStartDate(a, b, isAsc) {
      return isAsc
        ? this.compareDate(a.enabled_start_date, b.enabled_start_date)
        : this.compareDate(b.enabled_start_date, a.enabled_start_date);
    },
    customSortEndDate(a, b, isAsc) {
      return isAsc
        ? this.compareDate(a.enabled_end_date, b.enabled_end_date)
        : this.compareDate(b.enabled_end_date, a.enabled_end_date);
    },
  },
  created() {
    document.title = "Programación de Tipos de Solicitud"
    this.fetchInitialData();
  },
};
</script>
