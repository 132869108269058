<template>
  <div>
    <b-loading
      v-model="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></b-loading>
    <!--
      <div class="container box my-5" > 
        <div class="title">Servicio de Firma Digital Simple <br>
            Un Documento
        </div>
        <div>
          <div class="columns mb-3">
            <div class="column has-text-centered">
              <b-field extended label="Archivo PDF*">
                <b-upload v-model="file" accept=".pdf" drag-drop @input="onAddFile">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="file-pdf" size="is-large"></b-icon>
                    </p>
                    <p>Arrastra tu PDF o haz click para subir.</p>
                  </div>
                </b-upload>
              </b-field>
              <div class="columns tags is-centered">
                <span v-if="file !== null" class="tag is-primary mt-1">
                  {{ file.name }}
                  <button
                    class="is-small"
                    type="button"
                  ></button>
                </span>
              </div>
            </div>
            
            <div v-if="url == null"  class="column has-text-centered">
              <b-field extended label="Firma (formato PNG)*">
                <b-upload
                  ref="fileUpload"
                  v-model="signImage"
                  drag-drop
                  accept=".png"
                  @input="onAddSign"
                >
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="file-signature" size="is-large"></b-icon>
                    </p>
                    <p>Arrastra tu firma o haz click para subir.</p>
                  </div>
                </b-upload>
              </b-field>
              <div class="columns tags is-centered">
                <span v-if="signImage !== null" class="tag is-primary mt-1">
                  {{ signImage.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteImage"
                  ></button>
                </span>
              </div>
            </div>
            <div v-else class="column has-text-centered">
              
              <div class="has-text-weight-bold mb-2">
                Mi Firma Digital Simple (vista previa)
              </div>
              <div v-if="url != null" class="signature-container">
                <img
                  :src="url"
                  class="signature-preview"
                />
              </div>
              <div v-else class="signature-container-signature-off">
                Sin Firma Digital Simple
              </div>


            </div>
            
          </div>
          <div v-if="showMySignature==true" class="columns  mb-3">
            <div  class=" is-offset-6 column has-text-centered is-align-items-center is-justify-content-center" style="display: flex;">
              

              <b-button
                v-if="url != urlMySignature"
                type="is-primary"
                icon-left="signature"
                @click="useMySignature()"
              >
                Usar Mi Firma Digital Simple
              </b-button>
              <b-button
                v-else-if="url == null"
                type="is-primary"
                icon-left="signature"
                @click="useMySignature()"
              >
                Usar Mi Firma Digital Simple
              </b-button>
              <b-button
                v-else
                type="is-primary"
                icon-left="eraser"
                @click="changeSignature()"
              >
                Utilizar otro archivo de firma
              </b-button>

            </div>
            
          </div>
        </div>
      </div>

    -->
      <div class="container box my-5" ref="newService">
        <div class="title">Servicio de Firma Digital Simple <br>
            Un Documento
        </div>
        <br>

        <b-message
          title="¡Instrucciones de uso!"
          type="is-info"
          has-icon
          class="is-info is-light"
          icon="exclamation-circle"
      >
        <ol>
          <li>
            <b>Sube tu firma digital</b>: Haga click en el botón de carga y seleccione su archivo de firma digital desde su dispositivo. 
          </li>
          <li>
            <b>Selecciona donde firmar</b>: Presione la zona exacta donde desee insertar su firma en el documento PDF. 
          </li>
          <li>
            <b>Ingrese su código de Google Authenticator</b>: Ingrese el código dado por Google Authenticator para firmar y emitir el documento. 
          </li>
        </ol>
      </b-message>
      <br>
        <div class="columns">
          <div class="column">
            <div class="title">PDF</div>
            <div class="columns">
              <div class="column is-half has-text-right">
                <b-button
                  icon-left="arrow-left"
                  type="is-primary"
                  @click="backPage"
                  >Retroceder página</b-button
                >
              </div>
              <div class="column is-half has-text-left">
                <b-button
                  icon-right="arrow-right"
                  type="is-primary"
                  @click="nextPage"
                  >Siguiente página</b-button
                >
              </div>
            </div>
            <div class="columns has-text-centered">
              <div class="column">Página: {{ currentPage }}/{{ pageCount }}</div>
            </div>
            <div v-if="sign !== null" class="pdf-container" @click="onClickVuePDF">
              <pdf
                :page="currentPage"
                :src="src.at(-1)"
                @num-pages="pageCount = $event"
              />
            </div>
            <div v-else class="pdf-container">
              <pdf
                :page="currentPage"
                :src="src.at(-1)"
                @num-pages="pageCount = $event"
              />
            </div>
            <div class="columns has-text-centered mt-4">
              <div class="column">Página: {{ currentPage }}/{{ pageCount }}</div>
            </div>

            <div class="columns">
              <div class="column is-half has-text-right">
                <b-button
                  icon-left="arrow-left"
                  type="is-primary"
                  @click="backPage"
                  >Retroceder página</b-button
                >
              </div>
              <div class="column is-half has-text-left">
                <b-button
                  icon-right="arrow-right"
                  type="is-primary"
                  @click="nextPage"
                  >Siguiente página</b-button
                >
              </div>
            </div>
          </div>


          <div v-if="url == null"  class="column has-text-centered">
            <div class="title">Firma</div>
            <br><br><br><br>
            <b-field extended label="Firma (formato PNG)*">
              <b-upload
                ref="fileUpload"
                v-model="signImage"
                drag-drop
                accept=".png"
                @input="onAddSign"
              >
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="file-signature" size="is-large"></b-icon>
                  </p>
                  <p>Arrastra tu firma o haz click para subir.</p>
                </div>
              </b-upload>
            </b-field>
            <div v-if="showMySignature==true" class="columns  mb-3">
              <br><br>
              <div  class="column has-text-centered is-align-items-center is-justify-content-center" style="display: flex;">
                

                <b-button
                  v-if="url != urlMySignature"
                  type="is-primary"
                  icon-left="signature"
                  @click="useMySignature()"
                >
                  Usar Mi Firma Digital Simple
                </b-button>
                <b-button
                  v-else-if="url == null"
                  type="is-primary"
                  icon-left="signature"
                  @click="useMySignature()"
                >
                  Usar Mi Firma Digital Simple
                </b-button>
                <b-button
                  v-else
                  type="is-primary"
                  icon-left="eraser"
                  @click="changeSignature()"
                >
                  Utilizar otro archivo de firma
                </b-button>

              </div>
            </div>
          </div>
          <div v-else class="column ">
            <div class="title">Firma</div>
            <br><br><br><br>
            <div class="has-text-weight-bold mb-2">
              Mi Firma Digital Simple (vista previa)
            </div>            
            <div class="columns">
              <div class="column is-12">
                <div class="content has-text-centered signature-container">
                  <img :src="url" class="signature-preview" />
                </div>
              </div>
            </div>
            <div class="column is-12 is-vcentered">
              <b-field :label="'Tamaño de firma (' + targetHeight + 'px)'">
                <b-slider
                  v-model="targetHeight"
                  :max="500"
                  :min="50"
                  :step="20"
                  :ticks="true"
                  @change="onChangeImageSize"
                ></b-slider>
              </b-field>
            </div>     

            <div v-if="showMySignature==true" class="columns  mb-3">
              <br><br>
              <div  class="column has-text-centered is-align-items-center is-justify-content-center" style="display: flex;">
                

                <b-button
                  v-if="url != urlMySignature"
                  type="is-primary"
                  icon-left="signature"
                  @click="useMySignature()"
                >
                  Usar Mi Firma Digital Simple
                </b-button>
                <b-button
                  v-else-if="url == null"
                  type="is-primary"
                  icon-left="signature"
                  @click="useMySignature()"
                >
                  Usar Mi Firma Digital Simple
                </b-button>
                <b-button
                  v-else
                  type="is-primary"
                  icon-left="eraser"
                  @click="changeSignature()"
                >
                  Utilizar otro archivo de firma
                </b-button>

              </div>
            </div>
            <br>
            <div>
              <b-button expanded icon-left="undo" type="is-danger" @click="undoSign"
                >Deshacer última firma</b-button
              >
  
            </div>

            
            
          </div>

        </div>

        <br>
        <hr>
        <div class="columns">
          <div class="column">
            <b-checkbox v-model="consentGiven">
                He leído y acepto los <span class="link-style" @click.prevent="openModalTerms()"> términos y condiciones de uso</span>.
            </b-checkbox>
          </div>
        </div>

        <div class="columns">
          <div class="column has-text-centered is-align-items-center is-justify-content-center" style="display: flex;">
            <b-field grouped class="" >
              <template #label>
                <b-tooltip
                    label="Abra su aplicación 'Google Authenticathor' para ver su código de autenticación."
                    position="is-right"
                    size="is-medium"
                    multilined>

                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      size="is-small"
                      type="is-info">
                  </b-icon>
                </b-tooltip>
                Código de autenticación de Google Authenticator*
              </template>
              <b-input placeholder="XXXXXX" v-model="autenticatorQr" maxlength="6"></b-input>
              <p class="control">
                <b-tooltip label="Configure su App 'Google Authenticator' para generar códigos escaneando el siguiente código QR">
                  <b-button type="is-primary" icon-pack="fas" icon-left="qrcode" v-if="showQr"
                            @click.prevent="openQr" >
                    Ver QR
                  </b-button>
                </b-tooltip>
                <b-button v-if="sendDocument == false " type="is-primary is-light is-border-darkgoldenrod" icon-pack="fas" icon-left="save" :disabled="!consentGiven "
                          @click.prevent="validate" >
                  Firmar y Emitir Documento
                </b-button>

                <b-button v-else
                  type="is-primary is-light is-border-darkgoldenrod"
                  icon-left="save"
                  disabled
                > Firmar y Emitir Documento

                </b-button>

              </p>
            </b-field>
          </div>
        </div>
    

      </div>


      <b-modal  has-modal-card
              v-model="showmodalQR"
              trap-focus
              :destroy-on-hide="true"
              aria-role="dialog"
              aria-modal
              :can-cancel="true"
              width="100%"
    >
      <template #default="props">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="title">Código QR</p>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column is-8 is-offset-2">
                <b-message
                    title="Aplicación de autenticación"
                    type="is-warning"
                    aria-close-label="Close message"
                    has-icon
                    :closable="false"
                >
                  <p>
                    - Utilice la app de autenticación 'Google Authenticator' para escanear el siguiente código QR.<br>
                    - Los códigos generados por esta aplicación serán solicitados <b> cada vez </b> que requiera firmar documentación.<br>
                    - El código QR que se muestra a continuación solo se mostrara una vez.<br>
                    - Una vez escaneado, cerrar esta ventana e ingresar el código generado en la aplicación.
                  </p>
                </b-message>
              </div>
            </div>
            <br>
            <div class="columns has-text-centered is-centered">
              <div class="column is-centered">

                <b-image
                    :src="qrLink"
                    style="max-width: 300px; justify-content: center; margin: auto;"
                ></b-image>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot is-flex is-justify-content-space-between">
            <b-button @click="closeModal">Cerrar</b-button>
          </footer>
        </div>
      </template>
    </b-modal>

    <b-modal  has-modal-card 
              v-model="openModalTerminos"
              trap-focus
              :destroy-on-hide="true"
              aria-role="dialog"
              aria-modal
              :can-cancel="true"
              width="80%"
    >
      <template #default="props">
         <modal-term-and-condition @close="props.close">
          </modal-term-and-condition>
      </template>
    </b-modal>


  </div>
</template>

<script>
import axios from "axios";
import {PDFDocument} from "pdf-lib";
import pdf from "vue-pdf";
import ModalTermAndCondition from "../Parameterization/ModalTermAndCondition.vue"

export default {
  name: "SimpleDigitalSignatureViewService",
  components: {
    pdf, ModalTermAndCondition
  },
  props: ['token','qrLink', 'showQr', 'googleFormsResponse', 'email', 'urlReturn'],
  data() {
    return {
      file: null,
      currentPage: 1,
      pageCount: null,
      fileBinary: null,
      src: [],
      isLoading: false,
      signImage: null,
      sign: null,
      targetHeight: 200,
      url: null,
      aspectRatio: null,
      fileSelected: null,
      continueSing: false,
      msg: "",
      dowloadDocument: false,
      showmodalQR: false,
      disabledButton: true,
      autenticatorQr: "",
      showMySignature: false,
      signImageMySignature: null,
      signMySignature: null,
      urlMySignature: null,
      validationErrors: {},
      consentGiven: false,
      openModalTerminos: false,
      sendDocument: false,
    };
  },
  computed: {},
  created() {
    document.title = "Servicio Firma Digital Simple";
    window.addEventListener("keyup", this.handleKeyup, null);
    this.fetchFileSignature();
    this.fetchDocument();
  },
  errorCaptured(){
    return false;
  },
  methods: {
    openQr(){
      this.showmodalQR = true;
    },
    closeModal(){
      this.showmodalQR = false;
    },
    async signFile(xPercent, yPercent) {
      if (this.sign === null) {
        this.$buefy.dialog.alert({
          message: "No se ha cargado una firma",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      if (this.file === null) {
        this.$buefy.dialog.alert({
          message: "No se ha cargado un archivo PDF",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      try {
        const pdfDoc = await PDFDocument.load(this.src.at(-1));
        const signImage = await pdfDoc.embedPng(this.sign);

        const pages = pdfDoc.getPages();
        const page = pages[this.currentPage - 1];

        const signDims = signImage.scale(1);

        const aspectRatio = signDims.height / signDims.width;
        page.drawImage(signImage, {
          x: xPercent * page.getWidth() - this.targetHeight / 2,
          y:
            yPercent * page.getHeight() - (this.targetHeight * aspectRatio) / 2,
          width: this.targetHeight,
          height: Math.ceil(this.targetHeight * aspectRatio),
        });

        return await pdfDoc.saveAsBase64({
          dataUri: true,
        });
      } catch (error) {
        this.$buefy.dialog.alert({
          message: "Este archivo no se puede firmar",
          type: "is-danger",
          hasIcon: true,
        });
        this.onDeleteFile();
      }
      return;
    },    
    async onAddFile() {
      if (this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
        this.file = null;
        this.$buefy.dialog.alert({
          message: "Este archivo no tiene formato PDF",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      this.isLoading = true;
      const buffer = await this.file.arrayBuffer();
      this.fileBinary = buffer;
      this.src = [new Uint8Array(buffer)];
      this.isLoading = false;
    },
    async onClickVuePDF(e) {
      this.isLoading = true;
      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
      let xPosPercent = x / rect.width;
      let yPosPercent = 1 - y / rect.height;
      this.src.push(await this.signFile(xPosPercent, yPosPercent));
      this.isLoading = false;
    },
    undoSign() {
      if (this.src.length === 1)
        return this.$buefy.dialog.alert({
          message: "Ya se deshicieron todas las firmas.",
          type: "is-primary",
          hasIcon: true,
        });
      this.src.pop();
    },
    backPage() {
      if (this.pageCount === null) return;
      if (this.currentPage - 1 < 1) return;
      this.currentPage -= 1;
    },
    nextPage() {
      if (this.pageCount === null) return;
      if (this.currentPage + 1 > this.pageCount) return;
      this.currentPage += 1;
    },
    handleKeyup(e) {
      if (e.repeat) {
        return;
      }
      switch (e.keyCode) {
        case 37:
          this.backPage();
          break;
        case 39:
          this.nextPage();
          break;
      }
    },
    async onAddSign() {
      if (this.signImage.name.split(".").at(-1).toLowerCase() !== "png") {
        this.signImage = null;
        this.$buefy.dialog.alert({
          message: "Este archivo no tiene formato PNG",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      this.isLoading = true;

      const buffer = await this.signImage.arrayBuffer();
      this.sign = new Uint8Array(buffer);
      this.url = URL.createObjectURL(this.signImage);
      let img = new Image();
      let vm = this;
      img.onload = function () {
        vm.aspectRatio = this.height / this.width;
      };
      img.src = this.url;
      this.isLoading = false;
    },    
    onChangeImageSize() {
      var img = new Image();
      let vm = this;
      img.onload = function () {
        vm.aspectRatio = this.height / this.width;
      };
      img.src = this.url;
    },

    cancelAction(){
      this.openModal = false;
    },
    changeSignature(){
      this.signImage = null;
      this.sign = null;
      this.url = null;
    },
    useMySignature(){
      this.signImage = this.signImageMySignature;
      this.sign = this.signMySignature;
      this.url = this.urlMySignature;
    },
    async fetchFileSignature() {
      let vm = this;
      vm.isLoading = true;
      axios
          .request({
            url: "/signature-services/get-signature-feet",
            params: {
                        email: vm.email,
                        token: vm.token,
                    },

            responseType: "blob",
          })
          .then(async(response) => {
            if (response.status === 200){
              const blob = new Blob([response.data]);
              const filename = 'firma_usuario.png';
              vm.signImage = new File([blob], filename, { type: blob.type });

              const buffer = await blob.arrayBuffer();
              vm.sign = new Uint8Array(buffer);

              vm.url = window.URL.createObjectURL(blob);
              let img = new Image();
              img.onload = function () {
                vm.aspectRatio = vm.height / vm.width;
              };
              img.src = vm.url;
              vm.signImageMySignature = vm.signImage;
              vm.signMySignature = vm.sign;
              vm.urlMySignature = vm.url;
              vm.showMySignature = true;
            }

          })
          .catch((e) => {
            this.$buefy.dialog.alert({
              message: "Fallo la obtención de Firma Digital.",
              type: "is-danger",
            });
          })
          .finally(() => {
            });

    },

    openModalTerms(){
      this.openModalTerminos = true;
    },
    async createRequest() {
      if (this.src.length === 1)
        return this.$buefy.dialog.alert({
          message: "No se han realizado modificaciones",
          type: "is-warning",
          hasIcon: true,
        });
      this.isLoading = true;
      let vm = this;
      const base64String = vm.src.at(-1);
      const byteCharacters = atob(base64String.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const file = new File([blob], 'documento.pdf', { type: 'application/pdf' });

      let formData = new FormData();
      formData.append("token", vm.token);
      formData.append("files[]", file);
      formData.append("forms", JSON.stringify(vm.googleFormsResponse));
      axios
        .post("/signature-services/create-requests.json", formData)
        .then((response) => {
          this.text =
            "El documento fue firmado exitosamente." 

          this.$buefy.dialog.confirm({
            title: "Listo",
            message: this.text,
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            canCancel: false,
            onConfirm: () => this.closeWindow()
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.validationErrors = error.response.data;
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.newService.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: "Error",
            message: this.validationErrors.error,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.isLoading = false;
        })
        .finally(() => {
              this.isLoading = false;
            });
    },

    closeWindow(){
      this.sendDocument = true;
      window.location.href = this.urlReturn
    },


    async fetchDocument() {
      let vm = this;
      vm.isLoading = true;
      axios
          .request({
            url: "/signature-services/download-pdf-file/" + this.token,
            responseType: "blob",
          })
          .then(async(response) => {
            vm.file = new File([new Blob([response.data])], "documento_a_firmar");
            vm.fileBinary = await vm.file.arrayBuffer();         
            vm.src = [new Uint8Array(vm.fileBinary)];

          })
          .catch((e) => {
            this.$buefy.dialog.alert({
              message: "Fallo la obtención de Documento.",
              type: "is-danger",
            });
          })
          .finally(() => {
              this.isLoading = false;
            });

    },

    async validate(){
      let vm = this;
      if (vm.autenticatorQr.length < 6){
        this.$buefy.dialog.alert({
          message: 'Código de verificación no valido',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return false
      }
      vm.isLoading = true;
      await axios
          .post("/signature-services/verify-code-autenticator.json",
              {
                "code": vm.autenticatorQr,
                "email": vm.email,
                "only_validate": true, 
              })
          .then(async (res) => {
            if(res.data == true){
              await this.createRequest();
            }
            else{
              vm.isLoading = false;
              this.$buefy.dialog.alert({
                message: 'Código de verificación no válido o expirado.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            vm.errors_validations = null;
          })
          .catch((error) => {
            vm.isLoading = false;
            this.$buefy.dialog.alert({
              message: 'Hubo un error en su solicitud',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          });

    },


  },
};
</script>
<style scoped>
.pdf-container {
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 16px;
  overflow-y: auto;
}



.signature-container-signature-off{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 16px;
    width: 100%;
    height: 80%;
  }
  .signature-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-height: 300px;
    background-color: #f5f5f5;
    padding: 16px;
    width: 100%;
  }

.signature-preview {
  max-height: 300px;
  max-width: 100%;
  object-fit: contain;
}

.dialog .modal-card {
  min-width: 60em !important;
}

.link-style {
  font-weight: bold;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

</style>