<template>
  <div>
    <section class="pt-6">
      <div class="columns is-centered">
        <div class="column is-three-quarters has-background-white">
          <div class="columns is-vcentered ">
            <div class="column is-flex is-justify-content-flex-start">
              <h1 class="subtitle is-1 has-text-secondary">
                Mi Firma Digital Simple
              </h1>
            </div>
          </div>
          <hr class="solid">
        </div>
      </div>
      
      <div class="columns is-centered is-multiline ">
        <div class="column is centered is-three-quarters has-background-white">
          <!--
          <b-tabs type="is-toggle" expanded>
          
            <b-tab-item label="Mis Pies de Firma" icon="signature">
              aaaaaa
            </b-tab-item>
            <b-tab-item label="Documentos Asociados" icon="copy">
              bbbbbbbbb
            </b-tab-item>
            <b-tab-item label="Solicitudes Asociadas" icon="file-invoice">
              cccccccccc
            </b-tab-item>
            
            <b-tab-item label="Firma Digital Simple" icon="file-image">
              <h2 class="has-text-centered new-h2">Firma Digital Simple</h2>
                <br>
              !-->  
                <simple-digital-signature
                         :show-qr="showQr"
                         :link-qr="linkQr"                         
                         :signature-file-url="signatureFileUrl"
                         :accepted-terms="acceptedTerms"
                         @updateSignatureUrl = "updateSignatureUrl"
                >
                </simple-digital-signature>
                <!--
            </b-tab-item>
          </b-tabs>
          !-->
        </div>
      </div>
        
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>


<script>
import axios from "axios";
import SimpleDigitalSignature from "./SimpleDigitalSignature.vue"

export default {
  name: "UserSignatureFeet",
  components:{ SimpleDigitalSignature },
  data() {
    return {
      isLoading: false,
      showQr: true,
      linkQr: "...",
      signatureFile: null,
      signatureFileUrl: null,
      acceptedTerms: false,

    };
  },
  async created() {
    document.title = "Mis Pies de Firma";
    await this.fetchFileSignature();
    await this.fetchDataParameterizedUser();

  },
  methods: {
    fetchDataParameterizedUser() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/users/signature-feet.json")
        .then((response) => {
          vm.showQr = response.data.show_qr; 
          vm.linkQr = response.data.qr_link;
          vm.acceptedTerms = response.data.accepted_terms;
        })
        .catch((error) => {
          vm.$buefy.alert.open({
            title: "Error",
            hasIcon: true,
            message: "Error al cargar estudiantes habilitados",
            type: "is-danger",
          });
        })
        .then(() => {
          vm.isLoading = false;
        });
    },

    fetchFileSignature() {
      let vm = this;
      this.isLoading = true;
      axios
        .request({
          url: "/users/get-signature-feet",
          responseType: "blob",
        })
        .then((response) => {
          if (response.status === 200){
            vm.signatureFileUrl = window.URL.createObjectURL(new Blob([response.data]));
          }
          this.isLoading = false;
          
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Fallo la obtención de Firma Digital.",
            type: "is-danger",
          });
        });

    },
    updateSignatureUrl(newUrl){
      this.signatureFileUrl = newUrl;
    }

    
  },
};
</script>
<style scoped>
  .new-h2 {
    font-size: 24px;
    font-weight: bold;
  }


</style>