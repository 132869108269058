<template>
    <div>
        <img src="../../../../public/gsd/home_student_images/form/formalurios_solicitudes.png" class="responsive">
        <div class="columns is-centered">
            <div class="column is-9">
                <div class="container is-fullheight is-family-secondary">



                    <div class="container" style="height: 100%" ref="createRequestType">

                        <!-- Titulo de los formularios -->
                        <div class="is-flex is-justify-content-flex-start has-text-weight-bold">
                            <h1 class="subtitle is-3 has-text-secondary">
                                Solicitudes
                            </h1>
                        </div>

                        <!-- Separador -->
                        <hr class="solid">
                        <!-- subtitulo Titulo -->
                        <div class="mb-3 has-text-secondary">
                            <h6 class="is-size-6">A continuación se exponen las solicitudes para realizar tu trámite
                                estudiantil.
                                Si tienes dudas de cómo hacer una Solicitud de Docencia, te invitamos a revisar el video
                                explicativo expuesto anteriormente.
                            </h6>
                        </div>

                        <!-- Request Types Section -->

                        <div v-if="requestTypes.length === 0" class="columns is-multiline">
                            <div v-for="i in 8" :key="i"
                                 class="column is-one-quarter-desktop is-one-third-tablet is-half-mobile">
                                <article class="media">
                                    <div class="media-content">
                                        <div class="content p-2">
                                            <p>
                                                <b-skeleton height="150px"></b-skeleton>
                                                <b-skeleton active></b-skeleton>
                                            </p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                      <div v-if="!moduleEnabled" class="columns is-centered">
                        <b-message class="column is-vcentered" type="is-danger"  icon-size="is-medium" has-icon>
                          Actualmente este módulo no se encuentra recibiendo solicitudes.
                        </b-message>
                      </div>
                        <div v-else class="columns is-multiline">

                            <div v-for="(request_type, request_type_index) in requestTypes" :key="request_type_index"
                                 class="column is-one-quarter-desktop is-one-third-tablet is-full-mobile">
                                <div class="card has-background-warning p-2" style="min-height:100%">
                                    <a :href="'/requests/new/' + request_type.id" target="_blank">
                                        <b-tooltip :label="request_type.description" type="is-secondary is-light"
                                                   multilined position="is-bottom">
                                            <div class="card-content has-text-centered">
                                                <img class="mb-2" src="/document-student-icon.png" style="width:80%"
                                                     :alt="request_type.real_name">
                                                <h1 class="title is-6 has-text-secondary has-text-weight-bold">
                                                    {{ request_type.real_name }}
                                                </h1>
                                            </div>
                                        </b-tooltip>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
                    </div>

                </div>

            </div>
        </div>
        <div style="background-color: #003B71" align="center">
            <img src="../../../../public/logo_sin_texto.png"/>
            <div>
                <a href="https://www.fing.usach.cl/" target="_blank">
                    <h1 class="is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold">
                        WEB OFICIAL FING USACH | Entra aquí
                    </h1>
                </a>
                <br/>
            </div>

        </div>
        <div style="background-color: #FAC633" align="center">
            <br/>
            <h1 class="is-size-6 has-text-black has-text-centered is-family-secondary ">
                Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins nº 3363. Estación Central.
                Santiago. Chile.
            </h1>
            <a href="https://www.usach.cl/contacto" target="_blank">
                <h1 class="is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold">
                    Oficina de Informaciones, Reclamos y Sugerencias (OIRS)
                </h1>
            </a>
            <br/>

        </div>
    </div>
</template>

<script>
import axios from "axios"
import Youtube from "../Tools/Youtube";

export default {
    name: "ChooseRequestTypeSoa",
    components: {Youtube},
    data: () => {
        return {
            requestTypes: [],
          moduleEnabled:false,
            isLoading: false,
        }
    },
    created() {
        document.title="SOA | Iniciar una solicitud"
        this.isLoading = true;

        axios.get("/soa/request_types.json").then(
            response => {
                this.requestTypes = response.data.request_types;
              this.moduleEnabled = response.data.module_enabled;
              this.isLoading = false;
            }
        ).catch(
            error => {
                console.log(error);
                this.isLoading = false;
            }
        );
    },
    methods: {}
}
</script>
