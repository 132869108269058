<template>
  <div class="my-5">
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <b-table :data="requestTypes" paginated>
      <b-table-column
        field="name"
        label="Tipo de Solicitud"
        sortable
        searchable
      >
        <template v-slot:searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.real_name }}
        </template>
      </b-table-column>
      <b-table-column
        field="enabled_start_date"
        label="Fecha de Inicio"
        sortable
        searchable
        width="16em"
        :custom-search="customSortStartDate"
      >
        <template v-slot:searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.enabled_start_date }}
        </template>
      </b-table-column>
      <b-table-column
        field="enabled_end_date"
        label="Fecha de Término"
        sortable
        searchable
        width="16em"
        :custom-search="customSortEndDate"
      >
        <template v-slot:searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.enabled_end_date }}
        </template>
      </b-table-column>
      <b-table-column label="Estado" field="enabled" sortable>
        <template v-slot:searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <b-tag v-if="props.row.enabled" class="is-success is-size-6">
            <b-icon icon="check-circle" class="mr-1" />En Curso
          </b-tag>
          <b-tag v-else class="is-danger is-size-6">
            <b-icon icon="times-circle" class="mr-1" />Cerrado
          </b-tag>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["origin"],
  data() {
    return {
      requestTypes: [],
      isLoading: false,
    };
  },
  methods: {
    fetchRequestTypesCalendar() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get(`/origins/${vm.origin}/calendar.json`)
        .then((response) => {
          vm.requestTypes = response.data.request_types;
        })
        .catch((e) => console.log(e))
        .finally(() => (vm.isLoading = false));
    },
    compareDate(a, b) {
      if (a == null && b == null) {
        return 0;
      }
      if (a == null) {
        return -1;
      }
      if (b == null) {
        return 1;
      }
      const dateA = new Date(a.split("-").reverse().join("-"));
      const dateB = new Date(b.split("-").reverse().join("-"));
      return dateA - dateB;
    },

    customSortStartDate(a, b, isAsc) {
      return isAsc
        ? this.compareDate(a.enabled_start_date, b.enabled_start_date)
        : this.compareDate(b.enabled_start_date, a.enabled_start_date);
    },
    customSortEndDate(a, b, isAsc) {
      return isAsc
        ? this.compareDate(a.enabled_end_date, b.enabled_end_date)
        : this.compareDate(b.enabled_end_date, a.enabled_end_date);
    },
  },
  created() {
    this.fetchRequestTypesCalendar();
  },
};
</script>
