<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Documentos</p>

        <button
            type="button"
            class="delete"
            @click="$emit('close')"
            style="background: #ff3860"
        />

      </header>
      <section class="modal-card-body">
        <DocumentPrevisulizer v-if="!isLoading" :src="src"/>

        <footer class="modal-card-foot columns ">
          <div class="column has-text-left my-3">
            <button
                class="button is-pulled-left"
                type="button"
                @click="closeModal()"
            >
              Cerrar
            </button>
          </div>
        </footer>
      </section>

      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="false"
      ></b-loading>
    </div>
  </section>
</template>
<script>
import pdf from "vue-pdf";
import axios from "axios";
import DocumentPrevisulizer from "./DocumentPrevisulizer.vue";
export default {
  components: {
    DocumentPrevisulizer,
    pdf,
  },
  props: ["docId", "isAppeal"],
  data() {
    return {
      currentPage: 1,
      isLoading:false,
      src:"",
      pageCount: null,
      downloaded:false
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    backPage() {
      if (this.pageCount === null) return;
      if (this.currentPage - 1 < 1) return;
      this.currentPage -= 1;
    },
    nextPage() {
      if (this.pageCount === null) return;
      if (this.currentPage + 1 > this.pageCount) return;
      this.currentPage += 1;
    },
    handleKeyup(e) {
      if (e.repeat) {
        return;
      }
      switch (e.keyCode) {
        case 37:
          this.backPage();
          break;
        case 39:
          this.nextPage();
          break;
      }
    },
  },
 async created() {

   this.isLoading = true;
    await axios
        .request({
          url: "/documents/download_pdf_file/" + this.docId,
          responseType: "blob",
          params: { is_appeal: this.isAppeal ? true : false},
        })
        .then(async (response) => {
          
          const file = new File([new Blob([response.data])], document.name);
          const buffer = await file.arrayBuffer();

          this.src = new Uint8Array(buffer);
          this.downloaded=true;

        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Fallo la previsualización.",
            type: "is-danger",
          });
        }).finally(() => {

          this.isLoading = false;
        });
    window.addEventListener("keyup", this.handleKeyup, null);
  },
};
</script>
