<template>
  <!-- header -->
  <div style="background-color: white">
    <img
      src="../../../../public/gsd/home_student_images/headers/contactos.png"
      class="responsive"
    />
    <!-- body -->
    <div class="columns is-centered mt-3">
      <div class="column is-9">
        <b-collapse
          class="card my-1"
          animation="slide"
          v-for="(collapse, index) of collapses"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
          :aria-id="'contentIdForA11y5-' + index"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              :aria-controls="'contentIdForA11y5-' + index"
              :aria-expanded="props.open"
            >
              <p
                class="card-header-title is-flex is-justify-content-left subtitle is-3-desktop is-5-mobile has-text-secondary has-text-weight-bold is-family-secondary mb-0 my-2"
              >
                {{ collapse.titulo }}
              </p>

              <a class="card-header-icon">
                <b-icon
                  type="is-secondary"
                  size="is-medium"
                  :icon="props.open ? 'minus-circle' : 'plus-circle'"
                >
                </b-icon>
              </a>
            </div>
          </template>

          <div
            class="card-content"
            v-for="(imagen, index) of collapse.imagenes"
            :key="index"
          >
            <img v-bind:src="imagen" class="responsive" />
          </div>
          <div class="card-content box" style="height: 85em" v-for="(page, index) of collapse.pages" :key="index">
            <iframe
              :src="page"
              width="100%"
              height="100%"
              allowfullscreen
            />
          </div>
        </b-collapse>
        <b-collapse
          class="card"
          animation="slide"
          :open="false"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
            >
              <p
                class="card-header-title is-flex is-justify-content-left subtitle is-3-desktop is-5-mobile has-text-secondary has-text-weight-bold is-family-secondary mb-0 my-2"
              >
                INSTAGRAM USACH
              </p>

              <a class="card-header-icon">
                <b-icon
                  type="is-secondary"
                  size="is-medium"
                  :icon="props.open ? 'minus-circle' : 'plus-circle'"
                >
                </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content columns">
            <div class="column is-5">
              <img src="../../../../public/gsd/home_student_images/contact_us/ig-logo.png" style="width: 30em" />
            </div>
            <div class="column is-1"></div>
            <div class="column">
              <p class="has-text-secondary">
                <b>Universidad de Santiago de Chile</b> <br>
                <u><a href="https://www.instagram.com/usachoficial/" target="_blank">@usachoficial <br> <br></a></u>

                <b>Facultad de Ingeniería Usach</b> <br>
                <u><a href="https://www.instagram.com/fingusach/" target="_blank">@fingusach</a> <br> <br></u>

                <b>Vicerrectoría Académica (VRA)</b> <br>
                <u><a href="https://www.instagram.com/vrausach/" target="_blank">@vrausach</a> <br> <br></u>

                <b>Vicerrectoría de Vinculación con el Medio</b> <br>
                <u><a href="https://www.instagram.com/vimeusach/" target="_blank">@vimeusach</a> <br> <br></u>

                <b>Vicerrectoría de Apoyo al Estudiante</b> <br>
                <u><a href="https://www.instagram.com/vraeusach/" target="_blank">@vraeusach</a> <br> <br></u>

                <b>Departamento de Beneficios Estudiantiles</b> <br>
                <u><a href="https://www.instagram.com/dbeusach/" target="_blank">@dbeusach</a> <br> <br></u>

                <b>Departamento de Relaciones Internacionales</b> <br>
                <u><a href="https://www.instagram.com/usach_internacional/" target="_blank">@usach_internacional</a> <br> <br></u>

                <b>Estudiantes pregrado Ingeniería Usach</b> <br>
                <u><a href="https://www.instagram.com/cachorrosfing/" target="_blank">@cachorrosfing</a> <br> <br></u>

                <b>Paiep Usach</b> <br>
                <u><a href="https://www.instagram.com/paiep_usantiago/" target="_blank">@paiep_usantiago</a> <br> <br></u>

                <b>Mujeres en Ingeniería Usach</b> <br>
                <u><a href="https://www.instagram.com/atomicas_usach/" target="_blank">@atomicas_usach</a> <br> <br></u>

                <b>Vocalía de Género y Sexualidades Usach</b> <br>
                <u><a href="https://www.instagram.com/vogesexusach/" target="_blank">@vogesexusach</a> <br> <br></u>

                <b>Dirección de Género Usach</b> <br>
                <u><a href="https://www.instagram.com/direcciongenerousach/" target="_blank">@direcciongenerousach</a> <br> <br></u>

                <b>Departamento de Gestión del Deporta</b> <br>
                <u><a href="https://www.instagram.com/deporteyculturausach/" target="_blank">@deporteyculturausach</a> <br> <br></u>

                <b>Departamento de las Culturas y las Artes</b> <br>
                <u><a href="https://www.instagram.com/culturayarteusach" target="_blank">@culturayarteusach</a> <br> <br></u>

                <b>Centro de Innovación Usach</b> <br>
                <u><a href="https://www.instagram.com/centrousach/" target="_blank">@centrousach</a> <br> <br></u>

                <b>Centro de salud</b> <br>
                <u><a href="https://www.instagram.com/csaludusach/" target="_blank">@csaludusach</a></u>
              </p>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- Footer -->

    <div style="background-color: #003b71" align="center">
      <img src="../../../../public/logo_sin_texto.png" />
      <div>
        <a href="https://www.fing.usach.cl/" target="_blank">
          <h1
            class="is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold"
          >
            WEB OFICIAL FING USACH | Entra aquí
          </h1>
        </a>
        <br />
      </div>
    </div>
    <div class="has-background-msd" align="center">
      <br />
      <h1
        class="is-size-6 has-text-black has-text-centered is-family-secondary"
      >
        Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins
        nº 3363. Estación Central. Santiago. Chile.
      </h1>
      <a href="https://www.usach.cl/contacto" target="_blank">
        <h1
          class="is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold"
        >
          Oficina de Informaciones, Reclamos y Sugerencias (OIRS)
        </h1>
      </a>
      <br />
    </div>
  </div>
</template>

<script>
import Accordion from "../Tools/accordion";
import AccordionItem from "../Tools/accordion-item";

export default {
  name: "GsdContactUs",
  components: {},
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          titulo: "CONTACTOS GENERALES",
          imagenes: [
            require("../../../../public/gsd/home_student_images/contact_us/contactos_generales.png"),
          ],
        },
        {
          titulo: "CONTACTOS POR DEPARTAMENTOS ACADÉMICOS",
          imagenes: [
            require("../../../../public/gsd/home_student_images/contact_us/Contactos_departamentos.png"),
          ],
        },
        {
          titulo: "CONTACTOS ASISTENTES SOCIALES",
          pages: [
            "https://vrae.usach.cl/asistentes-sociales/#ingenieria",
          ]
        },
      ],
    };
  },
  created() {
    document.title = "MSD | Contáctanos";
  },
};
</script>

<style>
.responsive {
  width: 100%;
  height: auto;
}

/* Solid border */
</style>
