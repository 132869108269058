<template>
  <div class="columns is-centered">
    <div class="column is-12">
      <b-field label="Filtrar por nombre de solicitud">
        <b-autocomplete
          v-model="search"
          :data="filteredRequestTypes"
          field="real_name"
          icon="search"
          dropdown-position="top"
          expanded
          open-on-focus
          @input="() => $emit('search', filteredRequestTypes)"
          @focus="() => $emit('expand')"
        >
          <template #empty> No se encontraron resultados </template>
        </b-autocomplete>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  props: ["requestTypes"],
  data() {
    return {
      search: "",
    };
  },
  computed: {
    filteredRequestTypes() {
      if (this.requestTypes.length === 0) return [];
      return this.requestTypes.filter((requestType) =>
        requestType.name.includes(
          this.search
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        )
      );
    },
  },
  created() {
    this.$emit("search", this.filteredRequestTypes);
  },
};
</script>