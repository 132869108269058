<template>
  <div>
    <div class="title is-3 my-5">Precios de Planes y Programas</div>
    <b-message class="mt-3" type="is-info" icon="info-circle" has-icon>
      Las solicitudes de planes y programas deben ser pagadas desde la primera
      solicitud, su precio dependerá de qué tipo de programa se solicite y
      cuantas asignaturas sean solicitadas. Ante cualquier duda contactarse con
      <b>registrocurricular.fing@usach.cl</b>.
    </b-message>
    <b-table class="is-border-light-grey" :data="paymentProgramInfo">
      <b-table-column label="Tipo de Solicitud" v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column label="Precio" v-slot="props" width="8em">
        <div class="has-text-right">{{ props.row.price }} (CLP)</div>
      </b-table-column>
      <b-table-column label="Pago desde" v-slot="props">
        {{
          props.row.paid_immediately
            ? "La primera solicitud en el año"
            : "La tercera solicitud en el año"
        }}
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      paymentProgramInfo: [
        {
          name: "Programa Completo Carreras de 12 o más semestres (Civil)",
          price: "136.000",
          paid_immediately: true,
        },
        {
          name: "Programa Completo Carreras de 9 a 11 semestres (Ing. Biotecnología e Ing. Ambiental)",
          price: "107.000",
          paid_immediately: true,
        },
        {
          name: "Programa Completo Carreras de 8 semestres o equivalentes (Ejecución)",
          price: "85.000",
          paid_immediately: true,
        },
        {
          name: "Programa por Asignatura de Pregrado (Si la suma de los cobros de las asignaturas excede el monto de Civil o Ejecución se debe pagar como programa completo).",
          price: "22.000",
          paid_immediately: true,
        },
        {
          name: "Programa de Postítulo",
          price: "142.000",
          paid_immediately: true,
        },
        {
          name: "Programa de Postgrado",
          price: "236.000",
          paid_immediately: true,
        },
        {
          name: "Programa por Asignatura de Postgrado",
          price: "33.000",
          paid_immediately: true,
        },
        {
          name: "Plan de Estudios",
          price: "33.000",
          paid_immediately: true,
        },
      ],
    };
  },
};
</script>
