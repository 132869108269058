<template>
    <div class="container">
        <section style="width: auto;">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(addItems)">
                    <!-- Box de Formulario Appointment -->
                    <div class="modal-card" style="width: auto;height: 500px">
                        <header class="modal-card-head has-background-secondary">
                            <p class="modal-card-title has-text-white">Añadir {{ modalHeader }}</p>
                            <button type="button" class="delete" @click="$emit('close')"/>
                        </header>
                        <section class="modal-card-body" ref="ModalAddInternalPositions">
                            <div class="columns" v-if="Object.keys(validationErrors).length !== 0">
                                <div class="column">
                                    <div class="mb-2">
                                        <b-message type="is-danger" has-icon>
                                            <div v-for="(item,index) in validationErrors" :key="index">
                                                {{ item[0] }}
                                            </div>
                                        </b-message>
                                    </div>
                                </div>
                            </div>

                            <div v-if="modalHeader === 'Revisores'" class="control">
                                <label class="label">Seleccione los roles revisores el paso *</label>
                                <ValidationProvider rules="required" v-slot="{errors}">
                                    <multiselect v-model="selectedInternalPositions" :options="optionsInternalPositions"
                                                 :custom-label="opt => optionsInternalPositions.find(x => x.id === opt.id).name"
                                                 :multiple="true"
                                                 placeholder="Seleccione uno o mas cargos"
                                                 selectLabel="''"
                                                 selectedLabel="Seleccionado"
                                                 deselectLabel="Presione para deseleccionar" track-by="id" label="name">
                                        <template v-slot:noOptions>
                                            No existen datos
                                        </template>
                                        <span slot="noResult">
                                        No se encontraron elementos.
                                    </span>
                                    </multiselect>
                                    <span class="validation-alert">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>


                            <div v-if="modalHeader === 'Firmantes'" class="control">
                                <div class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap mb-4">
                                    <div class="field is-flex mb-0 mt-2">
                                        <h1 class="title is-5">Definir los roles firmantes en este paso
                                            <b-tooltip
                                                    label="En este paso se debe añadir el tipo de documento a firmar, que rol lo firma y bajo que pie de firma. Estos documentos fueron parametrizados previamente."
                                                    multilined position="is-bottom">
                                                <i class="fas fa-info-circle">
                                                </i>
                                            </b-tooltip>
                                        </h1>
                                    </div>
                                    <div class="is-flex mt-2">
                                        <b-button class="is-info is-light is-border-cornflowerblue" icon-left="plus" @click="addSignature()">
                                            Añadir Firmante
                                        </b-button>
                                    </div>
                                </div>
                                <b-table :data="selectedSignatories"
                                         :bordered="true"
                                         :hoverable="true"
                                         :mobile-cards="true">

                                    <b-table-column field="name" label="Tipo de documento Parametrizado *" v-slot="props">
                                        <b-field>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <multiselect v-model="props.row.document_parameterized_id"
                                                               :options="documentParameterizedTypes.map(type => type.id)"
                                                               :custom-label="opt => documentParameterizedTypes.find(x => x.id == opt).name"
                                                               placeholder="Seleccione Documento Parametrizado"
                                                               selectLabel=""
                                                               selectedLabel=""
                                                               deselectLabel=""
                                                               @input="validation(props.row)"
                                                               :allow-empty="true">
                                                    <template v-slot:noOptions>
                                                        No existen datos
                                                    </template>
                                                    <span slot="noResult">
                                                        No se encontraron elementos.
                                                    </span>
                                                </multiselect>
                                                <span class="validation-alert">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-field>
                                    </b-table-column>

                                    <b-table-column field="name" label="Descripción del Documento *" v-slot="props">
                                        <b-field>
                                            <ValidationProvider rules="required|max:250" v-slot="{errors}">
                                                <b-input v-model="props.row.description"
                                                         type ="textarea"
                                                         aria-placeholder="Ingrese una descripción *"></b-input>
                                                <span class="validation-alert">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-field>
                                    </b-table-column>

                                    <b-table-column field="name" label="Rol firmante *" v-slot="props">
                                        <b-field>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <multiselect v-model="props.row.internal_position_id"
                                                               :options="props.row.roles.map(type => type.id)"
                                                               :custom-label="opt => props.row.roles.find(x => x.id == opt).internal_position"
                                                               placeholder="Seleccione Rol"
                                                               selectLabel=""
                                                               selectedLabel=""
                                                               deselectLabel=""
                                                               :disabled="props.row.roles.length==0"
                                                               @input="validationFirmante(props.row, false)"
                                                               :allow-empty="true">
                                                    <template v-slot:noOptions>
                                                        No existen datos
                                                    </template>
                                                    <span slot="noResult">
                                                        No se encontraron elementos.
                                                    </span>
                                                </multiselect>
                                                <span class="validation-alert">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-field>
                                    </b-table-column>

                                    <b-table-column field="name" label="Pie de firma *" v-slot="props">
                                        <b-field>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <multiselect v-model="props.row.parameterized_signature_id"
                                                               :options="props.row.role_signature.map(type => type.id)"
                                                               :custom-label="opt => props.row.role_signature.find(x => x.id == opt).signature"
                                                               placeholder="Seleccione Pie de firma"
                                                               selectLabel=""
                                                               selectedLabel=""
                                                               deselectLabel=""
                                                               :disabled="props.row.role_signature.length==0"                                                            
                                                               :allow-empty="true">
                                                    <template v-slot:noOptions>
                                                        No existen datos
                                                    </template>
                                                    <span slot="noResult">
                                                        No se encontraron elementos.
                                                    </span>
                                                </multiselect>
                                                <span class="validation-alert">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-field>
                                    </b-table-column>

                                    <b-table-column field="actions" label="Acciones" centered v-slot="props">
                                        <a class="has-text-red" @click="removeDocument(props.row)">
                                        <span class="icon has-text-danger my-1">
                                        <i class="fas fa-trash-alt"></i>
                                        </span>
                                        </a>
                                    </b-table-column>

                                    <template #empty>
                                        <div class="has-text-centered">Aún no hay documentos definidos</div>
                                    </template>

                                </b-table>
                            </div>

                            <div v-if="modalHeader === 'Documentos'" class="control">
                                <div class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap mb-4">
                                    <div class="field is-flex mb-0 mt-2">
                                        <h1 class="title is-5">Definir documentos requeridos en este paso
                                            <b-tooltip
                                                    label="En este paso se debe añadir el nombre de los documentos a requerir en este paso junto con una pequeña descripción"
                                                    multilined position="is-bottom">
                                                <i class="fas fa-info-circle">
                                                </i>
                                            </b-tooltip>
                                        </h1>
                                    </div>
                                    <div class="is-flex mt-2">
                                        <b-button class="is-info is-light is-border-cornflowerblue" icon-left="plus" @click="addDocument()">
                                            Añadir Documento
                                        </b-button>
                                    </div>
                                </div>
                                <b-table :data="selectedDocuments"
                                         :bordered="true"
                                         :hoverable="true"
                                         :mobile-cards="true">

                                    <b-table-column field="name" label="Nombre del Documento *" v-slot="props">
                                        <b-field>
                                            <ValidationProvider rules="required|max:250" v-slot="{errors}">
                                                <b-input v-model="props.row.name"
                                                         aria-placeholder="Ingrese un nombre *"></b-input>
                                                <span class="validation-alert">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-field>
                                    </b-table-column>

                                    <b-table-column field="name" label="Descripción del Documento *" v-slot="props">
                                        <b-field>
                                            <ValidationProvider rules="required|max:250" v-slot="{errors}">
                                                <b-input v-model="props.row.description"
                                                         type ="textarea"
                                                         aria-placeholder="Ingrese una descripción *"></b-input>
                                                <span class="validation-alert">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-field>
                                    </b-table-column>
                                    <!--
                                     <b-table-column field="multipleResponse" label="¿Permite múltiples respuestas?" centered v-slot="props">
                                         <b-checkbox class="my-2" v-model="props.row.multipleResponse">
                                         </b-checkbox>
                                     </b-table-column>

                                  <b-table-column field="multipleResponse" label="¿Es una carpeta de drive?" centered v-slot="props">
                                     <b-checkbox class="my-2" v-model="props.row.is_folder">
                                     </b-checkbox>
                                   </b-table-column>
                                   -->

                                    <b-table-column field="actions" label="Acciones" centered v-slot="props">
                                        <a class="has-text-red" @click="removeDocument(props.row)">
                                        <span class="icon has-text-danger my-1">
                                        <i class="fas fa-trash-alt"></i>
                                        </span>
                                        </a>
                                    </b-table-column>

                                    <template #empty>
                                        <div class="has-text-centered">Aún no hay documentos definidos</div>
                                    </template>

                                </b-table>
                            </div>

                            <div v-if="modalHeader === 'Formularios'" class="control">
                                <div class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap mb-4">
                                    <div class="field is-flex mb-0 mt-2">
                                        <h1 class="title is-5">Definir formularios requeridos en este paso
                                            <b-tooltip
                                                    label="En esta sección se deben asociar agregar los formularios que el usuario deba rellenar en este paso de la solcitud."
                                                    multilined position="is-bottom">
                                                <i class="fas fa-info-circle">
                                                </i>
                                            </b-tooltip>
                                        </h1>
                                    </div>
                                    <div class="is-flex mt-2">
                                      <b-button class="is-info is-light is-border-cornflowerblue" icon-left="plus" @click="addForm()">
                                        Añadir Formulario
                                      </b-button>
                                    </div>
                                </div>
                                <b-table :data="selectedForms" :bordered="true" :hoverable="true" :mobile-cards="true">

                                    <b-table-column field="name" label="Nombre del Formulario *" v-slot="props">
                                        <b-field>
                                            <ValidationProvider rules="required|max:250" v-slot="{errors}">
                                                <b-input v-model="props.row.name"></b-input>
                                                <span class="validation-alert">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-field>
                                    </b-table-column>

                                    <b-table-column field="name" label="Url del Formulario *" v-slot="props">
                                        <b-field>
                                            <ValidationProvider rules="required|url_form|max:250" v-slot="{errors}">
                                                <b-input v-model="props.row.link"
                                                          type="textarea"
                                                ></b-input>
                                                <span class="validation-alert">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-field>
                                    </b-table-column>
                                    <!--
                                                                    <b-table-column field="multipleResponse" label="¿Se requiere en todo el proceso?" centered v-slot="props">
                                                                        <b-checkbox class="my-2" v-model="props.row.requiredAllProcess">
                                                                        </b-checkbox>
                                                                    </b-table-column>
                                    -->
                                    <b-table-column field="actions" label="Acciones" centered v-slot="props">
                                        <a class="" @click="removeForm(props.row)">
                                        <span class="icon has-text-danger my-1">
                                        <i class="fas fa-trash-alt"></i>
                                        </span>
                                        </a>
                                    </b-table-column>

                                    <template #empty>
                                        <div class="has-text-centered">Aún no hay formularios definidos</div>
                                    </template>

                                </b-table>
                            </div>
                        </section>
                    </div>

                    <footer class="modal-card-foot actions has-background-secondary">
                        <div class="field is-grouped is-pulled-right">
                            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
                            <button class="button is-primary" type="submit">Guardar cambios</button>
                        </div>
                    </footer>
                </form>
            </ValidationObserver>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </section>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "ModalAddItems",
    props: ["modalHeader", "currentState", "forms"],
    emits: ['stateWithInternalPositions'],
    components: {},
    data: function () {
        return {
            selectedFile: {},
            selectedInternalPositions: [],
            optionsInternalPositions: [],
            selectedDocuments: [],
            optionsDropFiles: [],
            selectedForms: [],
            optionsForms: [],
            selectedSignatories: [],
            internalPositions: {},
            validationErrors: {},
            isLoading: false,
            haveDuration: "no",
            stateDuration: 1,
            stateName: "",
            stateDescription: "",
            documentType: "",
            documentParameterizedTypes: []
        }
    },

    async created() {
        this.isLoading = true;
        if (this.modalHeader === 'Documentos') {
            if (this.currentState["documents"].length > 0) {
                this.selectedDocuments = this.selectedDocuments.concat(this.currentState["documents"]);
            }
            this.isLoading = false;
        } else if (this.modalHeader === 'Formularios') {
            if (this.currentState["forms"].length > 0) {
                this.selectedForms = this.selectedForms.concat(this.currentState["forms"]);
            }
            this.isLoading = false;
        }

        else if (this.modalHeader === 'Firmantes') {
            axios
                .get("/document-parameterizeds.json")
                .then((response) => {
                  this.documentParameterizedTypes = response.data;
                })
                .catch((error) => {
                  vm.$buefy.alert.open({
                    title: "Error",
                    hasIcon: true,
                    message: "Error al cargar los tipos de documentos parametrizados",
                    type: "is-danger",
                  });
                })
                .finally(() => {
                    if (this.currentState["signatories"].length > 0) {
                        this.selectedSignatories = this.selectedSignatories.concat(this.currentState["signatories"]);
                    }
                    this.isLoading = false;
                });

            
        }
            //Bring states from bakend
        //Meke axios to rails backend
        else if (this.modalHeader === 'Revisores') {
            this.selectedInternalPositions = this.currentState["internal_positions"];
            axios
                .get("/internal_positions.json")
                .then(response => {
                    // save fetched states into this.optionStates
                    this.optionsInternalPositions = response.data.filter(x => {
                        return x.name != "SUPERADMIN" && !x.name.includes("ALUMNO") && !x.name.includes("INVITADO") && !x.name.includes("ESTUDIANTE")  && !x.name.includes("PROFESOR")
                    });
                    this.isLoading = false;
                })
                //manage errors
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        }
    },

    methods: {

        addDocument() {
            this.selectedDocuments.push({
                name: "",
                multipleResponse: false,
                is_folder: false,
                description: "",
            });
        },
        addSignature(){
            this.selectedSignatories.push({
                document_parameterized_id: "",
                internal_position_id: null,
                parameterized_signature_id: null,
                roles: [],
                role_signature: [],


            });
        },
        removeDocument(document) {
            let index = this.selectedDocuments.indexOf(document)
            this.selectedDocuments.splice(index, 1);
        },

        removeSignature(signature) {
            let index = this.selectedSignatories.indexOf(signature)
            this.selectedSignatories.splice(index, 1);
        },

        addForm() {
            this.selectedForms.push({
                name: "",
                link: "",
                requiredAllProcess: false
            });
        },

        removeForm(form) {
            let index = this.selectedForms.indexOf(form)
            this.selectedForms.splice(index, 1);
        },

        addItems() {
            if (this.modalHeader === 'Revisores') {
                this.currentState["internal_positions"] = this.selectedInternalPositions
            }
            if (this.modalHeader === 'Documentos') {
                this.currentState["documents"] = this.selectedDocuments;
            }
            if (this.modalHeader === 'Firmantes') {
                this.currentState["signatories"] = this.selectedSignatories;
            }
            if (this.modalHeader === 'Formularios') {
                this.currentState["forms"] = this.selectedForms;
            }

            this.$emit('stateWithInternalPositions', this.currentState);
            this.$emit('close');
        },
        getDocumentParameterized() {
            let vm = this;
            
        },

        validation(row){
          row.roles = this.documentParameterizedTypes.find(x => x.id === row.document_parameterized_id).roles
        },
        validationFirmante(row){
          row.role_signature = row.roles.find(x => x.id === row.internal_position_id).parameterized_signatures
        },
        
    }
}
</script>
