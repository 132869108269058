<template>
  <div>
    <b-loading
      v-model="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></b-loading>

    <div v-if="disabledButton">
      <b-notification
          class="is-primary is-light"
          has-icon
          icon="exclamation-circle"
      >
        <b>¡IMPORTANTE!</b> <br>
        Se requiere la validación mediante el ingreso de los 6 digitos de Authenticator para ver, actualizar o eliminar la Firma Digital Simple.

      </b-notification>
      <div v-if="showQr">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <b-message
            title="Aplicación de autenticación"
            type="is-warning"
            aria-close-label="Close message"
            has-icon
            :closable="false"
          >
            <p>
              - Utilice la app de autenticación 'Google Authenticator' para escanear el siguiente código QR.<br>
              - Los códigos generados por esta aplicación serán solicitados <b> cada vez </b> que requiera firmar documentación masivamente.<br>
              - El código QR que se muestra a continuación solo se mostrara una vez.<br>

            </p>
          </b-message>
          </div>
        </div>
        <br>
        <div v-if="linkQr" class="columns has-text-centered is-centered">
          <div class="column is-centered">

            <b-image
                :src="linkQr"
                alt="codigo qr"
                style="max-width: 300px; justify-content: center; margin: auto;"                    
            ></b-image>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-offset-3">
          <b-field grouped >
            <template #label>
              <b-tooltip
                  label="Abra su aplicación 'Google Authenticathor' para ver su código de autenticación."
                  position="is-right"
                  size="is-medium"
                  multilined>

                <b-icon
                    pack="fas"
                    icon="info-circle"
                    size="is-small"
                    type="is-info">
                </b-icon>
              </b-tooltip>
              Código de autenticación de 6 digitos*
            </template>
            <b-input placeholder="XXXXXX" v-model="autenticatorQr" maxlength="6"></b-input>
            <p class="control">
              <b-tooltip label="Configure su App 'Google Authenticator' para generar códigos escaneando el siguiente código QR">                
              </b-tooltip>
              <b-button type="is-success" icon-pack="fas" icon-left="check-double"
                @click.prevent="validate" >
                  Habilitar Acciones
              </b-button>
            </p>
          </b-field>
        </div>    
      </div>
    </div>
    <div v-else>
      <div class="columns">
        <div class="column" v-if="signatureFileUrl">
          <div class="has-text-weight-bold mb-2">
            Firma Digital Simple (vista previa)
          </div>
          <div class="signature-container">
            <img
              :src="signatureFileUrl"
              class="signature-preview"
              :style="{ height: `${signatureImageHeight}px` }"
            />
            <div>
              <div class="columns is-desktop is-centered">
                <div
                  class="column has-text-centered is-size-7 mt-2 mb-0 pb-0"
                >
                  Tamaño: {{ signatureImageHeight }}px                
                </div>
              </div>

              <div class="columns is-mobile is-centered">
                <div class="column is-narrow has-text-right">
                  <b-button
                    icon-left="minus"
                    type="is-primary is-outlined is-small"
                    :disabled="signatureImageHeight === 50"
                    @click="scaleDown"
                  >
                    Disminuir
                  </b-button>
                </div>
                <div class="column is-narrow has-text-left">
                  <b-button
                    icon-right="plus"
                    type="is-primary is-outlined is-small"
                    :disabled="signatureImageHeight === 100"
                    @click="scaleUp"
                  >
                    Aumentar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column" v-else>
          <div class="has-text-weight-bold mb-2">
            Firma Digital Simple (vista previa)
          </div>
          <div class="signature-container-signature-off">
                Sin Firma Digital Simple
          </div>
        </div>
        <div class="column" v-if="!signatureFileNew">
          <b-field extended label="Nueva Firma Digital Simple (formato PNG)">
            <b-upload
              v-model="signatureFileNew"
              expanded
              required
              accept=".png,.jpeg,.jpg,.svg"
              drag-drop
              @input="onAddSignatureImage"
            >
              <div class="content has-text-centered p-4">
                <p>
                  <b-icon icon="image" size="is-large"></b-icon>
                </p>
                <p>Arrastra tu firma o haz clic para subir.</p>
                <i> Extensiones permitidas: '.png', '.jpg', '.jpeg', '.svg'<br />
                    Tamaño máximo: 2MB<br />
                </i>
                <b-tag
                  v-if="signatureFileNew"
                  icon="image"
                  type="is-success is-light"
                >
                  {{ shortTextFile(signatureFileNew.name, 48) }}
                  {{ Math.round((file.size * 100) / 1000000) / 100 }}MB
                </b-tag>
              </div>
            </b-upload>
          </b-field>
        </div>
        <div class="column" v-else>
          <div class="has-text-weight-bold mb-2">
            Nueva Firma Digital Simple (formato PNG)
          </div>
          <div class="signature-container">
            <img
              :src="signatureFileUrlNew"
              class="signature-preview"
              :style="{ height: `${signatureImageHeight}px` }"
            />
            <div>
              <div class="columns is-desktop is-centered">
                <div
                  class="column has-text-centered is-size-7 mt-2 mb-0 pb-0"
                >
                  Tamaño: {{ signatureImageHeight }}px                
                </div>
              </div>

              <div class="columns is-mobile is-centered">
                <div class="column is-narrow has-text-right">
                  <b-button
                    icon-left="minus"
                    type="is-primary is-outlined is-small"
                    :disabled="signatureImageHeight === 50"
                    @click="scaleDown"
                  >
                    Disminuir
                  </b-button>
                </div>
                <div class="column is-narrow has-text-left">
                  <b-button
                    icon-right="plus"
                    type="is-primary is-outlined is-small"
                    :disabled="signatureImageHeight === 100"
                    @click="scaleUp"
                  >
                    Aumentar
                  </b-button>
                </div>
                <div class="column is-narrow has-text-left">
                  <b-button
                    icon-right="trash"
                    type="is-danger is-outlined is-small"
                    @click="dropNewSignatureImage"
                  >
                    Limpiar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      

      <div v-if="signatureFileUrl == null && !acceptedTerms">
        <div class="columns">

          <div class="column">
            <b-message
              title="Término y condiciones de uso"
              type="is-info"
              has-icon
              class="my-4"
              :closable="false"
            >
              <p>
                Al utilizar estas funciones, usted autoriza el almacenamiento de su firma digital en el sistema SAS. Este
                almacenamiento encripta su imagen y usted acepta los riesgos restantes. sera
                requerido seguir un proceso de doble autenticación para ser utilizada
                en las herramientas de Firma Digital Simple
                . Además, usted podrá eliminar su firma del sistema en cualquier
                momento. SAS no se hace responsable de los usos de la Firma Digital Simple.
              </p>
            </b-message>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-checkbox v-model="consentGiven">
              He leído y acepto los <span class="link-style" @click="openModal()"> términos y condiciones de uso</span>.
            </b-checkbox>
          </div>
        </div>
      </div>
      <div class="columns is-pulled-right">
        <div class="column field">
          <b-button v-if="signatureFileUrl != null" icon-pack="fas" icon-left="pen" class="is-primary" @click="updateSignature" :disabled="signatureFileNew == null"> Actualizar Firma Digital</b-button>
          <b-button v-else icon-pack="fas" icon-left="pen" class="is-primary" @click="createSignature" :disabled="signatureFileNew == null || !consentGiven"> Guardar Firma Digital</b-button>
          <b-button class="is-danger" icon-pack="fas" icon-left="trash" @click="deleteSignature" :disabled="signatureFileUrl == null">Eliminar Firma Digital</b-button>
        </div>
      </div>
    </div>
  
    <b-modal  has-modal-card 
              v-model="openModalTerminos"
              trap-focus
              :destroy-on-hide="true"
              aria-role="dialog"
              aria-modal
              :can-cancel="true"
              width="80%"
    >
      <template #default="props">
         <modal-term-and-condition @close="props.close">
          </modal-term-and-condition>
      </template>
    </b-modal>
  </div>
</template>

<script >
import axios from "axios";
import ModalTermAndCondition from "./ModalTermAndCondition.vue"
export default {
  name: "SimpleDigitalSignature",
  components:{ ModalTermAndCondition },
  props: ["showQr","linkQr","signatureFileUrl","acceptedTerms"],
  data: function () {
    return {
      isLoading: false,
      autenticatorQr: "",
      signatureImageHeight: 60,
      signatureFileUrlNew: null,
      signatureFileNew: null,
      disabledButton: true,
      consentGiven: this.acceptedTerms,
      openModalTerminos: false,
    };
  },
  async created() {
  },
  watch:{
   acceptedTerms(newValue){
    this.consentGiven = newValue;
   }
  },
  methods: {
    closeModal(){
      this.openModalTerminos = false;
    },
    async onAddSignatureImage(File) {
      if (File === null) return;
      let size = Math.round((this.signatureFileNew.size * 100) / 1000000) / 100
      if (size > 2) {
        this.$buefy.dialog.alert({
          message: 'Archivo supera el tamaño máximo de 2MB.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.signatureFileNew = null;
        return
      }
      this.isLoading = true;
      this.signatureFileUrlNew = URL.createObjectURL(this.signatureFileNew);
      this.$buefy.toast.open({
        message: `Firma: ${this.shortTextFile(
          this.signatureFileNew.name,
          24
        )} cargada correctamente`,
        type: "is-success",
        position: "is-bottom",
        duration: 3000,
      });
      this.isLoading = false;
    },
    scaleUp() {
      if (this.signatureImageHeight + 10 > 100) return;
      this.signatureImageHeight += 10;
    },
    scaleDown() {
      if (this.signatureImageHeight - 10 < 50) return;
      this.signatureImageHeight -= 10;
    },
    shortTextFile(text, maxLength) {
      if (text.length <= maxLength) return text;
      return text.slice(0, maxLength) + "...";
    },
    dropNewSignatureImage(){
      this.signatureFileNew = null;
      this.signatureFileUrlNew = null;
    },
    async validate(){
      let vm = this;
      if (vm.autenticatorQr.length < 6){
        this.$buefy.dialog.alert({
              message: 'Código de verificación no valido',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
        return false
      }
      vm.isLoading = true;
      await axios
          .post("/verify-code-autenticator.json", 
            {
              "code": vm.autenticatorQr, 
              "only_validate": true, 
            })
          .then(async (res) => {
            if(res.data == true){
              this.disabledButton = false;
              this.$buefy.dialog.alert({
                message: 'Código de verificación validado.',
                type: 'is-success',
                hasIcon: true,
                icon: 'check-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            else{
              this.$buefy.dialog.alert({
                message: 'Código de verificación no válido o expirado.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            vm.errors_validations = null;
          })
          .catch((error) => {
            this.$buefy.dialog.alert({
              message: 'Hubo un error en su solicitud',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          })
          .finally(() => {
            vm.isLoading = false;
          });

    },

    createSignature(){
      let vm = this;
      vm.isLoading = true;
      vm.formData = new FormData();
      vm.formData.append("signature", vm.signatureFileNew);
      axios.post("create-signature-feet.json", this.formData)
        .then((response) => {
          this.$buefy.dialog.alert({
            title: "Éxito",
            message: "La Firma Digital Simple fue guardada con éxito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",            
          });
          this.$emit("updateSignatureUrl", window.URL.createObjectURL(new Blob([vm.signatureFileNew])));
          vm.signatureFileNew = null;
          vm.signatureFileUrlNew = null;
          this.isLoading = false;
        })
          .catch((error) => {
            this.$buefy.dialog.alert({
              title: "Error",
              message: error.response.data.error,
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
            this.isLoading = false;
          });

    },

    updateSignature(){
      let vm = this;
      vm.isLoading = true;
      vm.formData = new FormData();
      vm.formData.append("signature", vm.signatureFileNew);
      axios.patch("update-signature-feet.json", this.formData)
        .then((response) => {
          this.$buefy.dialog.alert({
            title: "Éxito",
            message: "La Firma Digital Simple fue actualizada con éxito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",            
          });
          this.$emit("updateSignatureUrl", window.URL.createObjectURL(new Blob([vm.signatureFileNew])));
          vm.signatureFileNew = null;
          vm.signatureFileUrlNew = null;
          this.isLoading = false;
        })
          .catch((error) => {
            this.$buefy.dialog.alert({
              title: "Error",
              message: error.response.data.error,
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
            this.isLoading = false;
          });

    },
    openModal(){
      this.openModalTerminos = true;
    },
    deleteSignature(){
      let vm = this;
      vm.isLoading = true;;
      axios.delete("delete-signature-feet.json")
        .then((response) => {
          this.$buefy.dialog.alert({
            title: "Éxito",
            message: "La Firma Digital Simple fue eliminada con éxito.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",            
          });
          this.$emit("updateSignatureUrl", null);
          vm.isLoading = false;
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: "Error",
            message: error.response.data.error,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          vm.isLoading = false;
        });

    },
  }
}

</script>

<style scoped>

.pagination-list {
  margin-top: 0 !important;
}
.signature-container-signature-off{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 16px;
    width: 100%;
    height: 80%;
  }
  .signature-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 16px;
    width: 100%;
  }
  .link-style {
    font-weight: bold;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
  .signature-preview {
    max-height: 300px;
    max-width: 100%;
    width: 100%;
    border: white 4px solid;
    background-color: white;
    object-fit: contain;
  }
  .image-container {
    position: relative;
    display: inline-block;
  }

</style>