<template>
  <div :class="isModal ? 'modal-card' : ''">
    <b-loading
      v-model="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></b-loading>

    <header v-if="isModal" class="modal-card-head">
      <p class="modal-card-title">Firma Digital Simple - Un Documento</p>
    </header>

    <section :class="isModal ? 'modal-card-body':''">
      <div class="container box my-5" >
        <div v-if="isModal" class="title">Firma un Nuevo Documento o Uno ya Existente <br>
            en la Solicitud
        </div>
        <div v-else class="title">Firma Digital Simple <br>
            Un Documento
        </div>
        <div v-if="(isModal && (file == null || signImage == null)) || (!isModal)">
          <div class="columns mb-3">
            <div class="column has-text-centered">
              <b-field extended label="Archivo PDF*">
                <b-upload v-model="file" accept=".pdf" drag-drop @input="onAddFile">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="file-pdf" size="is-large"></b-icon>
                    </p>
                    <p>Arrastra tu PDF o haz click para subir.</p>
                  </div>
                </b-upload>
              </b-field>
              <div class="columns tags is-centered">
                <b-tooltip v-if="file" type="is-primary" :label="file.name">
                  <span class="tag is-primary mt-1">
                  {{ shortenString(file.name) }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                  ></button>
                </span>
                </b-tooltip>
              </div>
            </div>
            
            <div v-if="url == null"  class="column has-text-centered">
              <b-field extended label="Firma (formato PNG)*">
                <b-upload
                  ref="fileUpload"
                  v-model="signImage"
                  drag-drop
                  accept=".png"
                  @input="onAddSign"
                >
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="file-signature" size="is-large"></b-icon>
                    </p>
                    <p>Arrastra tu firma o haz click para subir.</p>
                  </div>
                </b-upload>
              </b-field>
              <div class="columns tags is-centered">
                <span v-if="signImage !== null" class="tag is-primary mt-1">
                  {{ signImage.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteImage"
                  ></button>
                </span>
              </div>
            </div>
            <div v-else class="column has-text-centered">
              
              <div class="has-text-weight-bold mb-2">
                Mi Firma Digital Simple (vista previa)
              </div>
              <div v-if="url != null" class="signature-container">
                <img
                  :src="url"
                  class="signature-preview"
                />
              </div>
              <div v-else class="signature-container-signature-off">
                Sin Firma Digital Simple
              </div>


            </div>
            
          </div>
          <div v-if="showMySignature==true" class="columns  mb-3">
            <div  class=" is-offset-6 column has-text-centered is-align-items-center is-justify-content-center" style="display: flex;">
              

              <b-button
                v-if="url != urlMySignature"
                type="is-primary"
                icon-left="signature"
                @click="useMySignature()"
              >
                Usar Mi Firma Digital Simple
              </b-button>
              <b-button
                v-else-if="url == null"
                type="is-primary"
                icon-left="signature"
                @click="useMySignature()"
              >
                Usar Mi Firma Digital Simple
              </b-button>
              <b-button
                v-else
                type="is-primary"
                icon-left="eraser"
                @click="changeSignature()"
              >
                Utilizar otro archivo de firma
              </b-button>

            </div>
            
          </div>
          <div v-if="isModal">

            <b-field label="Selección de Documento de la Solicitud para Firmar:">
              <multiselect
                v-model="fileSelected"
                :options="files"
                :custom-label="customDocumentName"
                :multiple="false"
                :placeholder="files.length > 0 ? 'Seleccione un Documento' : 'Esta solicitud no posee documentos de observación interna'"
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
                track-by="id"
                @input="getDocument()"
                :disabled="files.length === 0"
              >
                <template v-slot:noOptions> No existen datos</template>
                <span slot="noResult"> No se encontraron elementos. </span>
              </multiselect>
            </b-field>
            <br><br>
          </div>
        </div>
        <div v-else-if="isModal">
          <div class="columns">
            <div class="column has-text-right">
              <b-button
                type="is-primary"
                icon-left="eraser"
                @click="newDocumentToSearch()"
              >
                Limpiar Documento
              </b-button>
            </div>
          </div>

        </div>
      </div>


      <div v-if="src.length > 0 && sign !== null" class="container box my-5">
        <div class="columns">
          <div class="column is-6">
            <div class="title">PDF</div>
            <div class="columns">
              <div class="column is-half has-text-right">
                <b-button
                  icon-left="arrow-left"
                  type="is-primary"
                  @click="backPage"
                  >Retroceder página</b-button
                >
              </div>
              <div class="column is-half has-text-left">
                <b-button
                  icon-right="arrow-right"
                  type="is-primary"
                  @click="nextPage"
                  >Siguiente página</b-button
                >
              </div>
            </div>
            <div class="columns has-text-centered">
              <div class="column">Página: {{ currentPage }}/{{ pageCount }}</div>
            </div>
            <div v-if="sign !== null" class="pdf-container" @click="onClickVuePDF">
              <pdf
                :page="currentPage"
                :src="src.at(-1)"
                @num-pages="pageCount = $event"
              />
            </div>
            <div v-else class="pdf-container">
              <pdf
                :page="currentPage"
                :src="src.at(-1)"
                @num-pages="pageCount = $event"
              />
            </div>
            <div class="columns has-text-centered mt-4">
              <div class="column">Página: {{ currentPage }}/{{ pageCount }}</div>
            </div>

            <div class="columns">
              <div class="column is-half has-text-right">
                <b-button
                  icon-left="arrow-left"
                  type="is-primary"
                  @click="backPage"
                  >Retroceder página</b-button
                >
              </div>
              <div class="column is-half has-text-left">
                <b-button
                  icon-right="arrow-right"
                  type="is-primary"
                  @click="nextPage"
                  >Siguiente página</b-button
                >
              </div>
            </div>
          </div>

          <div v-if="sign !== null" class="column is-4 is-offset-1">
            <div class="title">Firma</div>
            <div class="columns">
              <div class="column is-12">
                <div class="content has-text-centered signature-container">
                  <img :src="url" class="signature-preview" />
                </div>
              </div>
            </div>
            <div class="column is-12 is-vcentered">
              <b-field :label="'Tamaño de firma (' + targetHeight + 'px)'">
                <b-slider
                  v-model="targetHeight"
                  :max="500"
                  :min="50"
                  :step="20"
                  :ticks="true"
                  @change="onChangeImageSize"
                ></b-slider>
              </b-field>
            </div>
            <!--
            <div v-if="!isModal && showValidation" class="columns">
              <div class="column">
                <b-field grouped class="is-pulled-right" >
                  <template #label>
                    <b-tooltip
                        label="Abra su aplicación 'Google Authenticathor' para ver su código de autenticación."
                        position="is-right"
                        size="is-medium"
                        multilined>

                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                    Código de autenticación de 6 digitos*
                  </template>
                  <b-input placeholder="XXXXXX" v-model="autenticatorQr" maxlength="6"></b-input>
                  <p class="control">
                    <b-button type="is-success" icon-pack="fas" icon-left="check-double"
                              @click.prevent="validate" >
                      Habilitar Acciones
                    </b-button>
                  </p>
                </b-field>
              </div>
            </div>
          -->

            <div>
              <b-button icon-left="undo" type="is-danger" @click="undoSign"
                >Deshacer última firma</b-button
              >
              <!--b-button v-if="!isModal" :disabled="disabledButton" icon-left="save" type="is-success" @click="download"
                >Descargar</b-button
              -->
              <b-button v-if="!isModal" icon-left="save" type="is-success" @click="download"
                >Descargar</b-button
              >
            </div>
          </div>
          <div v-else>

            <b-field extended label="Firma (formato PNG)*">
              <b-upload
                ref="fileUpload"
                v-model="signImage"
                drag-drop
                accept=".png"
                @input="onAddSign"
              >
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="file-signature" size="is-large"></b-icon>
                  </p>
                  <p>Arrastra tu firma o haz click para subir.</p>
                </div>
              </b-upload>
            </b-field>
            <div class="columns tags is-centered">
              <span v-if="signImage !== null" class="tag is-primary mt-1">
                {{ signImage.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="onDeleteImage"
                ></button>
              </span>
            </div>


          </div>
        </div>
        <div v-if="isModal && signImage !== null">
          <hr/>
          <div class="columns is-centered">
            <div class="column is-narrow is-border-light-grey is-centered my-4 pr-6">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" style="width: 25em">Seguir Firmando documentos de la Solicitud:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control" style="font-size: 1.2em !important;">
                      <b-checkbox v-model="continueSing" style="vertical-align: middle;"> </b-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--

          <div v-if="showValidation" class="columns">
            <div class="column">
              <b-field grouped class="is-pulled-right" >
                <template #label>
                  <b-tooltip
                      label="Abra su aplicación 'Google Authenticathor' para ver su código de autenticación."
                      position="is-right"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                  Código de autenticación de 6 digitos*
                </template>
                <b-input placeholder="XXXXXX" v-model="autenticatorQr" maxlength="6"></b-input>
                <p class="control">
                  <b-tooltip label="Configure su App 'Google Authenticator' para generar códigos escaneando el siguiente código QR">
                    <b-button type="is-primary" icon-pack="fas" icon-left="qrcode" v-if="showQr"
                              @click.prevent="openQr" >
                      Ver QR
                    </b-button>
                  </b-tooltip>
                  <b-button type="is-success" icon-pack="fas" icon-left="check-double"
                            @click.prevent="validate" >
                    Habilitar Acciones
                  </b-button>
                </p>
              </b-field>
            </div>
          </div>
          -->
          <div class="buttons is-centered">
            <b-button
              type="is-primary is-light is-border-darkgoldenrod"
              icon-left="save"
              @click="download"
            > Solo Descargar Documento

            </b-button>
            <b-button
              v-if="canCreateInternalObservation"
              type="is-secondary"
              icon-left="file-invoice"
              @click="confirmSendNote(false)"
            > Solo Adjuntar como Obs. y Doc. de revisión Interna
            </b-button>

            <b-button
              v-if="canCreateInternalObservation"
              type="is-primary"
              icon-left="file-invoice"
              @click="confirmSendNote(true)"
            > Descargar y Adjuntar como Obs. y Doc. de revisión Interna
            </b-button>
          </div>

        </div>
      </div>


    </section>

    <footer v-if="isModal" class="modal-card-foot columns">
      <div class="column has-text-left">
        <b-button
          type="is-danger"
          icon-left="times"
          @click="$emit('close')"
        >
          Cerrar
        </b-button>
      </div>
    </footer>

    <b-modal
      :active.sync="openModal"
      width="60em"
      scroll="keep"
      trap-focus
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Confirmación de Agregación de Observación y Documentación Interna
          </p>
        </header>
        <section class="modal-card-body">
          <p v-html="msg"> </p>
        </section>


        <footer class="modal-card-foot">
          <b-button class=" is-danger has-text-left" @click="cancelAction">Cancelar</b-button>
          <b-button class="is-success has-text-right" @click="sendNote">Confirmar</b-button>

        </footer>
      </div>
    </b-modal>


  </div>
</template>

<script>
import axios from "axios";
import { PDFDocument } from "pdf-lib";
import pdf from "vue-pdf";
import moment from "moment";

export default {
  name: "SimpleDigitalSignatureView",
  components: {
    pdf,
  },
  props: ['isModal','files', 'requestId', 'user', 'applicantData', 'canCreateInternalObservation','requestCode'],
  data() {
    return {
      file: null,
      currentPage: 1,
      pageCount: null,
      fileBinary: null,
      src: [],
      isLoading: false,
      signImage: null,
      sign: null,
      targetHeight: 200,
      url: null,
      aspectRatio: null,
      fileSelected: null,
      continueSing: false,
      msg: "",
      dowloadDocument: false,
      openModal: false,
      disabledButton: false,
      autenticatorQr: "",
      showValidation: false,
      showMySignature: false,
      signImageMySignature: null,
      signMySignature: null,
      urlMySignature: null,
    };
  },
  computed: {},
  created() {
    window.addEventListener("keyup", this.handleKeyup, null);
    this.fetchFileSignature();
  },
  errorCaptured(){
    return false;
  },
  methods: {
    async signFile(xPercent, yPercent) {
      if (this.sign === null) {
        this.$buefy.dialog.alert({
          message: "No se ha cargado una firma",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      if (this.file === null) {
        this.$buefy.dialog.alert({
          message: "No se ha cargado un archivo PDF",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      try {
        const pdfDoc = await PDFDocument.load(this.src.at(-1));
        const signImage = await pdfDoc.embedPng(this.sign);

        const pages = pdfDoc.getPages();
        const page = pages[this.currentPage - 1];

        const signDims = signImage.scale(1);

        const aspectRatio = signDims.height / signDims.width;
        page.drawImage(signImage, {
          x: xPercent * page.getWidth() - this.targetHeight / 2,
          y:
            yPercent * page.getHeight() - (this.targetHeight * aspectRatio) / 2,
          width: this.targetHeight,
          height: Math.ceil(this.targetHeight * aspectRatio),
        });

        return await pdfDoc.saveAsBase64({
          dataUri: true,
        });
      } catch (error) {
        this.$buefy.dialog.alert({
          message: "Este archivo no se puede firmar",
          type: "is-danger",
          hasIcon: true,
        });
        this.onDeleteFile();
      }
      return;
    },    
    async onAddFile() {
      if (this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
        this.file = null;
        this.$buefy.dialog.alert({
          message: "Este archivo no tiene formato PDF",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      this.isLoading = true;
      const buffer = await this.file.arrayBuffer();
      this.fileBinary = buffer;
      this.src = [new Uint8Array(buffer)];
      this.isLoading = false;
    },
    async onClickVuePDF(e) {
      this.isLoading = true;
      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
      let xPosPercent = x / rect.width;
      let yPosPercent = 1 - y / rect.height;
      this.src.push(await this.signFile(xPosPercent, yPosPercent));
      this.isLoading = false;
    },
    undoSign() {
      if (this.src.length === 1)
        return this.$buefy.dialog.alert({
          message: "Ya se deshicieron todas las firmas.",
          type: "is-primary",
          hasIcon: true,
        });
      this.src.pop();
    },
    backPage() {
      if (this.pageCount === null) return;
      if (this.currentPage - 1 < 1) return;
      this.currentPage -= 1;
    },
    nextPage() {
      if (this.pageCount === null) return;
      if (this.currentPage + 1 > this.pageCount) return;
      this.currentPage += 1;
    },
    handleKeyup(e) {
      if (e.repeat) {
        return;
      }
      switch (e.keyCode) {
        case 37:
          this.backPage();
          break;
        case 39:
          this.nextPage();
          break;
      }
    },
    async onAddSign() {
      if (this.signImage.name.split(".").at(-1).toLowerCase() !== "png") {
        this.signImage = null;
        this.$buefy.dialog.alert({
          message: "Este archivo no tiene formato PNG",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      this.isLoading = true;

      const buffer = await this.signImage.arrayBuffer();
      this.sign = new Uint8Array(buffer);
      this.url = URL.createObjectURL(this.signImage);
      let img = new Image();
      let vm = this;
      img.onload = function () {
        vm.aspectRatio = this.height / this.width;
      };
      img.src = this.url;
      this.isLoading = false;
    },
    download() {
      if (this.src.length === 1)
        return this.$buefy.dialog.alert({
          message: "No se han realizado modificaciones",
          type: "is-warning",
          hasIcon: true,
        });
      const linkSource = this.src.at(-1);
      const downloadLink = document.createElement("a");
      let fileName = "-";
      if(this.isModal && this.fileSelected !== null){
        fileName = this.fileSelected.name.split("-CC")[0]+"_firmado.pdf"
      }
      else{
        fileName = this.file.name;
      }
      if (this.url == this.urlMySignature){
        this.generateAudit(fileName);
      }
      downloadLink.href = linkSource;
      downloadLink.download = fileName.split(".")[0] + "-firmado";
      downloadLink.click();
      if(this.continueSing == true){
        this.fileSelected = null;
        this.src = [];
        this.fileBinary = null;
        this.file = null;
      }
    },
    async generateAudit(fileName){ 
      let vm = this;     
      vm.isLoading = true;
      await axios
          .post("/verify-code-autenticator.json", 
            { 
            "origin": "Firma Digital Simple - Un Documento",
            "name_files": fileName
            })
          .then(async (res) => {
              vm.isLoading = false;
          })
          .catch((error) => {
            vm.isLoading = false;
          });

    },
    onDeleteFile() {
      this.src = [];
      this.file = null;
    },
    onDeleteImage() {
      this.signImage = null;
      this.sign = null;
      this.url = null;
    },
    onChangeImageSize() {
      var img = new Image();
      let vm = this;
      img.onload = function () {
        vm.aspectRatio = this.height / this.width;
      };
      img.src = this.url;
    },

    downloadModifiedPDF(pdfBytes) {
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Crear un enlace para descargar
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "documento-firmado.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Limpiar la URL para liberar recursos
      URL.revokeObjectURL(url);
    },

    newDocumentToSearch(){
      this.fileSelected = null;
      this.src = [];
      this.fileBinary = null;
      this.file = null;
    },
    customDocumentName(file) {
      let str = "";
      if (file.name.includes("[INTERNO]")){
        str = "Documento Adjuntado observación interna por "+file.author+" ("+file.created_at+")";
      }
      else{
        str = "Documento Adjuntado por el solicitante ("+file.created_at+")";
      }
      return str;
    },

    getDocument(){
      if (this.fileSelected == null) return;
      let vm = this;
      vm.isLoading = true;
      return axios
        .get("/documents/download_pdf_file/" + vm.fileSelected.id, {
          responseType: "blob",
        })
        .then(async (response) => {
          vm.file = new File([new Blob([response.data])], this.fileSelected.name);
          vm.fileBinary = await vm.file.arrayBuffer();
          vm.src = [new Uint8Array(vm.fileBinary)];
        })
        .catch((e) => {
          vm.$buefy.dialog.alert({
            message: "Fallo la descarga documento PDF para previsulizar.",
            type: "is-danger",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },



    confirmSendNote(download){
      let nameOriginalFile = "";
      if(this.fileSelected !== null){
        nameOriginalFile = this.fileSelected.name.split("-CC")[0]+"_firmado.pdf"
      }
      else{
        nameOriginalFile = this.file.name;
      }
      this.msg =
        'Se creará una <b> nueva "Observación y documentación de revisión interna" </b> con el documento firmado y el siguiente comentario: <br><br> <b> \'Se Firma el documento \"' +
        nameOriginalFile +
        "\", por " +
        this.user.full_name +
        ", solicitud código " +
        this.requestCode +
        " en la Fecha " +
        moment(new Date()).format("DD-MM-YYYY") +
        ".'  </b><br><br>  ¿Desea agregar esta \"Observación y documentación de revisión interna\"? Luego de confirmar esta acción <b> No </b> podrá ser modificada y será visible para todas las personas que revisen esta solicitud luego de confirmar. Si existe algún error debera ser rectificada con una nueva observación.";
      this.dowloadDocument = download;
      this.openModal = true;

    },
    cancelAction(){
      this.openModal = false;
    },
    changeSignature(){
      this.signImage = null;
      this.sign = null;
      this.url = null;
    },
    useMySignature(){
      this.signImage = this.signImageMySignature;
      this.sign = this.signMySignature;
      this.url = this.urlMySignature;
    },
    async fetchFileSignature() {
      let vm = this;
      vm.isLoading = true;
      axios
          .request({
            url: "/users/get-signature-feet",
            responseType: "blob",
          })
          .then(async(response) => {
            if (response.status === 200){
              const blob = new Blob([response.data]);
              const filename = 'firma_usuario.png';
              vm.signImage = new File([blob], filename, { type: blob.type });

              const buffer = await blob.arrayBuffer();
              vm.sign = new Uint8Array(buffer);

              vm.url = window.URL.createObjectURL(blob);
              let img = new Image();
              img.onload = function () {
                vm.aspectRatio = vm.height / vm.width;
              };
              img.src = vm.url;
              vm.signImageMySignature = vm.signImage;
              vm.signMySignature = vm.sign;
              vm.urlMySignature = vm.url;
              vm.disabledButton = true;
              vm.showValidation = true;
              vm.showMySignature = true;
            }

          })
          .catch((e) => {
            this.isLoading = false;
            //this.$buefy.dialog.alert({
            //  message: "Fallo la obtención de Firma Digital.",
            //  type: "is-danger",
            //});
          })
          .finally(() => {
              this.isLoading = false;
            });

    },

    async sendNote(retry = true) {
      let download = this.dowloadDocument;
      let nameOriginalFile = "";
      if(this.fileSelected !== null){
        nameOriginalFile = this.fileSelected.name.split("-CC")[0]
      }
      else{
        nameOriginalFile = this.file.name;
      }
      const observation = "Se Firma el documento "+nameOriginalFile+", por "+this.user.full_name+", para la persona "+this.applicantData.full_name+", RUT "+this.applicantData.run+", código de la solicitud "+this.requestCode+" en la Fecha "+moment(new Date()).format("DD-MM-YYYY")+"."


      const base64String = this.src.at(-1);

      // Decodifica la cadena base64 a un Uint8Array
      const byteCharacters = atob(base64String.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const file = new File([blob], 'documento.pdf', { type: 'application/pdf' });
      let formData = new FormData();
      formData.append("internal_observation[observation]", observation);
      formData.append("internal_observation[request_id]", this.requestId);
      formData.append("internal_observation[internal_files][]", file, nameOriginalFile+"_FIRMADO.pdf");
      this.isLoading = true;
      return axios
        .post("/internal_observations.json", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (response) => {
          if(download){
            this.download();
          }

          this.$buefy.dialog.alert({
            message: "Se ha agregado la observación correctamente.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });

          this.openModal = false;
          this.$emit('reloadObservation');
          this.$emit('reload');
          if(this.continueSing == true){
            this.fileSelected = null;
            this.src = [];
            this.fileBinary = null;
            this.file = null;
          }
          else{
            this.$emit('close');
          }


        })
        .catch((e) => {
          if(retry){
            this.sendNote(false);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    shortenString(str, maxLength = 60) {
      return str.length > maxLength ? str.slice(0, maxLength) + '...' : str;
    },

    async validate(){
      let vm = this;
      if (vm.autenticatorQr.length < 6){
        this.$buefy.dialog.alert({
          message: 'Código de verificación no valido',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return false
      }
      vm.isLoading = true;
      await axios
          .post("/verify-code-autenticator.json",
              {
                "code": vm.autenticatorQr,
              })
          .then(async (res) => {
            if(res.data == true){
              this.disabledButton = false;
              this.$buefy.dialog.alert({
                message: 'Código de verificación validado.',
                type: 'is-success',
                hasIcon: true,
                icon: 'check-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            else{
              this.$buefy.dialog.alert({
                message: 'Código de verificación no válido o expirado.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            vm.errors_validations = null;
          })
          .catch((error) => {
            this.$buefy.dialog.alert({
              message: 'Hubo un error en su solicitud',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          })
          .finally(() => {
            vm.isLoading = false;
          });

    },


  },
};
</script>
<style scoped>
.pdf-container {
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 16px;
  overflow-y: auto;
}



.signature-container-signature-off{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 16px;
    width: 100%;
    height: 80%;
  }
  .signature-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-height: 300px;
    background-color: #f5f5f5;
    padding: 16px;
    width: 100%;
  }

.signature-preview {
  max-height: 300px;
  max-width: 100%;
  object-fit: contain;
}

.dialog .modal-card {
  min-width: 60em !important;
}



</style>
