<template>
  <!-- header -->
    <div style="background-color: white">
        <img src="../../../../public/gsd/home_student_images/headers/quienes_somos.png" class="responsive">
        <div class="columns is-centered ">
            <div class="column is-9 ">
                <!-- body -->

                <div class="subtitle is-3 has-text-secondary has-text-weight-bold is-family-secondary ">
                    <p>
                        VICEDECANATO DE DOCENCIA

                    </p>


                </div>
                <div>
                    <p class="is-size-6 has-text-secondary is-family-secondary">
                        El Vicedecanato de Docencia y Formación Profesional de la Facultad de Ingeniería es un ente
                        estratégico, operativo y contralor del desarrollo de la docencia de pregrado de la Facultad,
                        cuyo propósito es cautelar la óptima formación profesional de sus futuros Ingenieros e
                        ingenieras.<br/>
                        <br/>
                        El Vicedecanato está compuesto por tres unidades: Dirección de Docencia, Unidad de Trayectoria
                        Curricular y Módulo Básico y la Unidad de Virtualización.<br/>
                        <br/>
                        La Unidad encargada de gestionar las solicitudes de docencia, es la Dirección de Docencia<br/>
                        <br/>
                    </p>
                </div>
                <div class="columns is-multiline is-desktop  is-multiline ">
                    <div class="column
                is-full-mobile
                is-full-tablet
                is-half-desktop
                is-half-widescreen
                is-one-third-fullhd is-narrow has-text-centered">
            <span>
                      <img src="../../../../public/gsd/home_student_images/about_us/Alessandro.png"
                           class="responsive"><br/>
            </span>
                        <span class="is-size-6 has-text-secondary is-family-secondary has-text-weight-bold ">
                        ALESSANDRO AVAGLIANO GAETA
            </span>
                        <span class="is-size-7 has-text-secondary is-family-secondary ">
                       <br/> Director de Docencia<br/>Vicedecano Subrogante
            </span>
                    </div>
                    <div class="column
                is-full-mobile
                is-full-tablet
                is-half-desktop
                is-half-widescreen
                is-one-third-fullhd  is-narrow has-text-centered">
            <span>
                      <img src="../../../../public/gsd/home_student_images/about_us/David.png" class="responsive"><br/>
            </span>
                        <span class="is-size-6 has-text-secondary is-family-secondary has-text-weight-bold ">
                        DAVID SALINAS SANDOVAL
            </span>
                        <span class="is-size-7 has-text-secondary is-family-secondary ">
                       <br/> Jefe de Unidad de Trayectoria Curricular y Módulo Básico
            </span>
                    </div>
                    <div class="column
                is-full-mobile
                is-full-tablet
                is-half-desktop
                is-half-widescreen
                is-one-third-fullhd  is-narrow has-text-centered">
            <span>
                      <img src="../../../../public/gsd/home_student_images/about_us/Janet.png" class="responsive"><br/>
            </span>
                        <span class="is-size-6 has-text-secondary is-family-secondary has-text-weight-bold is-justify-content-center ">
                        JANET VÁSQUEZ QUELOPANA
            </span>
                        <span class="is-size-7 has-text-secondary is-family-secondary is-justify-content-center ">
                       <br/> Coordinadora de Docencia
            </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer -->

        <div style="background-color: #003B71" align="center">
            <img src="../../../../public/logo_sin_texto.png"/>
            <div>
                <a href="https://www.fing.usach.cl/" target="_blank">
                    <h1 class="is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold">
                        WEB OFICIAL FING USACH | Entra aquí
                    </h1>
                </a>
                <br/>
            </div>

        </div>
        <div class="has-background-msd" align="center">
            <br/>
            <h1 class="is-size-6 has-text-black has-text-centered is-family-secondary ">
                Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins nº 3363. Estación Central.
                Santiago. Chile.
            </h1>
            <a href="https://www.usach.cl/contacto" target="_blank">
                <h1 class="is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold">
                    Oficina de Informaciones, Reclamos y Sugerencias (OIRS)
                </h1>
            </a>
            <br/>

        </div>
    </div>

</template>
<script>
import Accordion from "../Tools/accordion";
import AccordionItem from "../Tools/accordion-item";

export default {
    name: "GsdAboutUs",
    components: {},
  created() {
    document.title = "MSD | Sobre nosotros"
  },


};
</script>


<style>


.responsive {
    width: 100%;
    height: auto;
}

/* Solid border */

</style>
