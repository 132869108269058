<template>
  <section>
    <div class="modal-card">
          <header class="modal-card-head">            
            <p class="title">Términos y Condiciones</p>
          </header>
          <section class="modal-card-body">

            <p class="title"> Condiciones Generales </p>
            Estas Condiciones generales rigen el uso del sistema SAS y las funcionalidades de Firma Digital Simple.<br><br>

            <p class="subtitle">1. Uso de Nuestro Servicio </p>
            <h3>1.1 Generalidades</h3>

            <p>

            Al utilizar el servicio de Firma Digital Simple de SAS, usted acepta cumplir con este acuerdo de términos y condiciones. En caso de desacuerdo con todo o parte de estos términos y condiciones, deberá abstenerse de utilizar el Servicio. <br><br>

            Mediante la aceptación de los presentes términos y condiciones, el usuario se compromete a cumplir con las siguientes reglas de servicio:
            <li style="margin-left: 2em;"> Haber leído y comprendido lo aquí explicado.</li>
            <li style="margin-left: 2em;"> Haber asumido todas las obligaciones aquí señaladas.</li>
            <li style="margin-left: 2em;"> Utilizar el servicio únicamente para fines permitidos por la ley y que no violen los derechos de un tercero.</li>
            <li style="margin-left: 2em;"> No utilizar este servicio para ninguna actividad ilícita. Tiene prohibido romper cualquier término y condición para no generar contenido dedicado a crear SPAM o que pueda proporcionar instrucciones sobre cómo participar en actividades ilegales.</li>
            <li style="margin-left: 2em;"> No recoger, tratar o almacenar información personal sobre otros Usuarios o terceros sin cumplir con la legislación vigente en materia de protección de datos. </li>
            <br>

          </p>
            <p class="subtitle">2. Cuentas</p>
              <h3>2.1 Generalidades</h3>
            Las cuentas de SAS brindan al usuario acceso a los servicios y funcionalidades que podemos establecer y mantener de vez en cuando y a nuestro exclusivo criterio. <br>

            El usuario es el único responsable de la actividad que ocurre en su cuenta y debe mantener segura la contraseña de su cuenta.             
            <br><br>
            <p class="subtitle">3. Información personal de los Usuarios finales</p>
              <h3>3.1 Sus responsabilidades. </h3>
                En lo que respecta a la relación entre usted y nosotros, usted es el único responsable de toda
                la información personal de los Usuarios finales utilizada y enviada en relación con los Servicios. Usted está obligado a:
                (A) cumplir con todas las leyes y normativas de protección de datos y privacidad aplicables a la información personal de
                los Usuarios finales, incluida la obtención y el mantenimiento del consentimiento, cuando sea necesario; y
                (B) defendernos, indemnizarnos y exonerarnos de toda responsabilidad ante cualquier tipo de reclamación, demanda o
                proceso que cualquier tercero o Usuario final promueva contra nosotros en relación con cualquier acto u omisión relativo
                a la información personal de los Usuarios finales.
              <br><br>
              <h3>3.2 Información personal confidencial de los Usuarios finales</h3> 
              <p>
                Respecto al de los Servicios:
                (A) Usted es el único responsable del cumplimiento de todas las leyes y normativas de privacidad aplicables que rigen el tratamiento de la Información personal confidencial. Podemos, como su proveedor de servicios, proporcionar alguna funcionalidad en los Servicios para ayudarle a cumplir con los requisitos, pero usted es responsable de implementar la
                funcionalidad de cumplimiento
              </p>
                
              <br>
            <p class="subtitle">4. Uso del cliente.</p>
              <h3>4.1 Generalidades</h3>
              Usted puede utilizar los servicios de SAS únicamente para sus propios fines y no facilitará su contraseña a ningún tercero. Usted acepta que su acceso a los servicios de SAS y el uso de ellos se rigen por las leyes, políticas y normativas de Chile, y que es su responsabilidad someterse a dichas leyes, políticas y normativas. Usted acepta que confiará en un asesoramiento jurídico independiente para determinar la legalidad y aplicabilidad de las firma digital simple.
              <br><br>
              <h3>4.2 Uso indebido</h3>
              Usted no debe hacer mal uso de los servicios de SAS. Por ejemplo, no debe:</li>

                  <li style="margin-left: 2em;">Copiar, alojar, arrotar, sublicencia o revender los servicios de SAS o su contenido.</li>

                  <li style="margin-left: 2em;">Habilitar o permitir que otros usen el Servicio o el contenido utilizando la información de su cuenta.</li>

                  <li style="margin-left: 2em;">Acceder a los Servicios o intentar acceder a los Servicios por cualquier medio distinto de la interfaz que hayamos facilitado o autorizado.</li>

                  <li style="margin-left: 2em;">Eludir las restricciones de acceso o uso que se pongan en marcha para evitar determinados usos de los Servicios.</li>

                  <li style="margin-left: 2em;">Compartir contenido o participar en un comportamiento que viole la razón de la propiedad intelectual de cualquier persona (Derechos de propiedad intelectual significa derechos de autor, derechos morales, marca registrada, vestuario comercial, patente, secreto comercial, competencia desleal, derecho de privacidad, derecho de publicidad y cualquier otro derecho de propiedad.).</li>

                  <li style="margin-left: 2em;">Intentar de desactivar, menoscabar o destruir los Servicios, los programas informáticos o el hardware.</li>

                  <li style="margin-left: 2em;">Violar la legislación aplicable.
                  </li>
            <br>
            <p class="subtitle"> 5. Seguridad y rendimiento del cliente.</p>
              <h3> 5.1 Sus responsabilidades. </h3>
              <p>
                Usted es responsable de configurar y utilizar las funciones de seguridad de los servicios de SAS para
                cumplir sus obligaciones con los Usuarios finales en virtud de las leyes y normativas aplicables de protección de datos,
                seguridad y privacidad. Usted es responsable de la seguridad de los Documentos electrónicos que se envían a los Usuarios
                finales desde SAS, se descargan desde los Servicios o se transfieren a un sistema ajeno a SAS mediante una
                función de integración de los Servicios. Nosotros no somos responsables de los daños que surjan del acceso no autorizado
                a su cuenta o a su Contenido si usted no sigue prácticas de gestión, protección y composición de contraseñas seguras en
                su cuenta. Mantendremos las salvaguardas administrativas, físicas y técnicas comercialmente razonables para ayudar a
                proteger la seguridad, la confidencialidad y la integridad de su contenido que se encuentre bajo nuestro control directo
                dentro de los servicios.
              </p>

              <br>
           <p class="subtitle"> 6. Limitación de responsabilidad</p>
              <h3>6.1 Generalidades</h3>
              <p>
                En la máxima medida permitida por la ley aplicable, en ningún caso SAS o sus empleados serán responsables de ningún daño directo, indirecto, punitivo, incidental, especial, consecuente o ejemplar, incluidos, entre otros, daños por uso, datos u otras pérdidas intangibles, que surjan. de o en relación con cualquier incumplimiento de este acuerdo. Bajo ninguna circunstancia SAS será responsable de ningún daño, pérdida o lesión que resulte de piratería, manipulación u otro acceso o uso no autorizado del servicio o de su cuenta o de la información contenida en el mismo. 
              </p>


          </section>
          <footer class="modal-card-foot is-flex is-justify-content-space-between">
            <b-button @click="$emit('close')">Cerrar</b-button>
          </footer>
        </div>
  </section>
</template>

<script>

export default {
  name: "ModalTermAndCondition",
  components:{
  },
  data() {
    return{
    
    }
  },
  props:[],
  async created() {
    
  },
  methods :{

  },



}

</script>

<style scoped>

</style>
