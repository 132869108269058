var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"white"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"columns is-centered mt-0"},[_c('div',{staticClass:"column is-9"},[_c('div',{staticClass:"section pt-1",staticStyle:{"background-color":"white"}},[_c('hr',{staticClass:"solid"}),_vm._v(" "),_c('div',{staticClass:"columns is-multiline is-centered"},[(_vm.canCreateRequest)?_c('div',{staticClass:"column"},[_c('a',{staticClass:"button is-customgrey is-border-light-grey is-large is-fullwidth",attrs:{"href":_vm.new_requests_path}},[_vm._m(1),_vm._v(" "),_c('span',[_vm._v(" Iniciar una solicitud ")])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"column"},[_c('a',{staticClass:"button is-customgrey is-border-light-grey is-large is-fullwidth",attrs:{"href":_vm.my_requests_path}},[_vm._m(2),_vm._v(" "),_c('span',[_vm._v(" Mis Solicitudes")])])])])]),_vm._v(" "),_c('b-carousel',{attrs:{"pause-info":false}},_vm._l((_vm.items),function(item,i){return _c('b-carousel-item',{key:i},[_c('b-image',{staticClass:"image",attrs:{"src":item.src}})],1)}),1),_vm._v(" "),_vm._m(3)],1)]),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('b-modal',{attrs:{"trap-focus":"","destroy-on-hide":true,"aria-role":"dialog","aria-modal":"","width":"90%"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('PaymentInfoModal',{on:{"close":props.close}})]}}]),model:{value:(_vm.isModalPaymentInfoOpen),callback:function ($$v) {_vm.isModalPaymentInfoOpen=$$v},expression:"isModalPaymentInfoOpen"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"has-background-gcc",staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("../../../../public/SOA/1. Banner - Inicio.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fas fa-file-alt mx-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fas fa-file-invoice mx-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5",attrs:{"align":"center"}},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.571353058175!2d-70.6832777!3d-33.4474356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c560ac6e81ff%3A0x1db726713bd1c3d7!2sFacultad%20de%20Ingenier%C3%ADa%20USACH!5e0!3m2!1sen!2scl!4v1652986186944!5m2!1sen!2scl","width":"600","height":"450","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#003b71"},attrs:{"align":"center"}},[_c('img',{attrs:{"src":require("../../../../public/logo_sin_texto.png")}}),_vm._v(" "),_c('div',[_c('a',{attrs:{"href":"https://www.fing.usach.cl/","target":"_blank"}},[_c('h1',{staticClass:"is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold"},[_vm._v("\n          WEB OFICIAL FING USACH | Entra aquí\n        ")])]),_vm._v(" "),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"has-background-gcc",attrs:{"align":"center"}},[_c('br'),_vm._v(" "),_c('h1',{staticClass:"is-size-6 has-text-white has-text-centered is-family-secondary"},[_vm._v("\n      Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins\n      nº 3363. Estación Central. Santiago. Chile.\n    ")]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.usach.cl/contacto","target":"_blank"}},[_c('h1',{staticClass:"is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold"},[_vm._v("\n        Oficina de Informaciones, Reclamos y Sugerencias (OIRS)\n      ")])]),_vm._v(" "),_c('br')])
}]

export { render, staticRenderFns }